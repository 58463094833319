import React, { useEffect, useState } from 'react'
import {
  formatNumberDisplay,
  formatHistoryDate,
  HistoryItem,
  formatAddress,
  formatDisplayDateAndTime,
} from '../../../../rx.core'
import { useResolveTriaName } from '../../../../../hooks'

type Props = {
  item: HistoryItem
  onClick: (action: string) => void
}

const TransactionHistoryListItem: React.FC<Props> = ({
  item,
  onClick: handleClick,
}) => {
  const [triaName, setTriaName] = useState<string>()
  const getTriaName = useResolveTriaName()
  const calculatedValue = item?.desc?.value
    ? formatNumberDisplay(
        parseFloat(item?.desc?.value) / Math.pow(10, item?.desc?.decimals),
        6
      )
    : item?.desc?.value
  const formattedDate = formatHistoryDate(new Date(item?.timestamp))
  const handleItemClick = () => {
    handleClick(item?.txnHash)
  }
  function maskHexString(hexString: string): string {
    return hexString.length < 12
      ? hexString
      : hexString.slice(0, 6) + '.'.repeat(3) + hexString.slice(-4)
  }

  function extractSecondItem(response: string): string {
    const items = response?.split(' ')

    // Check if there are at least two items
    if (items?.length >= 2) {
      return items[1] // Return the second item
    } else {
      return response // Return the original string
    }
  }
  useEffect(() => {
    const resolveName = async () => {
      if (item?.desc?.action === 'Received') {
        try {
          const response = await getTriaName(
            item?.chainName,
            item?.from?.address
          )
          setTriaName(response)
        } catch (err) {
          console.log(err)
        }
      } else if (item?.desc?.action === 'Sent') {
        try {
          const response = await getTriaName(item?.chainName, item?.to?.address)
          setTriaName(response)
        } catch (err) {
          console.log(err)
        }
      }
    }
    resolveName()
  }, [item])

  return (
    <>
      <div
        className='w-[100%] h-[8.6vh] pl-[2vh] pr-[1vh] py-[1.4vh] rounded-[2.5vh] flex-col justify-center items-center gap-[1vh] inline-flex cursor-pointer bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor'
        onClick={handleItemClick}
      >
        <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
          <div className=' w-[68%] h-[4.76vh] justify-start items-center gap-[3vh] flex'>
            <div className='w-[1.2vh] h-[1.2vh] relative'>
              <div className='w-[1.2vh] h-[1.2vh] left-0 top-0 absolute bg-opacity-25 rounded-[9.5vh] justify-center items-center inline-flex'>
                <div className='grow shrink basis-0 self-stretch p-[0.8vh] justify-center items-center inline-flex'>
                  <div className='w-[3.2vh] h-[3.2vh] relative flex-col justify-start items-start flex rounded-[2vh] overflow-hidden'>
                    {item?.desc?.assetLogo && (
                      <img
                        src={item?.desc?.assetLogo}
                        className={` ${
                          item?.desc?.assetSymbol?.toUpperCase() === 'FUSE'
                            ? 'scale-[120%]'
                            : ''
                        }`}
                      />
                    )}{' '}
                    {!item?.desc?.assetLogo && (
                      <div className='rounded-[2vh] overflow-hidden'>
                        <img
                          src={item?.chainLogo}
                          className={`bg-red-500 ${
                            item?.chainName?.toUpperCase() === 'FUSE'
                              ? 'scale-[120%]'
                              : ''
                          }`}
                        />
                      </div>
                    )}{' '}
                  </div>
                </div>
              </div>
              <div className='w-[2.14vh] h-[2.14vh] left-[1vh] top-[1vh] absolute  justify-center items-center flex  overflow-hidden rounded-[0.4vh]'>
                <img
                  src={item?.chainLogo}
                  className={`${
                    item?.chainName?.toUpperCase() === 'FUSE'
                      ? 'scale-[120%]'
                      : ''
                  }`}
                ></img>
              </div>
            </div>
            <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold  leading-tight'>
                  {item?.desc?.assetSymbol || item?.chainName}
                </div>
              </div>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                {/* <div className="text-center text-yellow-400 text-xs font-medium  leading-[14.40px]">
                  {item?.desc?.action}
                </div> */}
                {!item?.desc?.contract && (
                  <div
                    className={`text-start text-opacity-40 text-[1.4vh] font-medium w-[23.8vh] leading-tight dark:text-fontLightColorDark text-fontLightColor`}
                  >
                    {item?.desc?.action === 'Received' && (
                      <span>
                        {' '}
                        <span>Sent by</span>{' '}
                        {!triaName ? (
                          <span>{formatAddress(item?.from?.address)}</span>
                        ) : (
                          <span>{triaName}</span>
                        )}
                      </span>
                    )}
                    {item?.desc?.action === 'Sent' && (
                      <span>
                        {' '}
                        <span>Sent to</span>{' '}
                        {!triaName ? (
                          <span>{formatAddress(item?.to?.address)}</span>
                        ) : (
                          <span>{triaName}</span>
                        )}
                      </span>
                    )}
                  </div>
                )}
                {item?.desc?.contract && (
                  <div
                    className={`text-start text-opacity-40 w-[25vh] text-[1.4vh] font-medium leading-tight dark:text-fontLightColorDark text-fontLightColor`}
                  >
                    <span>NFT </span>
                    <span>{item?.desc?.contract?.method}</span>
                    <span> by </span>
                    <span>{item?.wallet?.name}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='grow shrink basis-0 flex-col justify-center items-end gap-[0.5vh] w-[40%] inline-flex'>
            {item?.desc?.valueInUsd && (
              <div
                className={`text-center ${
                  item?.desc?.action === 'Received'
                    ? 'text-received-warning'
                    : item?.desc?.action === 'Sent'
                    ? 'text-sent-warning'
                    : 'text-fontLightColor dark:text-fontLightColorDark'
                } text-[2vh] font-semibold  leading-tight`}
              >
                $ {item?.desc?.valueInUsd?.toFixed(4)}
              </div>
            )}

            {calculatedValue && item?.desc?.action != 'Mint' && (
              <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-[1.67vh] font-semibold  leading-tight'>
                {' '}
                {parseFloat(calculatedValue)?.toFixed(4)}{' '}
                {extractSecondItem(item?.desc?.assetSymbol)}
              </div>
            )}
            {calculatedValue && item?.desc?.action == 'Mint' && (
              <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-40 text-[1.67vh] font-semibold  leading-tight'>
                {' '}
                {parseFloat(calculatedValue)}{' '}
                {extractSecondItem(item?.desc?.assetSymbol)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionHistoryListItem
