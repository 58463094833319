interface Props{
  color: string;
  width: string;
  height: string;
}

export const WalletIcon:React.FC<Props> = ({color, width, height}) => (
  <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3333 7.5H6.33325" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.8336 9.14159V10.8583C18.8336 11.3166 18.4669 11.6916 18.0003 11.7083H16.3669C15.4669 11.7083 14.6419 11.0499 14.5669 10.1499C14.5169 9.62494 14.7169 9.13327 15.0669 8.79161C15.3752 8.47494 15.8002 8.29163 16.2669 8.29163H18.0003C18.4669 8.30829 18.8336 8.68326 18.8336 9.14159Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0667 8.79162C14.7167 9.13328 14.5168 9.62495 14.5668 10.15C14.6418 11.05 15.4667 11.7083 16.3667 11.7083H18.0001V12.9166C18.0001 15.4166 16.3334 17.0833 13.8334 17.0833H6.33341C3.83341 17.0833 2.16675 15.4166 2.16675 12.9166V7.08329C2.16675 4.81663 3.53342 3.23329 5.65842 2.96662C5.87508 2.93329 6.10008 2.91663 6.33341 2.91663H13.8334C14.0501 2.91663 14.2584 2.92495 14.4584 2.95828C16.6084 3.20828 18.0001 4.79996 18.0001 7.08329V8.29164H16.2667C15.8001 8.29164 15.3751 8.47495 15.0667 8.79162Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
