interface Props {}

export const QrButton: React.FC<Props> = () => {
  return (
    <div className='w-[2.4vh] h-[2.4vh] relative'>
      <img
        src='/scan-barcode-dark.svg'
        alt=''
        className='w-0 invisible dark:visible dark:w-[2.4vh]'
      />
      <img
        src='/scan-barcode.svg'
        alt=''
        className='w-[2.4vh] visible dark:invisible dark:w-0'
      />
    </div>
  )
}
