import { useEffect, useState } from 'react'

export function useDebounce<TState>(
  initialState: TState,
  callback: (state: TState) => void,
  delay = 300
): [TState, React.Dispatch<React.SetStateAction<TState>>] {
  const [state, setState] = useState<TState>(initialState)

  useEffect(() => {
    if (!state && state !== 0) {
      return
    }
    const timeoutId = setTimeout(() => {
      callback(state)
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [state])

  return [state, setState]
}
