import { PlatformType } from './platform.model'

export default class PlatformService {
  private static _instance: PlatformService

  public static getInstance(): PlatformService {
    if (!this._instance) {
      this._instance = new PlatformService()
    }
    return this._instance
  }

  public isMobile(): boolean {
    return this.getPlatform() === PlatformType.Mobile
  }

  public isWeb(): boolean {
    return this.getPlatform() === PlatformType.Web
  }

  public isExtension(): boolean {
    return this.getPlatform() === PlatformType.Extension
  }

  public getPlatform(): PlatformType {
    return process.env.NEXT_PUBLIC_PLATFORM! as PlatformType
  }
}
