import Home from "./pages/home"
import Nft from "./pages/nft"
import TransactionListPage from "./pages/activity"
import TransactionDetailCrypto from "./package/ui.common/src/layouts/Activity/TransactionDetailCrypto"

import { Routes, Route, useParams } from "react-router-dom"
import "./index.css"
import { useEffect, useState } from "react"
import OneNft from "./package/ui.common/src/layouts/Nfts/OneNft"
import { AuthIframe } from "./package/ui.common/src/components"
import { SendCrypto } from "./pages/sendcrypto"
import { BuyCrypto } from "./pages/buycrypto"
import { UserConversations } from "./pages/conversations"
import { AssetDetailHistory } from "./pages/assethistory"
import { SendToken } from "./pages/sendtoken"
import HomeBackgroundVector from "./package/ui.common/src/layouts/UI/HomeBackgroundVector"
import { useHandleParams } from "./hooks"
import { useDisconnect } from "@tria-sdk/connect"

import { SendNft } from "./pages/sendnft"
import TransactionListPagePending from "./pages/activitypending"
import { PinSetup } from "./pages/pinsetup"
import { Toast } from "./package/ui.common/src/components/Toast"
import {
  RootState,
  closeToast,
  fundTriaWallet,
  openToast,
  updatePinSetup,
} from "./package/rx.core"
import { useDispatch, useSelector } from "react-redux"
import { useDetectInactivity } from "./hooks/useDetectInactivity"
import { Swap } from "./pages/swap"
import { AuthController } from "@tria-sdk/core"
import { LogoutModal } from "./package/ui.common/src/components/Popups/LogoutModal"
import { listenForAccessToken, listenIdToken } from "@tria-sdk/connect"

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true)
  const [navigateToHome, setNavigateToHome] = useState<boolean>(false)
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false)
  const param = useParams()
  const AUTH_URL = process.env.REACT_APP_AUTH_URL || ""

  const dispatch = useDispatch()
  const { isLoading } = useHandleParams(param.params || "")
  const { disconnect } = useDisconnect()

  useDetectInactivity()
  const inactivity = useSelector(
    (store: RootState) => store?.User?.isUserInactive
  )

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setIsDarkMode(parsedData?.darkMode)
      // setIsDarkMode(false)
      setNavigateToHome(true)
    } else {
      console.log("Can't find triaName;")
    }
  }, [isLoading])

  useEffect(() => {
    const preloader = document.getElementById("preloader")
    if (preloader) {
      // Hide the preloader once the app is loaded
      preloader.style.display = "none"
    }
  }, [])
  const baseUrl = process.env.REACT_APP_SDK_BASE_URL || ""

  const authController = new AuthController(baseUrl)

  const checkPinStatus = async () => {
    const pinStatus = await authController.isPinSetup({
      accessToken: localStorage.getItem("tria.accessToken") || " ",
      idToken: localStorage.getItem("tria.idToken") || " ",
    })
    dispatch(updatePinSetup(pinStatus.isPinSetup))
  }

  useEffect(() => {
    const initialize = async () => {
      await listenForAccessToken({ authUrl: AUTH_URL })
      await listenIdToken({ authUrl: AUTH_URL })
      const checkevent = () => {
        window.addEventListener(
          "unauthorized",
          (event) => {
            {
              process.env.REACT_APP_ENVIRONMENT === "dev"
                ? setShowLogoutModal(false)
                : setShowLogoutModal(true)
            }
          },
          false
        )
      }
      checkevent()
      await checkPinStatus()
      const intervalId = setInterval(() => {
        checkPinStatus()
      }, 30000)

      return () => {
        clearInterval(intervalId)
        window.removeEventListener("unauthorized", checkevent)
      }
    }

    let cleanupFunction: any
    initialize().then((cleanup) => {
      cleanupFunction = cleanup
    })

    // Cleanup on component unmount
    return () => {
      if (cleanupFunction) cleanupFunction()
    }
  }, [])
  useEffect(() => {
    const handleEvent = async (event: MessageEvent) => {
      console.log("event in wallet", event)
      if (
        event.origin === "https://demo-v1.tria.so" &&
        event.data.type === "fundwallet"
      ) {
        console.log("calling api")
        try {
          const res = await fundTriaWallet()
          if (res?.success) {
            const message: any = {
              type: "fund-wallet-res",
              success: true,
              message: "wallet funded succesfully",
            }
            window.parent.postMessage(message, "*")
          } else {
            const message: any = {
              type: "fund-wallet-res",
              success: false,
              message: "failed",
            }
            window.parent.postMessage(message, "*")
          }
        } catch (err) {
          const message: any = {
            type: "fund-wallet-res",
            success: false,
            message: "failed",
          }
          window.parent.postMessage(message, "*")
        }
      }
    }
    console.log("adding event listener")
    window.addEventListener("message", handleEvent)
    return () => {
      window.removeEventListener("message", handleEvent)
    }
  }, [])

  return (
    <>
      <AuthIframe src={AUTH_URL} />
      {!isLoading && (
        <div className={`overflow-hidden  ${isDarkMode ? "dark" : ""}`}>
          <div
            className={`z-50  bg-primaryColor dark:bg-primaryDarkColor overflow-hidden rounded-2xl select-none`}
          >
            <div className='absolute top-0 moving-div'>
              {" "}
              <HomeBackgroundVector />
            </div>
            {showLogoutModal && (
              <div style={{ zIndex: 1000 }}>
                <LogoutModal setOpenState={setShowLogoutModal} />
              </div>
            )}
            <div style={{ zIndex: 1000 }}>
              <Toast />
            </div>

            <Routes>
              <Route path='/:params' element={<App />} />
              <Route path='/home' element={<Home />} />
              <Route path='/nfts' element={<Nft />} />
              <Route path='/home/buycrypto' element={<BuyCrypto />} />
              <Route path='/home/sendCrypto' element={<SendCrypto />} />
              <Route
                path='/home/sendCrypto/conversations'
                element={<UserConversations />}
              />
              <Route
                path='/home/sendCrypto/cryptoSendPage'
                element={<SendToken />}
              />
              <Route path='/activity' element={<TransactionListPage />} />
              <Route
                path='/activities/:params'
                element={<TransactionListPagePending />}
              />
              <Route
                path='/activity/TransactionDetailCrypto'
                element={<TransactionDetailCrypto />}
              />
              <Route path='/nfts/oneNft' element={<OneNft />} />
              <Route path='/home/sendCrypto/nftsend' element={<SendNft />} />
              <Route path='/assethistory' element={<AssetDetailHistory />} />
              <Route path='/pinsetup' element={<PinSetup />} />
              <Route path='/swap' element={<Swap />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  )
}

export default App
