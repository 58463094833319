import Chains from "../../components/Popups/Chains"
import Accounts from "../../components/Popups/Accounts"
import { useTriaUser } from "../../contexts"
import { useEffect, useRef, useState } from "react"
import {
  NetworkItem,
  useToast,
  coerceStringValue,
  updateNetworks,
  NonEvmAddressesType,
  updateReload,
} from "../../../../rx.core"
import { useTranslation } from "react-i18next"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../rx.core"
import { GetAllAddressesResponse } from "@tria-sdk/core"
import { ArrowButton, CopyButton } from "../../components/Buttons"
import { UserMenu } from "../../components/Popups/UserMenu"
import { UserAvatar } from "../../components/Containers/user/UserAvatar"
import { Refresh } from "../../components"
import { useReload } from "../../contexts/reload/ReloadContext"

export const Nav: React.FC = () => {
  const [accountsToggle, setAccountsToggle] = useState(false)
  const { getAllNetworks } = useTriaUser()
  const { t } = useTranslation()
  const [userAddresses, setUserAddresses] = useState<string>("")
  const [userNonEvmAddresses, setUserNonEvmAddresses] = useState<
    NonEvmAddressesType[]
  >([{ chainName: "", address: "" }])
  const [userPopupToggle, setUserPopupToggle] = useState<Boolean>(false)
  const [activeChain, setActiveChain] = useState<NetworkItem>()
  const [isChainToggleOpen, setIsChainToggleOpen] = useState(false)
  const [isAccountsOpen, setIsAccountsOpen] = useState(false)
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false)
  const [triaName, setTriaName] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const { reload, toggleReload } = useReload()
  // const reload = useSelector(
  //   (store: RootState) => store.AppState?.AppCurrentState?.reload
  // )
  const dispatch = useDispatch()

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
      setUserAddresses(parsedData?.userAddress)
      setUserNonEvmAddresses(parsedData?.userNonEvmAddress)
      // if(typeof parsedData?.defaultChain == "string"){
      // dispatch(updateUserChain(parsedData?.defaultChain))
      // }
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const selectRef = useRef<HTMLDivElement>(null)

  const networkItems = useSelector(
    (store: RootState) => store?.Constants?.NetworkItems
  )
  const fetchNetworks = async () => {
    if (!networkItems.length || reload) {
      try {
        const networks = await getAllNetworks()
        dispatch(updateNetworks(networks))
      } catch (error: any) {
        console.error(error)
      }
    }
  }
  useEffect(() => {
    if (reload) {
      fetchNetworks()
    }
  }, [reload])

  const userStatus = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  )
  // Side effect
  useEffect(() => {
    async function initalFetch() {
      try {
        fetchNetworks()
        // await fetchAssets()
      } catch (error: any) {
        console.error(error)
      }
    }
    initalFetch()
  }, [])
  useEffect(() => {
    const result = networkItems.find(
      (network: any) => network?.chainName === userStatus?.chainName
    )
    setActiveChain(result)
  }, [networkItems, userStatus?.chainName])

  const handleAllAddressesClick = () => {
    setIsAccountsOpen(!isAccountsOpen)
  }

  const handlePopUp = () => {
    setIsChainToggleOpen(false)
  }

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        (isChainToggleOpen || isAccountsOpen || isUserPopupOpen) &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsChainToggleOpen(false)
        setIsAccountsOpen(false)
        setIsUserPopupOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isChainToggleOpen, isAccountsOpen, isUserPopupOpen])

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])
  const handleReload = () => {
    localStorage.removeItem("persist:root")
    toggleReload(true)
    dispatch(updateReload(true))
    setLoading(true)
    setTimeout(() => {
      // dispatch(updateReload(false))
      toggleReload(false)
      setLoading(false)
    }, 2000)
  }

  return (
    <>
      <div className='self-stretch h-[10vh] py-[2vh] rounded-[2vh]  flex-col justify-center items-center gap-[1vh] z-[200] flex relative '>
        {isChainToggleOpen && (
          <>
            <div className='w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50' />
            <div
              className={`absolute top-[7vh] right-[1.2vh]  flex  bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh] z-50 opacity-100 ${
                isChainToggleOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id='chain-toggle-ref'
            >
              <Chains network={networkItems} onClick={handlePopUp} />
            </div>
          </>
        )}
        {isAccountsOpen && (
          <>
            <div
              className={`w-[80%] h-auto shadow-[2vh] absolute top-[7vh] right-[5vh] flex rounded-lg z-50 ${
                isAccountsOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id='accounts-toggle-ref'
            >
              <Accounts
                addresses={userAddresses}
                activeChain={activeChain}
                nonEvmAddresses={userNonEvmAddresses}
              />
            </div>
          </>
        )}
        {isUserPopupOpen && (
          <>
            <div className='w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50' />
            <div
              className={`w-[80%] shadow-[2vh] absolute top-[7vh] left-0  flex p-[1vh]  rounded-[1vh] z-50 ${
                isUserPopupOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id='user-toggle-ref'
            >
              <UserMenu />
            </div>
          </>
        )}
        <div className='self-stretch p-[1vh] justify-between items-center inline-flex'>
          <div className='grow shrink basis-0 h-[5.2vh] justify-between items-center flex'>
            <div
              className='group justify-center items-center flex cursor-pointer'
              onClick={() => {
                setIsUserPopupOpen(!isUserPopupOpen)
              }}
              ref={selectRef}
              id='user-toggle-ref'
            >
              <UserAvatar />

              <div className='px-[1vh] justify-start items-center flex'>
                <div className='text-center text-black dark:text-[#FAFAFACC] group-hover:dark:text-[#FFFFFF] bg-opacity-90 text-opacity-80 text-[2.3vh] font-semibold leading-tight'>
                  {triaName}
                </div>
              </div>
              <div
                className={`w-[2.8vh] h-[2.8vh] relative flex items-center justify-start group-hover:translate-y-[0.5vh] transform
                 duration-300 ${userPopupToggle ? "rotate-180" : ""}`}
              >
                <ArrowButton />
              </div>
            </div>
            <div className='justify-end items-center gap-[2vh] flex'>
              <div
                className={` z-20 ${
                  loading ? "rotate-animation" : ""
                } cursor-pointer`}
                onClick={handleReload}
              >
                <div
                  className={`flex items-center ${
                    loading ? "" : "hover:rotate-[45deg]"
                  } transform duration-300`}
                >
                  <Refresh />
                </div>
              </div>
              <div className='w-[2vh]'>
                <CopyButton onClick={handleAllAddressesClick} />
              </div>
              <div
                className='w-[5vh] h-[5vh] backdrop-blur-[3.50px] p-[0.5vh] flex justify-center items-center cursor-pointer '
                onClick={() => {
                  setIsChainToggleOpen(!isChainToggleOpen)
                  setAccountsToggle(false)
                }}
              >
                <div className='w-[5vh] h-[5vh]  ' />
                <img
                  className='rounded-[0.8vh] overflow-hidden hover:translate-y-[-0.5vh] transform duration-300'
                  src={activeChain?.logo}
                  alt='logo'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
