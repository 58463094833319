import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState, updateUserChain } from "../package/rx.core"
import { useNavigate } from "react-router-dom"

interface CurrentState {
  triaName?: string
  userAddress?: string
  //  appName?: string,
  logo?: string
  darkMode?: boolean
  appDomain?: string
  defaultChain?: string
  //  allowedChains?: string[],
  accessToken?: string
  //  loginType?: string,
  //  socialName?: string,
  //  userId?: string
}

const paramInitialState: CurrentState = {
  triaName: "",
  userAddress: "",
  //  appName: "",
  logo: "",
  darkMode: false,
  appDomain: "",
  defaultChain: "POLYGON",
  //  allowedChains: [],
  accessToken: "",
  //  loginType: "",
  //  socialName: "",
  //  userId: ""
}

export const useHandleParams = (values: string) => {
  const [params, setParams] = useState<CurrentState>(paramInitialState)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean>()
  const dispatch = useDispatch()
  const route = useSelector((store: RootState) => store?.Route?.currentRoute)
  const navigate = useNavigate()
  useEffect(() => {
    const encodedParams = values
    if (encodedParams) {
      setIsLoading(true)
      const jsonString = atob(encodedParams)
      const jsonData = JSON.parse(jsonString)
      setDarkMode(jsonData?.darkMode)
      setParams(jsonData)
    }
  }, [values])

  useEffect(() => {
    if (params.triaName) {
      const appState = JSON.stringify(params)
      localStorage.setItem(`tria.wallet.store.app`, appState)

      dispatch(updateUserChain(params?.defaultChain || "FUSE"))
      localStorage.setItem("tria.wallet.params", values)
      navigate(route)
      setIsLoading(false)
    }
  }, [params])

  return { isLoading, darkMode }
}
