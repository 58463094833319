import { useNavigate } from 'react-router-dom'
import { PrimaryCustomButton } from '../../package/ui.common/src/components/Buttons/PrimaryButton'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ParamState,
  RootState,
  updateCurrentRoute,
  updatePreviousRoute,
  useToast,
} from '../../package/rx.core'
import { ConversationNav, Opentria } from '../../package/ui.common'
export const SendToken: React.FC = () => {
  const [enteredAmountInUsdValue, setEnteredAmountInUsdValue] =
    useState<string>('')
  const [enteredAmountInTokenValue, setEnteredAmountInTokenValue] =
    useState<string>('')
  const [amountInUsd, setAmountInUsd] = useState<number>(0)
  const [amountInToken, setAmountInToken] = useState<number>(0)
  const [senderAddress, setSenderAddress] = useState<string>()
  const [senderName, setSenderName] = useState<string>()
  const [sendParams, setSendParams] = useState<string>()
  const [recepientAddress, setRecepientAddress] = useState<string>()
  const [error, setError] = useState<string>()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openToastMessage, closeToastMessage, setToastData } = useToast()

  dispatch(updateCurrentRoute(window.location.pathname))

  const prevPath = useSelector((store: RootState) => store.Route.previousRoute)

  const transactionPayload: ParamState = useSelector(
    (store: RootState) => store.TransactionState.param
  )
  const chainName = useSelector(
    (store: RootState) => store.TransactionState.param.chainName
  )
  const appLogo = useSelector(
    (store: RootState) => store.TransactionState.param.appLogo
  )
  const appDomain = useSelector(
    (store: RootState) => store.TransactionState.param.appDomain
  )

  const darkMode = true
  const tokenAddress = useSelector(
    (store: RootState) => store.TransactionState.param.tokenAddress
  )
  const receiverName = useSelector(
    (store: RootState) =>
      store.SendToken.tokenState?.recipientTriaName ||
      store.SendToken.tokenState?.recipientAddress
  )
  const [inputInUsd, setInputInUsd] = useState<boolean>(false)

  const AUTH_URL = process.env.REACT_APP_AUTH_URL

  const HandleClick = () => {
    if (prevPath === '/assetHistory') {
      dispatch(updatePreviousRoute('/'))
      navigate('/assetHistory')
    } else {
      navigate('/home/sendCrypto/conversations')
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setSenderAddress(parsedData?.userAddress)
      setSenderName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  useEffect(() => {
    if (receiverName) {
      setRecepientAddress(receiverName)
    }
  }, [receiverName])

  useEffect(() => {
    setError('')
    const numericAmountValue =
      parseFloat(enteredAmountInUsdValue) / (transactionPayload?.qouteRate || 1)
    if (numericAmountValue >= (transactionPayload?.senderBalance || 1000)) {
      setError('Insufficient funds')
    }

    if (
      senderName &&
      senderAddress &&
      recepientAddress &&
      !isNaN(numericAmountValue) &&
      !error
    ) {
      const params = {
        amount: numericAmountValue,
        senderName,
        senderAddress,
        recepientAddress,
        chainName,
        appLogo,
        appDomain,
        darkMode,
        tokenAddress,
        fromWallet: true,
      }

      const encodedParams = btoa(JSON.stringify(params))
      setSendParams(encodedParams)
    }
  }, [senderName, senderAddress, recepientAddress, enteredAmountInUsdValue])
  useEffect(() => {
    setError('')
    const numericAmountValue = parseFloat(enteredAmountInTokenValue)
    if (numericAmountValue >= (transactionPayload.senderBalance || 1000)) {
      setError('Insufficient funds')
    }

    if (
      senderName &&
      senderAddress &&
      recepientAddress &&
      !isNaN(numericAmountValue) &&
      !error
    ) {
      const params = {
        amount: numericAmountValue,
        senderName,
        senderAddress,
        recepientAddress,
        chainName,
        appLogo,
        appDomain,
        darkMode,
        tokenAddress,
        fromWallet: true,
      }

      const encodedParams = btoa(JSON.stringify(params))
      setSendParams(encodedParams)
    }
  }, [senderName, senderAddress, recepientAddress, enteredAmountInTokenValue])

  const handleAmountUSDValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value

    const validDecimalRegex = /^[0-9]*\.?[0-9]*$/

    if (inputValue === '' || validDecimalRegex.test(inputValue)) {
      setEnteredAmountInUsdValue(inputValue)
    } else {
    }
  }
  const handleAmountTokenValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value
    const validDecimalRegex = /^[0-9]*\.?[0-9]*$/

    if (inputValue === '' || validDecimalRegex.test(inputValue)) {
      setEnteredAmountInTokenValue(inputValue) // Directly use the string value
    } else {
    }
  }

  useEffect(() => {
    const numericAmountValue = parseFloat(enteredAmountInTokenValue)
    if (!isNaN(numericAmountValue) && transactionPayload?.qouteRate) {
      setAmountInUsd(numericAmountValue * transactionPayload.qouteRate)
    } else if (isNaN(numericAmountValue)) {
      setAmountInUsd(0)
    }
  }, [enteredAmountInTokenValue, transactionPayload?.qouteRate])

  useEffect(() => {
    const numericAmountValue = parseFloat(enteredAmountInUsdValue)
    if (!isNaN(numericAmountValue) && transactionPayload?.qouteRate) {
      setAmountInToken(numericAmountValue / transactionPayload.qouteRate)
    } else if (isNaN(numericAmountValue)) {
      setAmountInToken(0)
    }
  }, [enteredAmountInUsdValue, transactionPayload?.qouteRate])

  const handleSendClick = () => {
    if (sendParams && !error) {
      window.open(`${AUTH_URL}/send/${sendParams}`, '_self')
    }
  }
  useEffect(() => {
    if (error) {
      setToastData({ status: 'error', title: error })
      openToastMessage()
      setTimeout(closeToastMessage, 4000)
    }
  }, [error])

  return (
    <div className='w-[53vh] h-[100vh] p-[2vh] flex-col justify-between items-center inline-flex rounded-[1.4vh] relative'>
      <div className='w-full h-[100%] flex-col justify-start items-center flex z-50'>
        <ConversationNav onClick={HandleClick} />
        <div className='w-full h-[75vh] relative '>
          <div className='w-full h-[37.85vh] left-0 top-[5vh] absolute rounded-[2vh]'>
            {inputInUsd && (
              <div className='w-full h-[21.67vh] py-[1.4vh] left-0 top-[2vh] absolute flex-col justify-end items-center gap-[1.4vh] inline-flex '>
                <div className='self-stretch justify-center items-center gap-[0.5vh] flex'>
                  <div
                    className={`text-center flex mb-[-2.4vh] ${
                      error
                        ? 'text-redWarning '
                        : 'text-fontLightColor dark:text-fontLightColorDark '
                    }  text-[5.7vh] font-medium leading-tight`}
                  >
                    $
                  </div>

                  <div className='justify-end items-end flex'>
                    <div
                      className={`text-center ${
                        error
                          ? 'text-redWarning '
                          : 'text-fontPrimaryColor dark:text-fontPrimaryDarkColor '
                      }  text-[10.9vh] font-bold overflow-hidden`}
                    >
                      <input
                        type='text'
                        className='text-start bg-primaryColor dark:bg-primaryDarkColor flex items-end justify-end focus:outline-none focus:border-none focus:ring-0'
                        style={{
                          width: `${
                            40 + 30 * enteredAmountInUsdValue?.length
                          }px`,
                        }}
                        placeholder='0'
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = '0')}
                        value={enteredAmountInUsdValue}
                        onChange={handleAmountUSDValueChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='self-stretch justify-center items-center gap-[0.5vh] inline-flex mt-[-2vh]'>
                  <div className=' w-full flex justify-center items-center'>
                    <span
                      className={`text-center bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark text-[3.57vh] font-semibold  leading-normal focus:outline-none focus:border-none focus:ring-0`}
                    >
                      {typeof amountInToken === 'string'
                        ? parseFloat(amountInToken)?.toFixed(4)
                        : amountInToken?.toFixed(4)}
                    </span>
                    <span className='text-center ml-[1vh] text-fontLightColor dark:text-fontLightColorDark  text-[3.57vh] font-semibold  leading-normal'>
                      {transactionPayload?.tokenName}
                    </span>
                    <button
                      className='w-[3.57vh] h-[3.57vh] flex items-center justify-center'
                      onClick={() => {
                        setInputInUsd(!inputInUsd)
                      }}
                    >
                      <img
                        src='/icons/swapcurrency.svg'
                        className='w-[2.4vh]'
                        alt='swap'
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!inputInUsd && (
              <div className='w-full h-[21.67vh] py-[1.4vh] left-0 top-[2.4vh] absolute flex-col justify-end items-center gap-[1vh] flex'>
                <div className='flex justify-center items-end  '>
                  <input
                    type='text'
                    className='text-center bg-primaryColor dark:bg-primaryDarkColor text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[4.76vh] font-semibold  leading-normal focus:outline-none focus:border-none focus:ring-0'
                    placeholder='0'
                    onFocus={(e) => (e.target.placeholder = '')}
                    onBlur={(e) => (e.target.placeholder = '0')}
                    value={enteredAmountInTokenValue}
                    onChange={handleAmountTokenValueChange}
                    style={{
                      width: `${4 + 2.4 * enteredAmountInTokenValue?.length}vh`,
                    }}
                  />
                  <span className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[4.76vh] font-semibold  leading-normal'>
                    {transactionPayload?.tokenName}
                  </span>
                </div>
                <div className='self-stretch justify-center items-center gap-[0.5vh] flex '>
                  <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-[3.57vh] font-bold leading-tight w-full flex justify-center items-center'>
                    $
                    <span className='text-center text-fontLightColor dark:text-fontLightColorDark text-[3.57vh] font-bold '>
                      {amountInUsd?.toFixed(4)}
                    </span>
                    <button
                      className='w-[3.57vh] h-[3.57vh] flex items-center justify-center'
                      onClick={() => {
                        setInputInUsd(!inputInUsd)
                      }}
                    >
                      <img
                        src='/icons/swapcurrency.svg'
                        className='w-[2.4vh]'
                        alt='swap'
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className='w-full h-[8.57vh] px-[2.8vh] py-[1.67vh] left-0 top-[27.4vh] absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[8.7vh] flex-col justify-center items-center gap-[1vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
                <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1.4vh] flex'>
                  <div className='w-[4.76vh] h-[4.76vh] relative bg-violet-600 rounded-[4.76vh] shadow'>
                    <div className='rounded-[1vh] overflow-hidden'>
                      <img src={transactionPayload.tokenLogo} alt='logo' />
                    </div>
                    <div className='left-[2.8vh] top-[3vh] absolute justify-start items-center gap-[0.7vh] inline-flex'>
                      <div className='w-[2.4vh] h-[2.4vh] relative border-white backdrop-blur-[0.24vh] rounded-[0.5vh] overflow-hidden'>
                        <img src={transactionPayload.chainLogo} alt='logo' />
                      </div>
                    </div>
                  </div>
                  <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
                    <div className='self-stretch justify-start items-center gap-[0.5vh] inline-flex'>
                      <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor  text-base font-semibold  leading-tight'>
                        {transactionPayload.tokenName}
                      </div>
                      <div className='w-[2.1vh] h-[2.1vh] relative'>
                        <div className='w-[2.1vh] h-[2.1vh] left-0 top-0 absolute'></div>
                      </div>
                    </div>
                    <div className='text-center text-fontLightColor dark:text-fontLightColorDark  text-[1.67vh] font-semibold  leading-tight'>
                      {typeof transactionPayload.senderBalance === 'string'
                        ? (
                            parseFloat(transactionPayload.senderBalance) || 0
                          )?.toFixed(4)
                        : (transactionPayload.senderBalance || 0)?.toFixed(
                            4
                          )}{' '}
                      {transactionPayload.tokenName}
                    </div>
                  </div>
                </div>
                <div className='px-[1vh] py-[0.5vh] bg-primaryDarkColor dark:bg-primaryColor rounded-[3vh] justify-center items-center flex'>
                  <div className='w-[6.43vh] self-stretch px-[1.2vh] justify-start items-center gap-[1vh] flex'>
                    <div className='text-center text-fontPrimaryDarkColor dark:text-fontPrimaryColor text-[1.67vh] font-semibold  uppercase leading-tight'>
                      max
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='self-stretch h-[12.5vh] flex-col justify-center items-center gap-[1vh] flex '>
        <div className='self-stretch h-[6.43vh] flex-col justify-center items-center gap-[2vh] flex '>
          <div className='self-stretch justify-center items-center gap-[2.8vh] inline-flex'>
            <div className='w-[24vh] cursor-pointer' onClick={handleSendClick}>
              {!error &&
                !inputInUsd &&
                parseFloat(enteredAmountInTokenValue) > 0 && (
                  <PrimaryCustomButton content='send' />
                )}
              {!error && inputInUsd && amountInToken > 0 && (
                <PrimaryCustomButton content='send' />
              )}
              {error && <PrimaryCustomButton content='send' disable={true} />}
              {!inputInUsd && !(parseFloat(enteredAmountInTokenValue) > 0) && (
                <PrimaryCustomButton content='send' disable={true} />
              )}
              {!error && inputInUsd && !(amountInToken > 0) && (
                <PrimaryCustomButton content='send' disable={true} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-[2vh]'>
        <Opentria />
      </div>
    </div>
  )
}
