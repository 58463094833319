import axios from "axios"
import {
  Asset,
  AssetWithAmounts,
  AssetForTriaName,
  NetworkItem,
  ChainAssets,
} from "../types"
import { SwapTargetResponse } from "@tria-sdk/core"

export function coerceStringValue(value: string | null | undefined): string {
  return value ?? ""
}

export function reverseString(s: string): string {
  return s.split("").reverse().join("")
}

export function sortNetworks(networks: NetworkItem[]): NetworkItem[] {
  return networks.sort((a: NetworkItem, b: NetworkItem) =>
    a.chainName.localeCompare(b.chainName)
  )
}

export function checkForAssetWithAmounts(asset: Asset | AssetWithAmounts) {
  return (
    typeof (asset as AssetWithAmounts)["amounts"] !== "undefined" &&
    (asset as AssetWithAmounts).amounts.token !== 0
  )
}

export function createAssetId(asset: ChainAssets | AssetForTriaName): string {
  if (asset.tokenAddress) {
    return [asset.chainName, asset.symbol, asset.tokenAddress].join(":")
  } else {
    return [asset.chainName, asset.symbol].join(":")
  }
}

export function convertTokenToAsset(
  token: SwapTargetResponse,
  chainName: string,
  chainLogo: string
): AssetForTriaName {
  return {
    wallet: {
      address: "",
      name: "",
    },
    name: token.name,
    symbol: token.symbol,
    logoUrl: token.logoUrl,
    chainName: chainName,
    chainLogo: chainLogo,
    balanceInTokens: 0,
    balanceOfTokensInUnits: "",
    decimals: 0,
    balanceInUSD: 0,
    quoteRate: 0,
    tokenAddress: token.tokenAddress,
    isNativeToken: false,
    isSpam: false,
    percentChangein24hr: 0,
  } as AssetForTriaName
}
export const fundTriaWallet = async () => {
  const walletStore = localStorage.getItem("tria.wallet.store.app")
  const accessToken = localStorage.getItem("tria.accessToken")
  if (walletStore && accessToken) {
    const userAddress = JSON.parse(walletStore)?.triaName
    console.log("calling fundwallet", walletStore)
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const call = await axios.post(
        "https://prod.tria.so/api/v2/wallet/fundWallet",
        {
          triaName: userAddress,
          origin: "https://www.empireofsight.com/",
        },
        { headers }
      )
      console.log("fund wallet resp -->", call.data.success)
      if (call?.data?.success === true) {
        setTimeout(() => {}, 2000)
        return call?.data
      }
    } catch (err) {
      throw err
    }
  } else {
    throw "User not logged in!"
  }
}
