import React from 'react'
import { SearchLayout } from '../Search'

export const Header: React.FC = () => {
  return (
    <div className='w-[100%] max-h-[12.3vh] px-[2.4vh] py-[1.4vh] rounded-[2vh] flex-col justify-center items-center gap-[1vh] '>
      <div className='self-stretch justify-start items-center gap-[2vh] flex'>
        <div className='h-[2.85vh] justify-start items-center gap-[1vh] flex'>
          <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-semibold font-['Montserrat'] leading-normal">
            Activities
          </div>
        </div>
      </div>
      <div className='py-[2.4vh]'>
        <SearchLayout onUpdate={() => {}} />
      </div>
    </div>
  )
}
