import { useDisconnect } from "@tria-sdk/connect"
import { Dispatch, SetStateAction, useEffect } from "react"

interface Props {
  setOpenState: Dispatch<SetStateAction<boolean>>
}

export const LogoutModal: React.FC<Props> = ({ setOpenState }) => {
  const { disconnect } = useDisconnect()
  const handleLogout = () => {
    localStorage.removeItem("persist:root")
    disconnect()
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      localStorage.removeItem("persist:root")
      disconnect()
    }, 10000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div
      style={{ zIndex: 9999 }}
      className='w-[45.6vh] fixed mt-[24vh] left-[3.4vh] shadow-xl h-[42vh] px-[1.4vh] pt-[1.4vh] pb-[2vh] bg-neutral-900 rounded-[1.4vh] flex-col justify-center items-center gap-[2.4vh] inline-flex'
    >
      <div className='self-stretch h-[2.1vh] flex-col justify-center items-end gap-[1.4vh] flex'>
        <div className='w-[2.1vh] h-[2.1vh] relative'>
          <div
            onClick={() => setOpenState(false)}
            className='w-[2.1vh] h-[2.1vh] left-[0.01vh] top-0 absolute cursor-pointer'
          >
            <img src='/icons/close-circle.svg' alt='close' />
          </div>
        </div>
      </div>
      <div className='h-[10vh] flex-col justify-center items-center gap-[1.4vh] flex'>
        <div className='w-[5.7vh] h-[5.7vh] justify-center items-center inline-flex'>
          <div className='w-[5.7vh] h-[5.7vh] relative'>
            <div>
              <img src='/icons/security-user.svg' alt='security' />
            </div>
          </div>
        </div>
        <div className='text-center text-neutral-50 text-opacity-80 text-[2.4vh] font-semibold  leading-normal'>
          Session Expired
        </div>
      </div>
      <div className='self-stretch h-[9.5vh] px-[1vh] flex-col justify-center items-start gap-[1.4vh] flex'>
        <div className='self-stretch opacity-80 text-center text-zinc-500 text-[1.67vh] font-medium '>
          Your session has been expired please click on the logout button to
          relogin again. You'll be logged out automatically after 10 seconds.
        </div>
      </div>
      <div className='self-stretch h-[10vh] px-[1vh] flex-col justify-center items-center gap-[0.5vh] flex'>
        <button onClick={handleLogout}>
          <div className='w-[42.6vh] h-[4.76vh] px-[2.4vh] py-[1.4vh] bg-neutral-50 rounded-[2.4vh] justify-center items-center inline-flex'>
            <div className='justify-center items-center flex'>
              <div className='text-center text-stone-950 text-[2vh] font-semibold leading-tight'>
                Logout
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}
