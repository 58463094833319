import { useEffect, useState } from 'react'
import {
  Conversation,
  Nft,
  NftsItem,
  NftsItemDetails,
  formatDisplayDateAndTime,
} from '../../../../rx.core'
import { useTriaUser } from '../../contexts'

interface Props {
  item: Conversation
  onClick?: () => void
}

export const NftConversationCard: React.FC<Props> = ({
  item,
  onClick: handleNftClick,
}) => {
  const { getNFTDetails } = useTriaUser()
  const [triaName, setTriaName] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [nftDetail, setNftDetail] = useState<NftsItemDetails>()
  const [dateTime, setDateTime] = useState(['', ''])

  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchNftDetails = async () => {
    try {
      setIsLoading(true)
      const response = await getNFTDetails(
        item?.chainName,
        item?.nft?.tokenAddress,
        item?.nft?.tokenId,
        triaName
      )
      if (response) {
        setNftDetail(response)
      }
    } catch (error) {
      console.error(error)
      const { message } = error as Error
      return Promise.reject(String(message || error))
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (item?.nft && triaName) {
      fetchNftDetails()
    }
  }, [item, triaName])

  useEffect(() => {
    const date = formatDisplayDateAndTime(new Date(item?.timestamp))
    setDateTime(date)
  }, [item])
  return (
    <div
      className='w-[26.2vh] p-[2.4vh] rounded-[2vh] justify-between items-start inline-flex '
      onClick={handleNftClick}
    >
      <div className='flex-col justify-center items-start gap-5 inline-flex'>
        <div className='self-stretch h-[24vh] flex-col justify-center items-start gap-[1vh] flex'>
          <div className='self-stretch justify-end items-center gap-[0.5vh] inline-flex'>
            <div className='text-center text-zinc-400 text-[1.2vh] font-semibold  leading-tight'>
              {dateTime[1]}
            </div>
            <div className='justify-center items-end gap-[0.5vh] flex'>
              <div className='text-center text-green-400 text-[1.2vh] font-bold  leading-tight'>
                •
              </div>
              <div className='text-center text-green-400 text-[1.2vh] font-semibold  leading-tight'>
                {item?.tag}
              </div>
            </div>
          </div>
          <div className='w-[21.4vh] h-[21.4vh] relative rounded-xl'>
            <img
              className='w-[21.4vh] h-[22vh] left-0 top-[-0.2vh] absolute rounded-[2.4vh]'
              src={nftDetail?.nftInfo?.content?.preview?.url}
            />
          </div>
        </div>
        <div className='flex-col justify-center items-start gap-[1vh] flex'>
          <div className='w-[21.4vh] justify-start items-center gap-[1vh] inline-flex h-[2.3vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[1.67vh] font-semibold leading-tight'>
            {nftDetail?.nftInfo?.name}
          </div>
          <div className='self-stretch justify-between items-center inline-flex'>
            <div className='justify-center items-start gap-[0.5vh] flex'>
              <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[1.67vh] font-semibold  leading-tight'>
                NFT
              </div>
              <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[1.67vh] font-semibold  leading-tight'>
                X{item?.nft?.amount}
              </div>
            </div>
            <div className='px-[1vh] py-[0.2vh] bg-indigo-500 bg-opacity-20 rounded-[5.6vh] justify-center items-center gap-[0.5vh] flex'>
              <div className='text-center text-violet-500 text-[1.2vh] font-semibold  leading-tight'>
                ART
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
