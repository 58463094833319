import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AssetForTriaName, RootState } from "../../../../rx.core"
import { useTriaUser } from "../../contexts"

interface FromSelectDropDownProps {
  chainData: any
  chainIndexSelected: any
  tokenSelectLifi: any
  setChainData: any
  setChainIndexSelected: any
  setTokenSelectLifi: any
  setOverlayVisible: any
  overlayVisible: any
  toChainData: any
  fromChainIdSelected: any
  setFromChainIdSelected: any
  setChainDataSelected: any
  chainDataSelected: any
}
const FromSelectDropDown: React.FC<FromSelectDropDownProps> = ({
  chainData,
  chainIndexSelected,
  tokenSelectLifi,
  setChainData,
  setChainIndexSelected,
  setTokenSelectLifi,
  setOverlayVisible,
  overlayVisible,
  toChainData,
  fromChainIdSelected,
  setFromChainIdSelected,
  chainDataSelected,
  setChainDataSelected,
}) => {
  const fromAssets = useSelector((store: RootState) => store.User?.userAssets)
  interface tokenSelectLifi {
    [x: string]: any
    symbol: any
  }
  const { getAllNetworks } = useTriaUser()
  const [fromDropDownClick, setFromDropDownClick] = useState<boolean>(false)
  // const [tokenSelectLifi, setTokenSelectLifi] = useState<tokenSelectLifi>();

  // const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  // const [chainData, setChainData] = useState();
  const [alltokensLifi, setAlltokensLifi] = useState<AssetForTriaName[]>()
  // const [chainIndexSelected, setChainIndexSelected] = useState(0);
  const [filterTokensData, setFilterTokensData] = useState<AssetForTriaName[]>(
    []
  )
  const [filterChainData, setFilterChainData] = useState([])
  const [fromSelectedData, setFromSelectedData] = useState(false)
  const [visibleData, setVisibleData] = useState<AssetForTriaName[]>([])
  const [searchQueryLifiTokens, setSearchQueryLifiTokens] = useState<string>("")
  const [searchQueryLifiChains, setSearchQueryLifiChains] = useState<string>("")

  interface ChainIdType {
    [key: string]: number
    MUMBAI: number
    POLYGON: number
    AVALANCHE: number
    ARBITRUM: number
    OPTIMISM: number
    FANTOM: number
    GNOSIS: number
    FUSE: number
    ETH: number
    BINANCE: number
  }

  const ChainId: ChainIdType = {
    MUMBAI: 80001,
    POLYGON: 137,
    AVALANCHE: 43114,
    ARBITRUM: 42161,
    OPTIMISM: 10,
    FANTOM: 250,
    GNOSIS: 100,
    FUSE: 122,
    ETH: 1,
    BINANCE: 56,
  }

  useEffect(() => {
    if (fromAssets) {
      setAlltokensLifi(fromAssets)

      setVisibleData(
        fromAssets?.filter(
          (item) =>
            item?.chainName?.toUpperCase() ===
            chainDataSelected?.chainName?.toUpperCase()
        )
      )
    }
  }, [chainDataSelected])
  const [hasSetToChainDataSelected, setHasSetToChainDataSelected] =
    useState(false)

  const fetchNetworks = async () => {
    try {
      const networks = await getAllNetworks()
      let result: any
      if (networks && toChainData) {
        result = networks
          .map((network) => {
            const chainId = ChainId[network.chainName as keyof ChainIdType]

            const matchingToChainData = toChainData.find(
              (data: any) => data.id == chainId
            )

            if (matchingToChainData) {
              return {
                ...network,
                ...matchingToChainData,
                logo: matchingToChainData.logoURI,
              }
            }
          })
          .filter(Boolean) // Filter out elements with null values
      }

      setChainData(result)
      // if (chainDataSelected == null) {
      //   setChainDataSelected(result?.[0])
      //   setHasSetToChainDataSelected(true) // Mark that setToChainDataSelected has been called
      // }
    } catch (error: any) {
      console.error(error)
    }
  }

  const handleFromDropDownClick = () => {
    setFromDropDownClick(!fromDropDownClick)
    setOverlayVisible(!overlayVisible)
  }

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleSearchChangeLifiTokens = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const p =
      e.target.value.trim() === ""
        ? visibleData // If search box is empty, show all tokens
        : visibleData?.filter((token) =>
            token?.symbol?.toLowerCase().includes(e.target.value.toLowerCase())
          )
    setFilterTokensData(p)
    setSearchQueryLifiTokens(e.target.value)
  }

  const handleSearchChangeLifiChains = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const p =
      e.target.value.trim() === ""
        ? chainData // If search box is empty, show all tokens
        : chainData?.filter((token: any) =>
            token?.chainName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
    setFilterChainData(p)
    setSearchQueryLifiChains(e.target.value)
  }

  useEffect(() => {
    if (toChainData) {
      fetchNetworks()
    }
  }, [toChainData])

  function updateVisibleTokens(item: any) {
    const chainNameToFilter = item?.chainName
    const filteredData = alltokensLifi?.filter(
      (item) => item.chainName === chainNameToFilter
    )
    if (filteredData) setVisibleData(filteredData)
  }

  return (
    <>
      {fromSelectedData && (
        <div
          className='self-stretch px-[2.88vh] py-[1.44vh] dark:bg-zinc-900 h-[10vh] bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] inline-flex cursor-pointer'
          onClick={() => {
            handleFromDropDownClick()
            setFromDropDownClick(true)
            setFromSelectedData(false)
          }}
        >
          <div className='self-stretch justify-start items-center gap-[1.92vh] inline-flex'>
            <div className='grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex'>
              <div
                className={`w-[4.8vh] h-[4.8vh] relative ${
                  tokenSelectLifi?.name.toUpperCase() === "FUSE"
                    ? "overflow-hidden"
                    : ""
                } rounded shadow `}
              >
                <img
                  src={tokenSelectLifi?.logoUrl}
                  className={`${
                    tokenSelectLifi?.name.toUpperCase() === "FUSE"
                      ? "scale-[124%] rounded"
                      : ""
                  } `}
                />
                <div className='w-[3.6vh] h-[3.6vh] left-[.6vh] top-[5.31vh] absolute' />
              </div>

              {/* <div className='left-[2.9vh] top-[3vh] absolute justify-start items-center gap-[.7vh] inline-flex'>
                  <div className='w-[2.4vh] h-[2.4vh] relative  rounded  backdrop-blur-[2.50px]'>
                    <div className="border rounded overflow-hidden w-full h-full absolute inset-0">
                      <img src={chainDataSelected?.logo} className='w-full h-full' />
                    </div>
                    <div className='w-[1.8vh] h-[1.8vh] left-[.3vh] top-[.31vh] absolute' />
                  </div>
                </div> */}

              <div className='left-[7.6vh] top-[50.9vh] absolute justify-start items-center gap-[.7vh] inline-flex'>
                <div className='w-[2.4vh] h-[2.4vh] relative  rounded  backdrop-blur-[2.50px]'>
                  <div className='border rounded overflow-hidden w-full h-full absolute inset-0'>
                    <img
                      src={chainDataSelected?.logo}
                      className='w-full h-full'
                    />
                  </div>
                  <div className='w-[1.8vh] h-[1.8vh] left-[.3vh] top-[.31vh] absolute' />
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                <div className='self-stretch justify-start items-center gap-[.48vh] inline-flex'>
                  <div className='dark:text-neutral-50 text-zinc-500 md:text-base text-sm font-semibold font-montserrat -tight'>
                    {tokenSelectLifi?.symbol || ""}
                  </div>
                </div>
                <div className='text-zinc-500 md:text-sm text-xs font-semibold font-montserrat uppercase  '>
                  {chainDataSelected?.name || ""}
                </div>
              </div>
            </div>
            <div className='flex-col justify-center items-end gap- inline-flex'>
              <div className="text-right dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold font-['Montserrat'] -tight tracking-tight">
                ${tokenSelectLifi?.balanceInUSD?.toFixed(2)}
              </div>
              <div className='text-right text-zinc-500  md:text-sm text-xs font-medium font-montserrat  tracking-tight'>
                Available
              </div>
            </div>
            <div className='w-[2.6vh] h-[2.6vh] relative'>
              <div className='w-[2.6vh] h-[2.6vh] left-0 top-0 absolute'>
                <img
                  src='/icons/arrow-right2.svg'
                  className='dark:visible invisible w-0 dark:w-[2.6vh]'
                />
                <img
                  src='/icons/arrow-left.svg'
                  className='dark:invisible visible dark:w-0 rotate-180'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!fromSelectedData && (
        <>
          <div
            className='self-stretch  h-[10vh] px-[2.88vh] py-[1.44vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] flex cursor-pointer'
            onClick={() => {
              // fetchData();
              handleFromDropDownClick()
            }}
          >
            <div className='self-stretch justify-start items-center gap-[1.92vh] inline-flex'>
              <div className='grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex'>
                <div className='w-[4.8vh] h-[4.8vh] relative'>
                  <div className='w-[4.8vh] h-[4.8vh] left-0 top-0 absolute dark:bg-zinc-800 bg-bgOfCircle rounded-[4.8vh]' />
                </div>
                <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                  <div className='self-stretch justify-start items-center gap-[.48vh] inline-flex'>
                    <div className='text-zinc-500 font-montserrat md:text-base text-sm font-semibold  -tight'>
                      Select crypto
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-[2.6vh] h-[2.6vh] relative'>
                <div className='w-[2.6vh] h-[2.6vh] left-0 top-0 absolute'>
                  <img
                    src='/icons/arrow-right2.svg'
                    className='dark:visible invisible w-0 dark:w-[2.6vh]'
                  />
                  <img
                    src='/icons/arrow-left.svg'
                    className='dark:invisible visible dark:w-0 rotate-180'
                  />
                </div>
              </div>
            </div>
          </div>
          {overlayVisible && (
            <div
              style={{
                position: "absolute",
                // height: '50%',
                bottom: "24%",
                zIndex: 1001,
              }}
            >
              {fromDropDownClick && !isDropdownOpen && (
                <div className='w-[49.9vh] h-[56.9vh] relative dark:bg-stone-950 bg-white rounded-[2.4vh]   border-zinc-500 border-opacity-60'>
                  <div className=' left-0 right-0 top-0   absolute  flex-col justify-end items-center gap-[.96vh] inline-flex'>
                    <div className='self-stretch h-[14vh] flex-col justify-end items-center flex'>
                      <div className='self-stretch h-[8vh] pt-[1.44vh] pb-[.48vh] rounded-tl-[2.4vh] rounded-tr-[2.4vh]  flex-col justify-center  items-center gap-[.96vh] flex'>
                        <div className='self-stretch px-[2.4vh] py-[1.44vh] rounded-lg justify-center items-center gap-[.96vh] inline-flex'>
                          <div className='grow shrink basis-0 h-[11vh justify-between items-center flex'>
                            <div className='grow shrink basis-0 h-[11vh justify-start items-center gap-[1.44vh] flex'>
                              <div className='flex-col justify-start items-start gap-[.48vh] inline-flex'>
                                <div className='self-stretch justify-start items-center gap-[.96vh] inline-flex'>
                                  <div className='text-center font-montserrat dark:text-neutral-50 md:text-base text-sm font-semibold  -tight'>
                                    Select Crypto
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-[3.36vh] h-[3.36vh] relative'>
                            <div
                              className='w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer'
                              onClick={handleFromDropDownClick}
                            >
                              <img
                                src='/icons/close-circle-dark.svg'
                                className='dark:visible invisible w-0 dark:w-[3.36vh]'
                              />
                              <img
                                src='/icons/close-circle2.svg'
                                className='dark:invisible visible dark:w-0'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='self-stretch h-[5.76vh] px-[1.44vh] justify-start items-center gap-[1.92vh] inline-flex'>
                        <div className='grow shrink basis-0 h-[4vh] px-[1.44vh] py-[.96vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[3.6vh] justify-start items-center gap-[1.44vh] flex'>
                          <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                            <div className='self-stretch justify-between items-center inline-flex'>
                              <input
                                type='text'
                                className='text-zinc-500 font-montserrat text-xs font-medium   w-full bg-transparent border-none focus:outline-none'
                                placeholder='Search crypto'
                                value={searchQueryLifiTokens}
                                onChange={handleSearchChangeLifiTokens}
                              />{" "}
                              <div className='w-[2.2vh] h-[2.2vh] relative'>
                                <div className='w-[2.2vh] h-[2.2vh] left-0 top-0 absolute'>
                                  <img src='/icons/search-normal.svg'></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='self-stretch  flex-col justify-start items-center gap-[1.92vh] flex'>
                      <div className='self-stretch  flex-col justify-center items-start gap-[2.4vh] flex'>
                        <div className='px-[1.92vh] justify-center items-start gap-[.96vh] inline-flex'>
                          <div className=' dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold  lowercase  tracking-wide'>
                            networks
                          </div>
                        </div>

                        <div className='self-stretch  px-[2.88vh]  justify-between items-start inline-flex '>
                          {chainData?.length > 0 &&
                            chainData
                              ?.slice(0, 4)
                              ?.map((item: any, index: any) => (
                                <>
                                  <div
                                    key={index}
                                    className={`p-[1.4vh] border-2  rounded-[1.3vh] bg-fontColorNftCardDark dark:bg-black  ${
                                      chainDataSelected?.id === item?.id
                                        ? " border-violet-300"
                                        : "dark:border-zinc-900"
                                    } justify-center items-center gap-[1.4vh] cursor-pointer flex`}
                                    onClick={() => {
                                      setChainDataSelected(item)
                                      setFromChainIdSelected(item?.id)
                                      setChainIndexSelected(index)
                                      updateVisibleTokens(item)
                                    }}
                                  >
                                    <div className='justify-start items-center  gap-[1.3vh] flex'>
                                      <div className='w-[3.84vh] h-[3.84vh] relative  rounded  border-white backdrop-blur-sm'>
                                        <div className='w-[2.88vh] h-[2.88vh] rounded-md left-[4.01vh] top-[.5vh] absolute' />
                                        <img
                                          src={item?.logo}
                                          className='rounded border'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                          <div
                            className='p-[1.4vh] rounded-[1.3vh] bg-fontColorNftCardDark border-2 dark:bg-black  dark:border-zinc-900 justify-center items-center gap-[1.4vh] flex cursor-pointer '
                            onClick={handleToggleDropdown}
                          >
                            <div className='w-[3.84vh] h-[3.84vh] relative bg-white rounded-md dark:border-2 dark:border-zinc-900 backdrop-blur-sm'>
                              <div className=' absolute left-[.6vh] top-[.6vh] text-center  text-stone-950 text-opacity-70 text-[1.44vh] leading-tight font-semibold '>
                                +{chainData?.length - 4}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='self-stretch min-h-[24.8vh] flex-col justify-start items-start gap-[.96vh] flex'>
                        <div className='px-[1.92vh] justify-center items-start gap-[.96vh] inline-flex'>
                          <div className=' dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold  lowercase  tracking-wide'>
                            my assets
                          </div>
                        </div>

                        <div className='self-stretch h-[24.2vh] px-[2.4vh] p-[1.44vh] rounded-[2.5vh] flex-col justify-cente items-center gap-[.96vh] flex  cursor-pointer '>
                          <ul className='overflow-y-auto w-[100%]'>
                            {(searchQueryLifiTokens === ""
                              ? visibleData
                              : filterTokensData
                            )?.length > 0 ? (
                              (searchQueryLifiTokens === ""
                                ? visibleData
                                : filterTokensData
                              )?.map((item, index) => (
                                <li key={index} className=''>
                                  <div
                                    className='w-full self-stretch justify-start items-center rounded-[2.5vh] bg- dark:bg- hover:bg-hoverColor dark:hover:bg-hoverDarkColor gap-[1.92vh] py-[2.4vh] px-[1.4vh] inline-flex'
                                    onClick={() => {
                                      setTokenSelectLifi(item)
                                      setFromSelectedData(!fromSelectedData)
                                      setOverlayVisible(!overlayVisible)
                                    }}
                                  >
                                    <div className='grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[.96vh] flex'>
                                      <div className='w-[4.8vh] h-[4.8vh] relative rounded-h-[4.8vh] shadow '>
                                        <div className='w-[4.8vh] h-[4.8vh] left-0 top-0 absolute overflow-hidden rounded-h-[4.8vh] border-stone-950 backdrop-blur-[5px]'>
                                          <img
                                            className={`w-[4.8vh] h-[4.8vh] left-[-0.50px] top-[-0.50px] absolute rounded-[28.31vh] ${
                                              item?.name.toUpperCase() ===
                                              "FUSE"
                                                ? "scale-[124%]"
                                                : ""
                                            } `}
                                            src={item?.logoUrl}
                                            alt={`Token Logo ${item?.symbol}`}
                                          />
                                        </div>
                                        <div className='w-[2.4vh] h-[2.4vh] left-[2.9vh] top-[3vh] absolute   border-neutral-50 '>
                                          {chainDataSelected?.logo && (
                                            <img
                                              className='w-[2.4vh] h-[2.4vh] left-[0.3vh] rounded border top-[0.3vh] absolute'
                                              src={chainDataSelected?.logo}
                                              alt='Chain Logo'
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                                        <div className='self-stretch justify-start items-center gap-[.96vh] inline-flex'>
                                          <div className='text-center dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold font-montserrat -tight'>
                                            {item?.symbol}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grow shrink basis-0 flex-col justify-center items-end gap-[.48vh] inline-flex'>
                                      <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold font-['Montserrat'] -tight">
                                        ${item?.balanceInUSD?.toFixed(2)}
                                      </div>
                                      <div className='text-center text-zinc-500 md:text-sm text-xs font-semibold font-montserrat '>
                                        {item?.balanceInTokens?.toFixed(2)}{" "}
                                        {item?.symbol}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li>
                                <p className='font-[600] text-[1.67vh] font-montserrat text-fontLightColor dark:text-fontLightColorDark'>
                                  You have no crypto right now on this chain
                                </p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isDropdownOpen && (
                <div className='w-[49.9vh] h-[56.9vh] relative dark:bg-stone-950 bg-white rounded-[2.4vh] shadow  border-zinc-500 border-opacity-60'>
                  <div className='max-h-[56.9vh] left-0 top-0 absolute flex-col justify-end items-center gap-[.96vh] inline-flex'>
                    <div className='self-stretch h-[14vh] flex-col justify-end items-center flex'>
                      <div className='self-stretch h-[8vh] pt-[1.44vh] pb-[.48vh] rounded-tl-[2.4vh] rounded-tr-[2.4vh]  flex-col justify-center items-center gap-[.96vh] flex'>
                        <div className='w-[49.9vh] px-[2.4vh] py-[1.44vh] rounded-lg justify-center items-center gap-[.96vh] inline-flex'>
                          <div className='grow shrink basis-0 h-[11vh justify-between items-center flex'>
                            <div className='grow shrink basis-0 h-[11vh justify-start items-center gap-[1.44vh] flex'>
                              <div className='flex-col justify-start items-start gap-[.48vh] inline-flex'>
                                <div className='self-stretch justify-start items-center gap-[.96vh] inline-flex'>
                                  <div className='text-center font-montserrat dark:text-neutral-50 md:text-base text-sm font-semibold  -tight'>
                                    Select Network
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-[3.36vh] h-[3.36vh] relative'>
                            <div
                              className='w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer'
                              onClick={() => {
                                setIsDropdownOpen(!isDropdownOpen)
                              }}
                            >
                              <img
                                src='/icons/close-circle-dark.svg'
                                className='dark:visible invisible w-0 dark:w-[3.36vh]'
                              />
                              <img
                                src='/icons/close-circle2.svg'
                                className='dark:invisible visible dark:w-0'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='self-stretch h-[5.76vh] px-[1.44vh] justify-start items-center gap-[1.92vh] inline-flex'>
                        <div className='grow shrink basis-0 h-[4vh] px-[1.44vh] py-[.96vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[3.6vh] justify-start items-center gap-[1.44vh] flex'>
                          <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                            <div className='self-stretch justify-between items-center inline-flex'>
                              <input
                                type='text'
                                className='text-zinc-500 font-montserrat text-xs font-medium  w-full bg-transparent border-none focus:outline-none'
                                placeholder='Search network'
                                value={searchQueryLifiChains}
                                onChange={handleSearchChangeLifiChains}
                              />{" "}
                              <div className='w-[2.2vh] h-[2.2vh] relative'>
                                <div className='w-[2.2vh] h-[2.2vh] left-0 top-0 absolute'>
                                  <img src='/icons/search-normal.svg'></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='self-stretch  flex-col justify-start items-center gap-[.92vh] flex overflow-y-auto'>
                      {(searchQueryLifiChains === ""
                        ? chainData
                        : filterChainData
                      )?.map((item: any, index: any) => (
                        <div
                          className='self-stretch  flex-col justify-start items-start gap-[.96vh] flex cursor-pointer  rounded-[2.5vh] bg- dark:bg- hover:bg-hoverColor dark:hover:bg-hoverDarkColor '
                          onClick={() => {
                            updateVisibleTokens(item)
                            setFromChainIdSelected(item?.id)
                            setIsDropdownOpen(!isDropdownOpen)
                            setChainIndexSelected(index)
                            setChainDataSelected(item)
                            updateVisibleTokens(item)
                          }}
                        >
                          <div className='self-stretch h-[8.6vh] px-[2.4vh] py-[1.44vh] rounded-[2.5vh] flex-col justify-center items-center gap-[.96vh] flex'>
                            <div className='self-stretch justify-start items-center gap-[1.92vh] inline-flex'>
                              <div className='grow shrink basis-0 h-[3.84vh] justify-start items-center gap-[1.44vh] flex'>
                                <div className='justify-start items-center gap-[.12vh] flex'>
                                  <div className='w-[3.84vh] h-[3.84vh] relative bg-violet-600 rounded-md backdrop-blur-sm'>
                                    {/* <div className="w-[2.88vh] h-[2.88vh] left-[4.01vh] top-[.5vh] absolute" /> */}
                                    <img
                                      src={item?.logo}
                                      className='rounded  border border-white'
                                    />
                                  </div>
                                </div>
                                <div className='grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex'>
                                  <div className='self-stretch justify-start items-center gap-[.96vh] inline-flex'>
                                    <div className='text-center font-montserrat dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  -tight'>
                                      {item?.chainName}
                                    </div>
                                  </div>
                                </div>
                                <div className='w-[3.84vh] h-[3.84vh] relative' />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default FromSelectDropDown
