import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState, CurrentState } from './appstate.model'

const initialState: AppState = {
  AppCurrentState: {
    triaName: '',
    reload: false,
    // appName: "",
    appLogo: '',
    darkMode: true,
    appDomain: '',
    defaultChain: '',
    // allowedChains: [],
    accessToken: '',
    // loginType:"",
    // socialName:"",
    // userId:""
  },
}

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    updateAppState: (state, action: PayloadAction<CurrentState>) => {
      state.AppCurrentState = action.payload
    },
    updateAppDomain: (state, action: PayloadAction) => {
      state.AppCurrentState.appDomain = ''
    },
    updateReload: (state, action: PayloadAction<boolean>) => {
      state.AppCurrentState.reload = action.payload
    },
  },
})

export const { updateAppState, updateAppDomain, updateReload } =
  appSlice.actions
