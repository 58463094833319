import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateUserActivity } from "../package/rx.core"

export function useDetectInactivity({
  timeout = 60000,
}: { timeout?: number } = {}) {
  const [isActive, setIsActive] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    let inactivityTimer: number

    const onInactivity = () => {
      console.log("user inactive again")

      dispatch(updateUserActivity(true))
      setIsActive(false)
    }

    const onActivity = () => {
      if (!isActive) {
        console.log("user active again")
        dispatch(updateUserActivity(false))
        setIsActive(true)
        resetInactivityTimer()
      }
    }

    const resetInactivityTimer = () => {
      // onActivity()
      clearTimeout(inactivityTimer)
      inactivityTimer = window.setTimeout(onInactivity, timeout)
    }

    window.addEventListener("load", onActivity)
    window.addEventListener("mousedown", onActivity)
    window.addEventListener("keydown", onActivity)

    resetInactivityTimer()

    return () => {
      clearTimeout(inactivityTimer)
      window.removeEventListener("load", resetInactivityTimer)
      window.removeEventListener("mousedown", resetInactivityTimer)
      window.removeEventListener("keydown", resetInactivityTimer)
    }
  }, [timeout, isActive])
}
