import React, { useState } from 'react'
import { QrGenerator } from './QrGenerator'
import { NetworkItem, formatAddress } from '../../../../rx.core'

interface Props {
  item: string | undefined
  activeChain: NetworkItem | undefined
  networkItems: NetworkItem[]
  logo?: string
}

const QR: React.FC<Props> = ({ item, activeChain, networkItems, logo }) => {
  const [copied, setIsCopied] = useState(false)
  const copyToClipboard = (text: string | undefined) => {
    if (text) {
      setIsCopied(false)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setTimeout(() => setIsCopied(true), 200)
        })
        .catch((error) => {
          console.error('Failed to copy: ', error)
        })
    }
    setTimeout(() => setIsCopied(false), 2000)
  }
  const handleCopyClick = () => {
    copyToClipboard(item)
  }

  return (
    <div className='flex-col w-[40.5vh] justify-start items-center flex rounded-lg pt-[3vh] pb-0'>
      {activeChain?.chainName === 'SOLANA' && (
        <QrGenerator content={item} logo='/images/solanalogo.svg' />
      )}
      {activeChain?.chainName !== 'SOLANA' && <QrGenerator content={item} />}
      <div className='w-[38vh] px-[2.4vh] py-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor flex-col justify-start items-center gap-[1vh] inline-flex relative '>
        <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
          <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
            <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
              <div className='text-start text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold  leading-tight'>
                <span>Your </span>
                <span>{activeChain?.chainName.toLowerCase()} </span>
                <span>address</span>
              </div>
            </div>
            <div className='text-center text-neutral-400 text-[1.67vh] font-semibold max-w-[24vh] overflow-hidden leading-tight'>
              {formatAddress(item || '')}
            </div>
          </div>
          <div className='py-[1vh] bg-hoverDarkColor rounded-[2.9vh] justify-center items-center w-[9.5vh] flex cursor-pointer'>
            <div
              className='w-[6.54vh] self-stretch justify-center items-center gap-[1vh] flex'
              onClick={handleCopyClick}
            >
              {!copied && (
                <div className='text-center text-white text-[1.67vh] font-semibold  leading-tight'>
                  copy
                </div>
              )}
              {copied && (
                <div className='text-center text-white text-[1.67vh] font-semibold leading-tight'>
                  copied
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='flex gap-[0.2vh] absolute left-[2.4vh] top-[7.1vh]'>
          {networkItems &&
            networkItems?.slice(0, 9)?.map((item, index) => (
              <div key={index} className=''>
                <img
                  src={item?.logo}
                  alt=''
                  className='w-[2.8vh] h-[2.8vh] rounded-[0.6vh]'
                />
              </div>
            ))}
          {logo && (
            <div className=''>
              <img
                src={logo}
                alt=''
                className='w-[2.8vh] h-[2.8vh] rounded-[0.6vh]'
              />
            </div>
          )}
        </div>
      </div>
      <div className='w-[38vh] px-[2.4vh] py-[3vh] bg-primaryColor dark:bg-primaryDarkColor flex-col justify-start items-center gap-[1vh] inline-flex rounded-lg'>
        <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
          <div className='grow shrink basis-0 text-neutral-400 text-[1.67vh] font-medium  leading-tight'>
            Use for receiving tokens and NFTs on{' '}
            {activeChain?.chainName.toLowerCase()} only
          </div>
        </div>
      </div>
    </div>
  )
}

export default QR
