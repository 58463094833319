import { useSelector } from 'react-redux'
import { BackButton } from '../../components/Buttons/BackButton'
import { store, RootState } from '../../../../rx.core'
import { AvatarContainer } from '../../components/Containers/user/AvatarContainer'
import { formatAddress } from '../../../../rx.core'

interface Props {
  onClick: () => void
}

export const ConversationNav: React.FC<Props> = ({ onClick: HandleClick }) => {
  const withTriaName = useSelector(
    (store: RootState) => store.SendToken.tokenState.recipientTriaName
  )
  const withTriaAddress = useSelector(
    (store: RootState) => store.SendToken.tokenState?.recipientAddress
  )
  const withTriaNameAvatar = useSelector(
    (store: RootState) => store.SendToken.tokenState.recipientAvatar
  )

  return (
    <div className='self-stretch h-[8.6vh] rounded-[2vh]  flex-col justify-center items-center gap-[1vh] flex'>
      <div className='self-stretch h-[8.6vh] flex-col justify-center items-center gap-[1vh] flex'>
        <div className='self-stretch h-[8.6vh] px-[2.4vh] py-[1.4vh] rounded-[2.5vh] flex-col justify-center gap-[1vh] flex'>
          <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
            <BackButton onClick={HandleClick} />
            <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1.4vh] flex'>
              {withTriaNameAvatar?.avatar && (
                <AvatarContainer
                  avatar={withTriaNameAvatar?.avatar}
                  background={withTriaNameAvatar?.background}
                />
              )}
              {!withTriaNameAvatar?.avatar && withTriaNameAvatar?.char && (
                <AvatarContainer
                  char={withTriaNameAvatar?.char}
                  background={withTriaNameAvatar?.background}
                />
              )}
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
                <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                  {withTriaName && (
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold leading-tight'>
                      {withTriaName}
                    </div>
                  )}
                  {!withTriaName && withTriaAddress && (
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold leading-tight'>
                      {formatAddress(withTriaAddress)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
