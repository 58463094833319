// ReloadContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ReloadContextType {
  reload: boolean
  toggleReload: (val: boolean) => void
}

const ReloadContext = createContext<ReloadContextType | undefined>(undefined)

interface ReloadProviderProps {
  children: ReactNode
}

export const ReloadProvider = ({ children }: ReloadProviderProps) => {
  const [reload, setReload] = useState<boolean>(false)

  const toggleReload = (val: boolean) => setReload(val)

  return (
    <ReloadContext.Provider value={{ reload, toggleReload }}>
      {children}
    </ReloadContext.Provider>
  )
}

export const useReload = (): ReloadContextType => {
  const context = useContext(ReloadContext)
  if (context === undefined) {
    throw new Error('useReload must be used within a ReloadProvider')
  }
  return context
}
