export const APP_ROUTES = Object.freeze({
  root: '/',
  wildcard: '*',
  home: {
    root: '/home',
    buyCrypto: '/home/buycrypto',
    sendCrypto: '/home/sendCrypto',
  },
  nfts: {
    root: '/nfts',
  },
  activity: {
    root: '/activity',
    transactionDetailCrypto: '/activity/TransactionDetailCrypto',
  },
  signin: {
    root: '/signin',
  },
  dashboard: {
    root: '/dashboard',
  },
  forYou: {
    root: '/for-you',
  },
  ecosystems: {
    root: '/ecosystems',
  },
  assets: {
    root: '/assets',
    byAssetId: {
      forClient: (assetId: string) => `/assets/${assetId}`,
      forServer: '/assets/:assetId',
    },
  },
  user: {
    root: '/user',
    byUserId: {
      forClient: (userId: string) => `/user/${userId}`,
      forServer: '/user/:userId',
      send: {
        forClient: (userId: string) => `/user/${userId}/send`,
        forServer: '/user/:userId/send',
      },
    },
  },
  buy: {
    root: '/buy',
  },
  swap: {
    root: '/swap',
  },
  actionsAndHistory: {
    root: '/actions-and-history',
  },
  settings: {
    root: '/settings',
    manageWallet: {
      root: '/settings/manage-wallet',
      import: {
        root: '/settings/manage-wallet/import',
      },
      create: {
        root: '/settings/manage-wallet/create',
      },
    },
    deleteAccount: {
      root: '/settings/delete-account',
    },
    export: {
      root: '/settings/export',
      wallet: {
        root: '/settings/export/wallet',
      },
      address: {
        root: '/settings/export/address',
        byAddressId: {
          forClient: (addressId: string) =>
            `/settings/export/address/${addressId}`,
          forServer: '/settings/export/address/:addressId',
        },
      },
    },
  },
})
