import { useAppDispatch, useAppSelector } from '../base'
import { setData, openToast, clearData, closeToast } from '../../features'
import { ToastData } from '../../../types'
import { useDispatch } from 'react-redux'

interface openToastMessage {
  (): {
    payload: undefined
    type: 'toast/openToast'
  }
}

interface closeToast {
  (): any
}

export function useToast() {
  const { isOpen, toastData } = useAppSelector((state) => state.toast)
  const dispatch = useDispatch()

  const setToastData = (data: ToastData) => {
    dispatch(setData(data))
  }

  const openToastMessage = () => dispatch(openToast())

  const closeToastMessage = () => dispatch(closeToast())

  return {
    isOpen,
    toastData,
    setToastData,
    openToastMessage,
    closeToastMessage,
  }
}
