interface IconData {
  name: string
  iconSrc: string
  iconSrcDark: string
  iconSrcActive: string
  text: string
  route: string
}

interface Props {
  item?: IconData
  currentRoute?: string
  isTextVisible: (route: string) => 'block' | 'none'
  onClick: (item?: IconData) => void
}

export const NavigatorButton: React.FC<Props> = ({
  item,
  currentRoute,
  isTextVisible,
  onClick: handleIconClick,
}) => {
  return (
    <>
      {item?.route == currentRoute && (
        <div
          key={item?.name}
          className={` px-[1.4vh] py-[0.9vh] rounded-[2.9vh] flex justify-center items-center cursor-pointer transform duration-[150]  scale-x-100 ${
            item?.route == currentRoute
              ? 'bg-navigatorBackgroundActive dark:bg-navigatorBackgroundActiveDark'
              : 'bg-navigatorBackground dark:bg-navigatorBackgroundDark '
          }`}
          onClick={() => handleIconClick(item)}
        >
          <div className='flex-col justify-center items-center gap-[0.7vh] inline-flex'>
            <div className='w-[2.8vh] h-[2.8vh] relative'>
              <img
                className='w-[2.8vh] h-[2.8vh]'
                src={item?.iconSrcActive}
                alt={item?.name}
              />
            </div>
          </div>
          <div className='px-[1.2vh]'>
            <div
              className='text-center text-white font-semibold font-montserrat text-[2vh] leading-[1.2vh] '
              style={{ display: isTextVisible(item?.route || '') }}
            >
              {item?.text}
            </div>
          </div>
        </div>
      )}
      {item?.route != currentRoute && (
        <div
          key={item?.name}
          className={`px-[2vh] flex justify-center items-center cursor-pointer w-[6vh] transform duration-300  ease-in-out transition-all group hover:w-[8vh] relative `}
          onClick={() => handleIconClick(item)}
        >
          <div className='w-[6vh] h-[5vh] rounded-[3vh] absolute transform duration-300 transition-transform scale-x-100 group-hover:scale-x-[120%]  bg-navigatorBackground dark:bg-navigatorBackgroundDark' />
          <div className='flex-col justify-center items-center gap-[0.5vh] md:gap-1.5 inline-flex opacity-60 group-hover:opacity-100'>
            <div className='w-[2.8vh] h-[2.8vh]  relative'>
              <img
                className='w-[2.8vh] h-[2.8vh] dark:w-0 dark:h-0'
                src={item?.iconSrc}
                alt={item?.name}
              />
              <img
                className='w-0 h-0 dark:w-[2.8vh] dark:h-[2.8vh]'
                src={item?.iconSrcDark}
                alt={item?.name}
              />
            </div>
          </div>

          <div
            className='text-center pl-[6px] md:pl-2 text-white text-[10px] md:text-[14px] font-[420] md:font-[600] font-montserrat leading-[7px] md:leading-[10px] '
            style={{ display: isTextVisible(item?.route || '') }}
          >
            {item?.text}
          </div>
        </div>
      )}
    </>
  )
}
