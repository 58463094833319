import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en } from './locales/en'
import { deepmerge } from 'deepmerge-ts'
import { LocalizationResources } from './types'

const defaultResources: LocalizationResources = {
  en: {
    translation: en,
  },
}

export function initalize(
  additionalResources: LocalizationResources | undefined = undefined
) {
  const resources =
    typeof additionalResources === 'undefined'
      ? defaultResources
      : deepmerge(defaultResources, additionalResources)

  i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

  return i18n
}

export * from './types'
export * from './overrides'
