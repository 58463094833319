import { LocaleKeys } from '../types'
import { DeepPartial, PasswordValidationResult } from '../../../rx.core'

export const en: LocaleKeys = {
  WELCOME: {
    PAGE_TITLE: 'Welcome',
    TABS: [
      {
        title: 'We know you don’t need more crypto wallets –',
        heading: 'Use your @tria name - on any chain, any app, any device.',
        content: '/assets/videos/video_welcome_1.webm',
        contentType: 'video/webm',
        withIcon: false,
      },
      {
        title: 'Welcome to Tria',
        heading:
          'Transact with crypto, swap and bridge, across all blockchains.',
        content: '/assets/videos/video_welcome_2.webm',
        contentType: 'video/webm',
        withIcon: true,
      },
      {
        title: 'Welcome to Tria',
        heading: 'Explore tailored apps from all chains and earn rewards.',
        content: '/assets/videos/video_welcome_3.webm',
        contentType: 'video/webm',
        withIcon: true,
      },
      {
        title: 'Welcome to Tria',
        heading:
          'Use crypto without it’s hassles - trustless recovery & alerts.',
        content: '/assets/videos/video_welcome_4.webm',
        contentType: 'video/webm',
        withIcon: true,
      },
      {
        title: 'Welcome to Tria',
        heading:
          'Industry grade security with true Web3 ethos - decentralised & trustless.',
        content: '/assets/videos/video_welcome_5.webm',
        contentType: 'video/webm',
        withIcon: true,
      },
    ],
    LOGIN_BTN: 'Log in',
    CREATE_ACCOUNT_BTN: 'Get Started',
  },
  SIGNUP: {
    PAGE_TITLE: 'Signup',
    COMPANY_NAME: 'Tria',
    BACK_BTN: 'Back',
    CONTINUE_BTN: 'Continue',
    SUCCESS_BTN: 'Settle in',
    ERROR_BTN: 'Try again',
    REGISTER_BTN: 'Create',
    SIGNIN_BTN_DESCRIPTION: 'Have an account?',
    SIGNIN_BTN_CTA: 'Sign in',
    SIGNUP_BTN_LOADING: 'Loading',
    USERNAME_LABEL: '@tria name',
    NAME_CONTAINER: {
      TITLE: 'Your @tria',
      DESCRIPTION:
        'Your <strong>@tria</strong> name is your shareable identity to get paid, log-in to Web3 applications, and more – on every blockchain network.',
      ERROR_MESSAGE_UNAVAILABLE: '@tria not available',
      ERROR_MESSAGE_INVALID: '@tria name invalid',
      IMAGE_ALT: 'Create @tria name',
    },
    PASSWORD_CONTAINER: {
      TITLE: 'Hello',
      DESCRIPTION:
        'Safeguard your @tria with a <strong>strong password</strong>. You will need this to log-in to new devices and change permissions.',
      IMAGE_ALT: 'Create password',
    },
    PASSWORD_VERIFY_CONTAINER: {
      TITLE: 'Confirm your pass',
      DESCRIPTION:
        'Tria runs on a <strong>non-custodial</strong> infrastructure that keeps your assets on a state-of-the art decentralized data store.',
      IMAGE_ALT: 'Verify password',
      ERROR_MESSAGE: 'Password is incorrect',
    },
    PIN_CONTAINER: {
      TITLE: 'Setup a PIN',
      DESCRIPTION:
        'This is a mandatory additional layer of security that protects your <strong>@tria</strong>. You will need this to authorize most actions.',
      ERROR_MESSAGE: 'PIN is weak',
    },
    PIN_VERIFY_CONTAINER: {
      TITLE: 'Setup a PIN',
      DESCRIPTION:
        'This is a mandatory additional layer of security that protects your <strong>@tria</strong>. You will need this to authorize most actions.',
      ERROR_MESSAGE: 'PIN is incorrect',
      POLICY_MESSAGE:
        'By clicking “Create” you agree to the <a href="https://triahq.notion.site/Tria-Beta-Privacy-Policy-359373dd601045b888c207f255634371?pvs=4" target="_blank"><br><strong>privacy policy</strong></a> and <a href="https://triahq.notion.site/Tria-Beta-Terms-of-Use-1d7dfaf5a58f4038beecd1a67f963425?pvs=4" target="_blank"><strong>terms of service</strong></a>',
    },
    SUCCESS_CONTAINER: {
      HEADER: 'Welcome to Tria,',
      TITLE: 'Meet Web3, again.',
      DESCRIPTION: `There’s a whole lot to discover in this wallet built just for you. Let’s settle in.
      <br/><br/>
      Try out Tria <strong>BETA</strong> and do share feedback, thoughts, and concerns using the feedback using the links on the left sidebar at the bottom.`,
      CONTINUE_BTN: 'Settle in',
    },
    ERROR_CONTAINER: {
      HEADER: 'Welcome to Tria',
      TAG: 'BETA',
      TITLE: `An error occured <span class="error">:(</span>`,
      DESCRIPTION: `An <span class="error">error</span> occurred while registering to Tria. Please restart the application, check your network connection and try again.
      <br/><br/>
      If this problem persists, head to <span class="link">Report a Problem</span> and our team will be in touch.`,
    },
  },
  SIGNIN: {
    PAGE_TITLE: 'Signin',
    COMPANY_NAME: 'Tria',
    TITLE: 'Welcome back',
    DESCRIPTION: 'Log in with your @tria name and password and PIN.',
    USERNAME_LABEL: '@tria name',
    USERNAME_EXTENSION: '@tria',
    PIN_LABEL: 'PIN',
    SIGNIN_BTN: 'Login',
    SIGNIN_BTN_LOADING: 'Signing in',
    RECOVER_LINK: 'Lost access to account?'
  },
  COMMON: {
    ACTION_TYPE_REQUEST: 'requested',
    ACTION_TYPE_RECEIVED: 'received',
    ACTION_TYPE_AUTOPAYMENT: 'autopayment',
    APP_NAME: 'Tria',
  },
  DASHBOARD: {
    PAGE_TITLE: 'Home',
    HEADER: {
      INFORMATION: 'Total Balance',
    },
    ACTIONS: {
      TITLE: 'Actions',
      SUBTITLE: 'Important actions',
      VIEW_BTN: 'View history',
    },
    FOR_YOU: {
      TITLE: 'For you',
      SUBTITLE: 'Apps for you based on activity',
    },
    DISCOVER_ITEMS: {
      ASSETS_ITEM: {
        LABEL: 'assets',
      },
      REWARDS_ITEM: {
        LABEL: 'rewards',
      },
      BUY_ITEM: 'Buy',
      FEEDBACK_ITEM: 'Feedback',
      GIFT_ITEM: 'Gift to Friend',
    },
    ECOSYSTEMS: {
      TITLE: 'Ecosystems',
      DESCRIPTION: 'Explore blockchain networks and apps',
      ITEM: {
        LABEL: 'EXPLORE APPS',
        EXPLORE_BTN: 'EXPLORE',
      },
    },
  },
  SIDEBAR: {
    VIEW_ALL: 'View All',
    BETA_FEEDBACK: 'Beta Feedback',
    GIFT_TO_FRIEND: 'Gift to Friend',
    VERSION: 'Tria Wallet Beta V 0.2',
  },
  PIN_POPUP: {
    TITLE: 'Enter PIN',
    ERROR_MESSAGE: "The PIN doesn't match",
    CONFIRM_BTN: 'Confirm',
    FORGOT_PIN_LINK: 'Forgot your PIN code?',
  },
  USER_HISTORY: {
    PAGE_TITLE: 'Actions and History',
    LAYOUT_TITLE: 'Actions and History',
    HISTORY_TITLE: 'History',
    ACTIONS_TITLE: 'Actions',
    DROPDOWN: {
      TITLE: 'Filter by',
      ALL: 'All',
      SENT: 'Transactions - Sent',
      RECEIVED: 'Transactions - Receive',
      NETWORK: 'Network',
      ALL_NETWORK: 'All networks',
    },
    LOADING: 'Loading user details...',
    MULTICHAIN: 'Multichain',
    REQUEST_BTN: 'Request',
    SEND_BTN: 'Send',
    TRANSACTION_DETAILS: {
      TITLE: 'Sent to',
      TIME: 'Time',
      TO: 'To',
      FROM: 'From',
      FEE: 'Fee',
      EXPLORER: 'View in Explorer',
      LOADING: 'Loading...',
      CONTRACT: 'Contract Interaction',
      INTERACTION: 'Interaction',
    },
  },
  ASSETS: {
    PAGE_TITLE: 'Assets',
    HEADER: {
      TITLE: 'Assets',
    },
    DROPDOWN: {
      TITLE: 'Sort by',
      ASC: 'Amount – Ascending',
      DESC: 'Amount – Descending',
    },
    EMPTY_LABEL: 'No assets found.',
    ACCOUNT_COUNT: 'accounts',
  },
  BUY: {
    PAGE_TITLE: 'Buy Crypto',
    TITLE: 'Buy Crypto',
    ASSET: 'Asset',
    ACCOUNT: 'Account',
    CONTINUE_BTN: 'Continue',
    POPUP: {
      TITLE: 'Choose Asset',
      MORE: 'Search for more results...',
    },
    EMPTY_ACCOUNT_LIST: 'No accounts',
  },
  SEND: {
    PAGE_TITLE: 'Send to',
    FEE: 'Fee',
    FROM: 'From',
    MAX_BTN: 'MAX',
    SEND_BTN: 'Send',
    SEND_BTN_LOADING: 'Loading',
    BUY_BTN: 'Buy',
    LOADING: 'Loading user details...',
  },
  SWAP: {
    PAGE_TITLE: 'Swap',
    TITLE: 'Swap',
    FEE: 'Swap Fee',
    FEE_EMPTY: 'Calculated later',
    APPROVE_FEE: 'Approve Fee',
    FROM: 'From',
    REVIEW_BTN: 'Review swap',
    SWAP_BTN: 'Swap',
    APPROVE_BTN: 'Approve',
    SLIPPAGE: 'Slippage',
    NO_DATA: 'Selected subname does not have any asset to choose from',
    FAILURE: 'Failed to swap tokens',
    SWAPPING: 'Swapping',
    SWAP_FEE_ERROR: 'Failed to get swap fee',
    APPROVE_SUCCESS: 'Successfully approved',
    LOADING: 'Loading',
  },
  ASSET_DETAILS: {
    TITLE: 'Assets',
    SEND_BTN: 'Send',
    BUY_BTN: 'Buy',
    HISTORY: 'History',
    LOADING: 'Loading asset details...',
    CREATED_WALLETS: 'Created {{network}} Wallets',
    WALLET_POPUP: {
      SEND_BTN: 'Send',
      BUY_BTN: 'Buy',
      HISTORY_TITLE: 'History',
    },
  },
  SETTINGS: {
    PAGE_TITLE: 'Profile Settings',
    TITLE: 'Profile Settings',
    MENU: {
      SECURITY_PRIVACY: {
        TITLE: 'Security and Privacy',
        DESC: 'Passwords, biometrics and recovery',
      },
      MANAGE_WALLET: {
        TITLE: 'Manage Wallet',
        DESC: 'Manage wallet and addresses',
      },
      WALLET_BALANCE: {
        TITLE: 'Hide wallet balance in menu',
      },
    },
    DELETE_ACCOUNT_BTN: 'Delete account',
    LOGOUT_BTN: 'Log out from device',
  },
  MANAGE_WALLET: {
    PAGE_TITLE: 'Manage Wallet',
    TITLE: 'Manage Wallet',
    MENU: {
      CREATED_WALLET: 'Created Wallets',
      EXPORT: {
        TITLE: 'Export',
        ITEM: 'Export wallets',
      },
    },
    IMPORT_BTN: 'Import',
    CREATE_BTN: 'Create',
  },
  DELETE_ACCOUNT: {
    TITLE: 'Delete Wallet',
    WARNING_MESSAGE:
      'Make sure you have transferred all your funds, or exported your wallet seed phrase before you delete your account. After deleting, you wont be able to access your wallets if you have not exported your wallet. This can not be reversed.',
    DELETE_ACCOUNT_BTN: 'Delete account',
    PIN_LABEL: 'PIN',
    LABELS: {
      ARE_YOU_SURE: 'Are you sure you want to delete your account?',
      CONFIRM_DELETE: 'Enter your password and PIN to delete your account',
    },
    ERROR: {
      INVALID_CREDENTIALS: 'Invalid Password or Pin',
    },
  },
  EXPORT_WALLET: {
    TITLE: 'Export Wallet',
    EXPORT_WALLET_BTN: 'Export Wallet',
    EXPORT_WALLET_BTN_LOADING: 'Loading',
    WARNING_MESSAGE: `Do not share your private key with anyone. We will never ask for them. Sharing your private keys may result in a loss of funds.`,
    PIN_LABEL: 'PIN',
    SEED_PHRASE_LABEL: 'Seed Phrase',
    INSTRUCTION: {
      INITIAL: 'Export all addresses and wallet.',
      CONFIRM: 'Enter password and PIN to export wallet',
      FINAL: ' exported',
    },
    ERROR: {
      MISSING_CREDENTIALS: 'Missing credentials!',
      INVALID_CREDENTIALS: 'Invalid credentials!',
      EXPORT_FAILED: 'Failed to export mnemonic!',
    },
  },
  EXPORT_ADDRESS: {
    TITLE: 'Export Address',
    EXPORT_ADDRESS_BTN: 'Export Address',
    EXPORT_ADDRESS_BTN_LOADING: 'Loading',
    WARNING_MESSAGE: `Do not share your private key with anyone. We will never ask for them. Sharing your private keys may result in a loss of funds.`,
    PIN_LABEL: 'PIN',
    PRIVATE_KEY_LABEL: 'Private key',
    CONFIRM_INSTRUCTION: 'Enter password and PIN to export address',
    LOADING: 'Loading...',
    ERROR: {
      MISSING_CREDENTIALS: 'Missing credentials!',
      INVALID_CREDENTIALS: 'Invalid credentials!',
      EXPORT_FAILED: 'Failed to export private key!',
    },
  },
  SINGLE_NETWORK_DROPDOWN: {
    TITLE: 'Choose Network',
  },
  IMPORT_ADDRESS: {
    TITLE: 'Import address',
    MESSAGE: 'Choose network, enter private key and a Sub-Name',
    PRIVATE_KEY_LABEL: 'Private key',
    SUBNAME_LABEL: 'Enter a sub-name',
    IMPORT_BTN: 'Import Wallet',
  },
  CREATE_WALLET: {
    TITLE: 'Create address',
    MESSAGE: 'Select a network',
    SUBNAME_LABEL: 'Sub-name',
    INFO: 'a, b, c, d... sub-names are generated automatically.',
    CREATE_BTN: 'Create Address',
    CREATE_BTN_LOADING: 'Loading',
    PIN_LABEL: 'PIN',
    CONFIRM_INSTRUCTION: 'Enter password and PIN to create wallet',
    ERROR: {
      MISSING_CREDENTIALS: 'Missing credentials!',
      INVALID_CREDENTIALS: 'Invalid credentials!',
      CREATE_FAILED: 'Failed to create wallet!',
      RENAME_FAILED: 'Failed to rename wallet!',
    },
  },
  SEARCH: {
    TOKENS_TITLE: 'Tokens',
    TOKENS_NOT_FOUND: 'There are no tokens for this search term.',
    USERS_TITLE: 'Users',
    USERS_NOT_FOUND: 'There are no users for this search term.',
  },
  TOTAL_BALANCE_POPUP: {
    TITLE: 'Total Balance',
    MESSAGE:
      'Total balance of all your tokens on supported blockchains in this wallet. Some tokens and their price may be inaccurate.',
  },
  FOR_YOU: {
    PAGE_TITLE: 'For You',
    BADGE: 'Coming Soon',
    TITLE: 'For you',
    DESC: 'Discover apps from all of Web3 tailored to your likings powered by a privacy preserving recommendation engine',
  },
  ECOSYSTEMS: {
    PAGE_TITLE: 'Ecosystems',
    BADGE: 'Coming Soon',
    TITLE: 'Ecosystems',
    DESC: 'Discover latest ecosystems, their latest updates and applications recommended to you.',
  },
  ERRORS: {
    UNKNOWN: 'Something went wrong, try again',
  },
}

export const webappEn: DeepPartial<LocaleKeys> = {}
export const extensionEn: DeepPartial<LocaleKeys> = {}
