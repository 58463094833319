import { useNavigate } from 'react-router-dom'
import { BackButton } from '../Buttons/BackButton'
import { SendIcon } from '../Buttons/SendIcon'
import { WalletIcon } from '../Buttons/WalletIcon'
import { HomeAssets } from '../Loaders/HomeAssets'

export const AssetHistoryLoader: React.FC = () => {
  const navigate = useNavigate()
  const HandleClick = () => {
    navigate('/home')
  }
  return (
    <div className='w-[53vh] h-[98vh] overflow-hidden p-[2.4vh] flex-col justify-center items-center gap-[1.4vh] inline-flex rounded-xl font-montserrat'>
      <div className='w-[100%] h-[100%] flex-col justify-start items-center flex'>
        <div className='self-stretch h-[8.57vh] px-[2.4vh] py-[1.4vh] rounded-[2.4vh] justify-between items-center inline-flex'>
          <div className='w-[2.1vh] h-[2.1vh] relative'>
            <div className='w-[2.1vh] h-[2.1vh] left-0 top-0 absolute'>
              <BackButton onClick={HandleClick} />
            </div>
          </div>
          <div className='px-[1.4vh] py-[1vh] rounded-[48px] border-[0.2vh] border-fontLightColor dark:border-fontLightColorDark border-opacity-10 justify-center items-center gap[1vh] flex'>
            <div className="text-center text-fontLightColor text-fontLightColorDark text-[1.67vh] font-semibold font-['Montserrat'] leading-tight">
              on
            </div>
            <div className='w-[2.4vh] h-[2.4vh] relative bg-violet-600 rounded border border-stone-950 border-opacity-20 backdrop-blur-[2.6vh]'>
              {/* <img src={assetDetail?.chainLogo}/> */}
            </div>
          </div>
          <div className='w-[2.1vh] h-[2.1vh] relative'>
            <div className='w-[2.1vh] h-[2.1vh] left-0 top-0 absolute'></div>
          </div>
        </div>
        <div className='self-stretch h-[72vh] py-[1vh] flex-col justify-start items-center flex'>
          <div className='self-stretch h-[36.9vh] px-[2.4vh] py-[1.4vh] rounded-[1.07vh] flex-col justify-center items-start gap-[2.8vh] flex'>
            <div className='self-stretch h-[148px] flex-col justify-start items-center gap-[1.4vh] flex'>
              <div className='rounded-[4.76vh] shadow  flex-col justify-center items-center gap-[1vh] flex'>
                <div className='bg-primaryColor dark:bg-primaryDarkColor rounded-[7.1vh] backdrop-blur-[7.6vh] justify-start items-start gap-[1.4vh] inline-flex overflow-hidden'>
                  <div className='w-[7.1vh] h-[7.1vh] dark:w-0 dark:h-0 custom-pulse-light  overflow-hidden relative' />
                  <div className='dark:w-[7.1vh] dark:h-[7.1vh] w-0 h-0 custom-pulse-dark overflow-hidden relative' />
                </div>
              </div>

              <div className='self-stretch dark:w-0 dark:h-0 custom-pulse-light  rounded-[2.4vh]' />
              <div className='w-0 h-0 dark:w-full self-stretch custom-pulse-dark rounded-[2.4vh]' />
            </div>
            <div className='self-stretch py[1.4vh] justify-center items-center gap-[2vh] inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-center items-center gap[1vh] inline-flex cursor-pointer'>
                <div className='bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[4.76vh] w-[7.1vh] h-[7.1vh] border-[3px] border-outlining dark:border-outliningDark flex-col justify-center items-center gap-[10.91px] flex'>
                  <div className='w-[6vh] h-[6vh] flex items-center justify-center'>
                    <WalletIcon color='white' width='4.76vh' height='4.76vh' />
                  </div>
                </div>
                <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                  Buy
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-center items-center gap[1vh] inline-flex cursor-pointer'>
                <div className=' rounded-[4.76vh] w-[7.1vh] h-[7.1vh] border-[3px] border-outlining dark:border-outliningDark flex-col justify-center items-center gap-[1.3vh] flex'>
                  <div className='w-[6vh] h-[6vh] flex items-center justify-center'>
                    <SendIcon color='gray' width='4.76vh' height='4.76vh' />
                  </div>
                </div>
                <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                  Send
                </div>
              </div>
            </div>
          </div>
          <div className='self-stretch min-h-[45.2vh] py-[2vh] rounded-[2vh] flex-col justify-start items-center gap-[1vh] flex overflow-hidden'>
            <HomeAssets />
            <HomeAssets />
            <HomeAssets />
            <div className='self-stretch h-[2.9vh] rounded-[2.4vh] flex-col justify-center items-center gap-[1vh] flex'>
              <div className='self-stretch h-[2.9vh] px-[2.4vh] py-[0.5vh] rounded-[2.4vh] flex-col justify-center items-center gap-[1vh] flex'>
                <div className='self-stretch justify-center items-center gap-[2vh] inline-flex'>
                  <div className='grow shrink basis-0 h-[2vh] justify-center items-center gap-[1vh] flex'>
                    <div className="text-center text-stone-950 text-opacity-40 text-[1.67vh] font-semibold font-['Montserrat'] leading-tight">
                      View more
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='self-stretch py-[1.4vh] rounded-[44px] justify-center items-center inline-flex'>
        <div className='justify-center items-center gap[1vh] flex'>
          <div className='w-[19px] h-[19px] relative rounded-[5px]' />
          <div className='justify-center items-start gap-0.5 flex'>
            <div className="text-center text-zinc-400 text-sm font-semibold font-['Montserrat'] leading-[16.80px]">
              Open Tria
            </div>
            <div className='w-[2.1vh] h-[2.1vh] relative'>
              <div className='origin-top-left rotate-45 w-[2.1vh] h-[2.1vh] left-[9px] top-[[1.4vh].73px] absolute'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
