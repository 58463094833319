import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BalanceState } from './balance.model'

const INITIAL_STATE: BalanceState = {
  isBalanceVisible: true,
}

export const balanceSlice = createSlice({
  name: 'balance',
  initialState: INITIAL_STATE,
  reducers: {
    updateShowBalance: (state, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload
    },
  },
})

export const { updateShowBalance } = balanceSlice.actions
