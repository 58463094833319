import { useState } from 'react'
import { CopyButton } from '../Buttons'

interface Props {
  triaUrl?: string
  explorerUrl?: string
}

export const NftExplore: React.FC<Props> = ({ triaUrl, explorerUrl }) => {
  const [copied, setIsCopied] = useState(false)

  const handleCopyExploreUrl = () => {
    if (explorerUrl) {
      setIsCopied(false)
      navigator.clipboard
        .writeText(explorerUrl)
        .then(() => {
          setTimeout(() => setIsCopied(true), 200)
        })
        .catch((error) => {
          console.error('Failed to copy: ', error)
        })
    }
  }

  return (
    <div className='w-[38vh] py-[2vh] flex flex-col z-50 shadow-lg rounded-[2.4vh] overflow-hidden bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor'>
      {/* <div className='w-[38vh] h-[7.6vh] bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor flex flex-col px-[2.4vh] py-[1.4vh] gap-[0.5vh] justify-start items-start'>
        <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[600] leading-[120%]'>
          {' '}
          View on Tria
        </p>
        <p className='w-[29.3vh] text-fontExtraLightColor dark:text-fontExtraLightColorDark text-[1.67vh] font-[600] leading-[120%] overflow-hidden'>
          {triaUrl}
        </p>
      </div> */}
      <div className='w-[38vh] flex flex-col px-[2.4vh] gap-[0.5vh] justify-start items-start'>
        <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[600] leading-[120%]'>
          {' '}
          View on Explorer
        </p>
        <div className='w-full flex items-center justify-between gap-[2.4vh]'>
          <a href={`${explorerUrl}`} target='_blank'>
            <p className='w-[23.8vh] text-fontExtraLightColor dark:text-fontExtraLightColorDark text-[1.67vh] font-[600] leading-[120%] overflow-hidden'>
              {explorerUrl}
            </p>
          </a>
          {!copied && <CopyButton onClick={handleCopyExploreUrl} />}
          {copied && (
            <div className='bg-hoverColor dark:bg-hoverDarkColor text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-[600] rounded-[1.2vh] px-[1.2vh]'>
              Copied
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
