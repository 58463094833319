import React, { useState } from 'react'
import { Nav, Footer } from '../../package/ui.common'
import { Link, useNavigate } from 'react-router-dom'
import { AuthController } from '@tria-sdk/core'
import OTPInput from 'react-otp-input'
export const PinSetup: React.FC = () => {
  const [pin, setPin] = useState<string>('')
  const [confirmedPin, setConfirmedPin] = useState<string>('')
  const [setupSuccessful, setSetupSucessful] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [state, setState] = useState<number>(0)
  const [hide, setHide] = useState<boolean>(true)

  const navigate = useNavigate()

  const baseUrl = process.env.REACT_APP_SDK_BASE_URL || ''

  const authController = new AuthController(baseUrl)

  const handlePinChange = (pinValue: string) => {
    setPin(pinValue)
    handlePassword(pinValue)
  }

  const handleConfirmedPinChange = (pinValue: string) => {
    setConfirmedPin(pinValue)
  }

  const handleNext = () => {
    if (pin.length === 6) {
      setState(1)
      setError('')
    } else {
      setError('Enter a 6 digit pin')
    }
  }

  const handleSetupPin = async () => {
    if (confirmedPin.length === 6 && confirmedPin === pin) {
      try {
        setLoader(true)
        const set = await authController.setupPin({
          accessToken: localStorage.getItem('tria.accessToken') || '',
          idToken: localStorage.getItem('tria.idToken') || '',
          pin: confirmedPin,
        })
        if (set?.success === true) {
          setError('')
          setLoader(false)
          setSetupSucessful(true)
          setTimeout(() => {
            navigate('/home')
          }, 2500)
        } else {
          setLoader(false)
          setSetupSucessful(false)
          setError(set?.message)
        }
      } catch (err) {
        console.log(err)
      }
    } else if (confirmedPin.length !== 6) {
      setError('Enter a 6 digit pin')
    } else if (confirmedPin !== pin) {
      setError('Pins entered do not match')
    }
  }

  //pin strength bar
  const [message, setMessage] = useState<string>('')
  // const [progress, setProgress] = useState('')

  const handlePassword = (passwordValue: any) => {
    const strengthChecks: any = {
      length: 6,
      hasUpperCase: true,
      hasLowerCase: true,
      hasDigit: true,
      hasSpecialChar: false,
    }

    strengthChecks.length = passwordValue.length === 6 ? true : false
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue)
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue)
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue)
    // strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value)

    let strength =
      verifiedList.length === 4
        ? 'Strong'
        : verifiedList.length >= 2
        ? 'Medium'
        : 'Weak'

    // setProgress(`${(verifiedList.length / 5) * 100}%`)
    setMessage(strength)
  }

  return (
    <div>
      <div className='flex w-[53vh] h-[100vh] items-center justify-center relative   '>
        <div className='w-[100%] h-[100%] p-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat'>
          <div className='w-[100%] flex-col justify-start items-center flex'></div>
          <div className='w-[100%] grow shrink basis-0 flex-col justify-start items-center flex'>
            <Nav />
            <div className='w-full p-[1.4vh] bg-zinc-900 rounded-xl justify-start items-center gap-[1.4vh] inline-flex'>
              <div className='w-[2.8vh] h-[2.8vh] justify-center items-center flex'>
                <div className='w-[2.8vh] h-[2.8vh] relative'>
                  <img src='/icons/danger.svg' alt='danger' />
                </div>
              </div>
              <div className='grow shrink basis-0 h-[34px] justify-start items-center gap-[1vh] flex'>
                <div className="grow shrink basis-0 text-zinc-500 text-[1.67vh] font-medium font-['Montserrat']">
                  You must remember the PIN to recover the account on other
                  devices.
                </div>
              </div>
            </div>
            <div className='w-full mt-[5vh] justify-start items-center gap-[2vh] inline-flex'>
              <div className='grow shrink basis-0 h-[2.8vh] justify-start items-center gap-[1vh] flex'>
                <Link to='/home'>
                  <div className='w-[2.1vh] h-[2.1vh] justify-center items-center flex'>
                    <div className='w-[2.1vh] h-[2.1vh] relative'>
                      <img src='/icons/left.svg' alt='back' />
                    </div>
                  </div>
                </Link>
                <div className="text-center text-neutral-50 text-[2.4vh] font-semibold font-['Montserrat'] leading-normal">
                  PIN recovery
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-center items-end gap-1 inline-flex' />
            </div>
            <div className='mx-[2.8vh]'>
              <div className="w-full mt-[2.4vh] text-zinc-500 text-[1.67vh] font-semibold font-['Montserrat']">
                With the recovery set up, you can retrieve your account if lost
                access.
              </div>
            </div>
            <div className='mt-[5vh]'>
              <div className='w-[44.7vh] h-[2.1vh] px-[1vh] justify-between items-center gap-[1vh] inline-flex'>
                <div className="text-center text-zinc-500 text-[1.67vh] font-semibold font-['Montserrat'] leading-normal">
                  Enter a PIN
                </div>
                <img
                  className='cursor-pointer'
                  onClick={() => setHide(!hide)}
                  src='/icons/slash.svg'
                  alt='hide'
                />
              </div>
            </div>

            <div className='w-full flex justify-center py-[1vh] font-Montserrat mt-[1vh]'>
              {state === 0 ? (
                <OTPInput
                  value={pin}
                  onChange={handlePinChange}
                  numInputs={6}
                  inputType={hide ? 'password' : 'text'}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                  inputStyle={{
                    borderRadius: '2.4vh',
                    marginRight: '1.2vh',
                    width: '5.87vh',
                    height: '4.76vh',
                    fontSize: '2.4vh',
                    color: '#FFF',
                    fontWeight: '400',
                    background: 'rgba(128, 128, 128, 0.08)',
                  }}
                />
              ) : (
                <OTPInput
                  value={confirmedPin}
                  onChange={handleConfirmedPinChange}
                  numInputs={6}
                  inputType={hide ? 'password' : 'text'}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                  inputStyle={{
                    borderRadius: '2.4vh',
                    marginRight: '10px',
                    width: '49.33px',
                    height: '40px',
                    fontSize: '2.4vh',
                    color: '#FFF',
                    fontWeight: '400',
                    background: 'rgba(128, 128, 128, 0.08)',
                  }}
                />
              )}
            </div>
            <div className='py-[1.4vh] justify-center items-center gap-[1vh] flex w-[44.7vh] '>
              {pin.length > 0 && state === 0 && (
                <div className='ml-[1.4vh] self-stretch flex gap-[1vh] items-center w-full'>
                  <div
                    className={`
                      ${
                        message === 'Weak'
                          ? 'w-16 h-[1vh] bg-red-500 rounded-[2.8vh]'
                          : message === 'Medium'
                          ? 'w-16 h-[1vh] bg-yellow-500 rounded-[2.8vh]'
                          : message === 'Strong'
                          ? 'w-16 h-[1vh] bg-lime-600 rounded-[2.8vh]'
                          : null
                      }
                    `}
                  ></div>

                  <div
                    className={`
                      ${
                        message === 'Weak'
                          ? 'w-16 h-[1vh] bg-zinc-500 rounded-[2.8vh]'
                          : message === 'Medium'
                          ? 'w-16 h-[1vh] bg-yellow-500 rounded-[2.8vh]'
                          : message === 'Strong'
                          ? 'w-16 h-[1vh] bg-lime-600 rounded-[2.8vh]'
                          : null
                      }
                    `}
                  ></div>
                  <div
                    className={`
                      ${
                        message === 'Weak'
                          ? 'w-16 h-[1vh] bg-zinc-500 rounded-[2.8vh]'
                          : message === 'Medium'
                          ? 'w-16 h-[1vh] bg-yellow-500 rounded-[2.8vh]'
                          : message === 'Strong'
                          ? 'w-16 h-[1vh] bg-lime-600 rounded-[2.8vh]'
                          : null
                      }
                    `}
                  ></div>
                  <span
                    className={`
                      ${
                        message === 'Medium'
                          ? 'text-yellow-500 font-semibold text-[1.67vh]'
                          : message === 'Strong'
                          ? 'text-lime-600 font-semibold text-[1.67vh]'
                          : message === 'Weak'
                          ? 'text-red-500 font-semibold text-[1.67vh]'
                          : null
                      }
                    `}
                  >
                    {message}
                  </span>
                </div>
              )}
            </div>
            <div className='mx-[2.8vh] mt-[19vh] '>
              {error === '' ? (
                <div className="text-zinc-500 text-[1.67vh] font-medium font-['Montserrat']">
                  Losing this password will make your account inaccessible on
                  new devices. You will only be asked for it when you log on to
                  a new device.
                </div>
              ) : (
                <div
                  className='flex items-center w-[44vh] justify-center p-[2vh] mb-[1vh] text-[1.67vh] text-red-500 rounded-[1vh] bg-zinc-900'
                  role='alert'
                >
                  <svg
                    className='flex-shrink-0 inline w-[2vh] h-[2vh] me-[1.4vh]'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
                  </svg>
                  <span className='sr-only'>Info</span>
                  <div>
                    <span className='font-medium'>Error - </span> {error}.
                    Please try again!
                  </div>
                </div>
              )}
              {state === 0 ? (
                <div
                  onClick={
                    pin.length === 6 && message === 'Strong'
                      ? handleNext
                      : () => console.log('error')
                  }
                  className={
                    pin.length === 6 && message === 'Strong'
                      ? 'mt-[2vh] cursor-pointer w-full h-[5vh] px-[2.4vh] py-[1.4vh] bg-neutral-50 rounded-[2.4vh] justify-center items-center inline-flex'
                      : 'mt-[2vh] w-full h-[5vh] px-[2.4vh] py-[1.4vh] cursor-disabled bg-neutral-600 cursor-not-allowed rounded-[2.4vh] justify-center items-center inline-flex'
                  }
                >
                  <div className='justify-center items-center flex'>
                    <div className="text-center text-stone-950 text-[2vh] font-semibold font-['Montserrat'] leading-tight">
                      Next
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => handleSetupPin()}
                  className={
                    setupSuccessful === false
                      ? `mt-[2vh] cursor-${
                          confirmedPin.length === 6 ? 'pointer' : 'not-allowed'
                        } w-full h-[5vh] px-[2.4vh] py-[1.4vh] bg-${
                          confirmedPin.length === 6
                            ? 'neutral-50'
                            : 'neutral-600'
                        } rounded-[2.4vh] justify-center items-center inline-flex`
                      : 'mt-[2vh] cursor-pointer w-full h-[5vh] px-[2.4vh] py-[1.4vh] bg-lime-500 rounded-[2.4vh] justify-center items-center inline-flex'
                  }
                >
                  <div className='justify-center items-center flex'>
                    {loader === false ? (
                      <div className="text-center text-stone-950 text-[2vh] font-semibold font-['Montserrat'] leading-tight">
                        {setupSuccessful === false ? (
                          <span>Confirm</span>
                        ) : (
                          <span>Success</span>
                        )}
                      </div>
                    ) : (
                      <div className="text-center text-stone-950 text-[2vh] font-semibold font-['Montserrat'] leading-tight">
                        <div role='status'>
                          <svg
                            className='animate-spin h-[3.3vh] w-[3.3vh]'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                          >
                            <circle
                              className='opacity-25'
                              cx='12'
                              cy='12'
                              r='10'
                              stroke='currentColor'
                              strokeWidth='4'
                            ></circle>
                            <path
                              className='opacity-75'
                              fill='currentColor'
                              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
