import React, { useEffect, useState } from "react"
import { useFetchAssets } from "../../../../../hooks"
import { AssetForTriaName } from "../../../../rx.core"
import { Accordion } from "./Accordion" // Import your Accordion component
import { useSelector } from "react-redux"
import { RootState } from "../../../../rx.core"
import HomeCrypto from "../../components/LoadersPages/HomeCrypto"

interface GroupedAssets {
  [key: string]: AssetForTriaName[]
}

export const Crypto: React.FC = () => {
  const [groupedAssets, setGroupedAssets] = useState<GroupedAssets>({})
  const [lowBalanceGroup, setLowBalanceGroup] = useState<GroupedAssets>({})
  const isLoading = useFetchAssets()
  const [appDomain, setAppDomain] = useState<string>()

  const fromAssets = useSelector((store: RootState) => store.User?.userAssets)

  useEffect(() => {
    const updatedGroupedAssets: GroupedAssets = {}
    const lowbalupdatedGroupedAssets: GroupedAssets = {}
    fromAssets.forEach((asset) => {
      const { name } = asset
      if (!updatedGroupedAssets[name] && asset?.balanceInUSD > 0) {
        updatedGroupedAssets[name] = []
      } else if (
        !lowbalupdatedGroupedAssets[name] &&
        asset?.balanceInUSD === 0
      ) {
        lowbalupdatedGroupedAssets[name] = []
      }
      updatedGroupedAssets[name]?.push(asset)
      lowbalupdatedGroupedAssets[name]?.push(asset)
    })
    setGroupedAssets(updatedGroupedAssets)
    setLowBalanceGroup(lowbalupdatedGroupedAssets)
  }, [fromAssets])

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app")
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setAppDomain(parsedData?.appDomain)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  return (
    <div className='w-full h-[44vh] rounded-[2.7vh] flex-col justify-start items-center gap-[1.4vh] flex'>
      <div className='self-stretch px-[3.4vh] pt-[2vh] rounded-[2.7vh] flex-col justify-center items-center gap-[1.4vh] flex'>
        <div className='self-stretch justify-start items-center gap-[2.7vh] inline-flex'>
          <div className='justify-start items-center gap-[1.4vh] flex'>
            <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-semibold font-montserrat leading-normal'>
              Crypto
            </div>
          </div>
        </div>
      </div>
      {isLoading && <HomeCrypto />}
      {!isLoading && fromAssets && (
        <div className='w-full overflow-y-auto mb-[2vh]'>
          {/* {Object.keys(groupedAssets)?.length > 0 && ( */}
          <div className='w-full h-full'>
            <Accordion groupedAssets={groupedAssets} />
            <Accordion groupedAssets={lowBalanceGroup} />
          </div>
          {/* )} */}
        </div>
      )}
      {!isLoading &&
        !Object.keys(groupedAssets)?.length &&
        !Object.keys(lowBalanceGroup)?.length && (
          <div className='scrollbar-thin w-full scrollbar-thumb-gray-300 scrollbar-track-stone-100 overflow-y-auto flex flex-col items-center justify-center h-[38vh] gap-[1.4vh]'>
            <img
              src='./images/nocryptodark.svg'
              className='w-[17.85vh] h-[17.85vh]'
            />
            <p className='font-[600] text-[1.67vh] text-fontLightColor dark:text-fontLightColorDark'>
              You have no crypto right now
            </p>
          </div>
        )}
    </div>
  )
}
