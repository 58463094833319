import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ToastState } from './toast.model'
import { ToastData } from '../../../types'

const INITIAL_STATE: ToastState = {
  isOpen: false,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState: INITIAL_STATE,
  reducers: {
    openToast: (state) => {
      state.isOpen = true
    },
    closeToast: (state) => {
      state.isOpen = false
      state.toastData = undefined
    },
    clearData: (state) => {
      state.toastData = undefined
    },
    setData: (state, action: PayloadAction<ToastData>) => {
      state.toastData = action.payload
    },
  },
})

export const { openToast, clearData, closeToast, setData } = toastSlice.actions
