import { reverseString } from '../../utils'

export const PIN_LENGTH = 6

export const PIN_SEQUENCES: string[] = [
  '012345',
  '123456',
  '234567',
  '345678',
  '456789',
]
  .map((m) => [m, reverseString(m)])
  .flat()
