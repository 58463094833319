interface Props {
  color: string
  width: string
  height: string
}

export const SendIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.41699 11.4583C8.41699 12.2666 9.042 12.9166 9.80867 12.9166H11.3753C12.042 12.9166 12.5837 12.3499 12.5837 11.6416C12.5837 10.8833 12.2503 10.6083 11.7587 10.4333L9.25033 9.55828C8.75866 9.38328 8.42533 9.11662 8.42533 8.34995C8.42533 7.64995 8.96699 7.07495 9.63365 7.07495H11.2003C11.967 7.07495 12.592 7.72495 12.592 8.53328'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5002 6.25V13.75'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8334 9.99996C18.8334 14.6 15.1001 18.3333 10.5001 18.3333C5.90008 18.3333 2.16675 14.6 2.16675 9.99996C2.16675 5.39996 5.90008 1.66663 10.5001 1.66663'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8333 4.99996V1.66663H15.5'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6667 5.83329L18.8334 1.66663'
        stroke={color}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
