import { useDispatch, useSelector } from "react-redux";
import { useTriaUser } from "../../contexts";
import {
  RootState,
  store,
  updateSenderAvatar,
  updateUserAvatar,
} from "../../../../rx.core";
import { useEffect, useState } from "react";
import { UserAvatar } from "../Containers/user/UserAvatar";
// import logout from '@tria-sdk/core/controllers/auth.controller'

import { useDisconnect, useSwitchChain } from "@tria-sdk/connect";

export const UserMenu: React.FC = () => {
  const { updateAvatar } = useTriaUser();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const storedData = localStorage.getItem("tria.accessToken");
    if (storedData !== null) {
      setAccessToken(storedData);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);
  useEffect(() => {}, [accessToken]);
  const handleAvatarChangeClick = async () => {
    if (accessToken) {
      try {
        setIsLoading(true);
        const response = await updateAvatar(accessToken);
        if (response?.avatar) {
          dispatch(updateUserAvatar(response));
          dispatch(updateSenderAvatar(response));

          // Notify the parent window that the profile photo has changed
          const message = {
            type: "PROFILE_PHOTO_UPDATED",
            payload: { message: true },
          };
          window.parent.postMessage(message, "*");
        }
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleLogout = () => {
  //   // logout();
  //   disconnect()
  //   window.open("https://auth-7rin.vercel.app/logout","_self")
  // }
  return (
    <div className="flex flex-col w-[38vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[2.4vh] overflow-hidden">
      <div
        className="flex px-[2.4vh] py-[1.4vh] items-center gap-[2vh] self-stretch h-[8vh] cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
        onClick={handleAvatarChangeClick}
      >
        <div className="w-[26.7vh] flex flex-col items-start justify-start">
          <p className="flex text-start text-[2vh] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor ">
            Change Avatar
          </p>
          <p className="flex text-center text-[1.7vh] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">
            choose your avatar
          </p>
        </div>
        <UserAvatar loading={isLoading} />
      </div>
      {/* <div className="flex px-[20px] py-[12px] items-center gap-[16px] self-stretch h-[64px] cursor-auto bg-hoverColor dark:bg-hoverDarkColor">
    <div className="w-[224px] flex flex-col items-start justify-start">
     <p className="flex text-center text-[16px] font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor ">Settings</p>
     <p className="flex text-center text-[14px] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">& preferences</p>
    </div>
   <div className="w-[56px] md:w-[80px] flex items-end justify-end">
     <img className="w-[16.8px] md:w-[24px] h-[16.8px] md:h-[24px] mr-[5.6px] md:mr-[8px] rounded-[14px] md:rounded-[20px]" src="./icons/setting-2.svg"/>
    </div>
   </div> */}
      {/* <div className="flex px-[20px] py-[12px] items-center gap-[16px] self-stretch h-[64px] justify-between cursor-pointer hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[4px]" onClick= {handleLogout}>
    <div className="w-[224px] flex flex-col items-start justify-start" >
     <p className="flex text-center text-[16px] font-[600] leading-[120%] text-redWarning ">Sign Out</p>
     <p className="flex text-center text-[14px] font-[600] leading-[120%] text-fontLightColor dark:text-fontLightColorDark">from thekaypo@tria</p>
    </div>
    <div className="w-[56px] md:w-[80px] flex items-end justify-end">
     <img className="w-[16.8px] md:w-[24px] h-[16.8px] md:h-[24px] mr-[5.6px] md:mr-[8px] rounded-[14px] md:rounded-[20px]" src="./icons/logout.svg"/>
    </div>
   </div> */}
    </div>
  );
};
