import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendTokenState, TokenState } from "./token.model";

const initialState: SendTokenState = {
 tokenState:{
 recipientTriaName: "",
 recipientAddress:"",
 amount: 0,
 tokenAddress: "",
 chainName: "",
 qouteRate: 0,
recipientAvatar:{
  avatar:"",
  background: "",
  char: ""
}
 }
  };

export const sendTokenSlice = createSlice({
 name:"SendToken",
 initialState,
 reducers:{

  updateCurrentSendToken:( state, action:PayloadAction<TokenState>) =>{
   state.tokenState.tokenAddress = action.payload.tokenAddress;
   state.tokenState.chainName = action.payload.chainName;
   state.tokenState.qouteRate = action.payload.qouteRate
  },

  updateCurrentRecipient:(state, action:PayloadAction<string>) =>{
   state.tokenState.recipientTriaName = action.payload;
  },
  updateCurrentRecipientAddress:(state, action:PayloadAction<string>) =>{
    state.tokenState.recipientAddress = action.payload
  },

  updateSendAmount:(state,action:PayloadAction<number>) =>{
   state.tokenState.amount = action.payload;
  },

  updateRecipientAvatar: (state, action:PayloadAction<{avatar?:string, background:string, char?:string}>) => {
    state.tokenState.recipientAvatar = action.payload
  }
 }
})

export const {updateCurrentSendToken, updateCurrentRecipient,updateSendAmount, updateRecipientAvatar, updateCurrentRecipientAddress} = sendTokenSlice.actions;

