import { Names } from '../../../../../rx.core'

interface Props {
  avatar?: string
  background?: string
  char?: string
  height?: string
  width?: string
  borderRadius?: string
}

export const AvatarContainer: React.FC<Props> = ({
  avatar,
  background,
  char,
  height = '4.8vh',
  width = '4.8vh',
  borderRadius = '2.4vh',
}) => {
  return (
    <>
      {!char && (
        <div
          className='flex justify-center items-center'
          style={{
            backgroundImage: background,
            height: height,
            width: width,
            borderRadius: borderRadius,
          }}
        >
          <img
            src={avatar}
            className=' rounded-[2.7vh]'
            style={{ height: height, width: width }}
          />
        </div>
      )}
      {char && (
        <div
          className='h-[4.2vh] w-[4.2vh] rounded-[2.1vh] flex justify-center items-center text-white capitalize font-[600] text-[2.4vh]'
          style={{
            backgroundColor: background,
            height: height,
            width: width,
            borderRadius: borderRadius,
          }}
        >
          {char}
        </div>
      )}
    </>
  )
}
