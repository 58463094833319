import { useTriaUser } from '../package/ui.common'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateApiTimer, updateRecentUsers } from '../package/rx.core'
import { useReload } from '../package/ui.common/src/contexts/reload/ReloadContext'

export function useFetchRecentUsers() {
  const dispatch = useDispatch()
  const { reload } = useReload()
  const { getRecentUsers } = useTriaUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [triaName, setTriaName] = useState<string>()

  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchRecentUsers = async () => {
    if (triaName) {
      try {
        setLoading(true)
        const response = await getRecentUsers(triaName)
        dispatch(updateRecentUsers(response.data))
        dispatch(
          updateApiTimer({
            apiEndpoint: 'recentUsers',
            time: Date.now(),
          })
        )
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchRecentUsers() // Initial fetch
    // Fetch every 60secs
    const intervalId = setInterval(() => {
      fetchRecentUsers()
    }, 60000)
    return () => {
      clearInterval(intervalId)
    }
  }, [triaName])
  useEffect(() => {
    if (reload) fetchRecentUsers()
  }, [reload])

  return loading
}
