import React from 'react'
import { NftAsset } from '../Loaders/NftAsset'

export default function NftAssets() {
  return (
    <div className='flex gap-[2.8vh]'>
      <NftAsset />
      <NftAsset />
    </div>
  )
}
