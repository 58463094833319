import { useDispatch, useSelector } from 'react-redux'
import { RootState, updateShowBalance } from '../../../../rx.core'

export const HideBalance: React.FC = () => {
  const isTextVisible = useSelector(
    (store: RootState) => store?.balance?.isBalanceVisible
  )
  const dispatch = useDispatch()
  return (
    <div
      className='w-[18.8vh] h-[5.1vh] relative cursor-pointer'
      onClick={() => {
        dispatch(updateShowBalance(!isTextVisible))
      }}
    >
      <img src='/images/hidebalance.svg' alt='' className='opacity-60' />
      <div className='absolute top-[0.8vh] left-[40%] hover:translate-y-[-0.2vh] transform duration-300'>
        {isTextVisible ? (
          <img
            src='/icons/eye-slash.svg'
            alt=''
            className='cursor-pointer w-[2.4vh] h-[2.4vh]'
          />
        ) : (
          <img
            src='/icons/eye-slash.svg'
            alt=''
            className='cursor-pointer w-[2.4vh] h-[2.4vh]'
          />
        )}
      </div>
    </div>
  )
}
