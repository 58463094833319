import React, { useEffect, useState } from "react"
import { formatCurrency, AssetForTriaName } from "../../../../rx.core"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  updateAssetBalance,
  updateFetchParams,
} from "../../../../rx.core/src/redux/features/assethistory"

interface ExpandedGroups {
  [groupName: string]: boolean
}

interface GroupedAssets {
  [key: string]: AssetForTriaName[]
}

interface Props {
  groupedAssets: GroupedAssets | undefined
}

export const Accordion: React.FC<Props> = ({ groupedAssets }) => {
  const [expandedGroups, setExpandedGroups] = useState<ExpandedGroups>({})
  const [currentActive, setCurrentActive] = useState<number>()
  const [percentChangein24hr, setPercentChangeIn24hr] = useState<number>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if(groupedAssets){
  //     setPercentChangeIn24hr(groupedAssets[groupName][0]?.percentChangein24hr || 0)
  //   }
  // },[])

  const toggleGroup = (groupName: string, index?: number) => {
    if (groupedAssets && groupedAssets[groupName].length > 1) {
      setExpandedGroups((prevExpandedGroups) => {
        return {
          ...prevExpandedGroups,
          [groupName]: !prevExpandedGroups[groupName],
        }
      })
      if (index === currentActive) {
        setCurrentActive(-1)
      } else {
        setCurrentActive(index)
      }
    }
  }
  useEffect(() => {}, [groupedAssets])

  const handleClick = () => {
    alert("Hello")
  }

  const calculateTotalPercentChange = (group: any): number => {
    return group.reduce(
      (total: any, item: any) => total + parseFloat(item?.percentChangein24hr),
      0
    )
  }

  const calculateTotalBalanceInUSD = (group: any): any => {
    return group.reduce(
      (total: any, item: any) => total + parseFloat(item?.balanceInUSD),
      0
    )
  }

  const calculateTotalBalanceInToken = (group: any): any => {
    return group.reduce(
      (total: any, item: any) => total + parseFloat(item?.balanceInTokens),
      0
    )
  }
  const calculateTranslation = (index: any) => {
    return index > 0 ? `calc(-2.4vh * ${index})` : "0"
  }
  // const handleClickAsset = () => {
  //   navigate("/activity/TransactionDetailCrypto")
  // }
  const handleAssetClick = (
    item: AssetForTriaName[],
    groupName: string,
    index: number
  ) => {
    if (item.length > 1) {
      toggleGroup(groupName, index)
    } else {
      const tokenAddress = item[0]?.tokenAddress?.toString()
      const chainName = item[0]?.chainName
      const assetBalance = item[0]?.balanceInTokens

      dispatch(updateFetchParams({ chainName, tokenAddress }))
      navigate("/assethistory")
      dispatch(
        updateAssetBalance({
          assetBalance: assetBalance,
          valueInUsd: item[0]?.balanceInUSD,
        })
      )
    }
  }

  const handleAssetClickForExpanded = (item: AssetForTriaName) => {
    const tokenAddress = item?.tokenAddress?.toString()
    const chainName = item?.chainName
    dispatch(updateFetchParams({ tokenAddress, chainName }))
    dispatch(
      updateAssetBalance({
        assetBalance: item?.balanceInTokens,
        valueInUsd: item?.balanceInUSD,
      })
    )
    navigate("/assethistory")
  }
  const handlePercentChange = (value: number | null) => {
    if (value == null) {
      return 0
    } else {
      return value
    }
  }
  //
  useEffect(() => {
    // console.log('log from accordion --->', groupedAssets)
    groupedAssets && Object.keys(groupedAssets).map((groupName: any) => {})
  }, [groupedAssets])
  return (
    <div className='overflow-hidden'>
      {groupedAssets &&
        Object.keys(groupedAssets).map(
          (groupName, index) =>
            calculateTotalBalanceInToken(groupedAssets[groupName])?.toFixed(
              4
            ) != 0 && (
              <div key={index}>
                <div
                  className={`w-[100%] h-[8.5vh] px-[2.4vh] py-[1.4vh]  rounded-[2.5vh] flex-col font-montserrat justify-center items-center gap-[1vh] inline-flex group cursor-pointer overflow-hidden ${
                    currentActive === index
                      ? "bg-hoverColor dark:bg-hoverDarkColor"
                      : "bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
                  }`}
                  onClick={() => {
                    handleAssetClick(groupedAssets[groupName], groupName, index)
                  }}
                >
                  <div
                    className={`self-stretch justify-start items-center gap-[2vh] inline-flex ${
                      currentActive === index
                        ? "bg-hoverColor dark:bg-hoverDarkColor"
                        : ""
                    }`}
                  >
                    {groupedAssets[groupName].length > 1 ? (
                      <div
                        className={`grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[2vh] flex "}`}
                      >
                        {/* onClick={()=>{handleAssetClick(groupedAssets[groupName],groupName,index)}} */}
                        <div className='w-[4.76vh] h-[4.76vh] relative rounded-[4.76vh]'>
                          <div className='w-[4.76vh] h-[4.76vhh] left-[-1px] top-[-1px] absolute rounded-[4.76vh]'>
                            <img
                              src={groupedAssets[groupName][0]?.logoUrl}
                              alt={`image of ${groupedAssets[groupName][0]?.symbol}`}
                              className=''
                            />
                          </div>
                          <div className='left-[2.85vh] top-[2.97vh] absolute flex gap-[0.6vh]'>
                            {groupedAssets[groupName]?.map((item, index) => (
                              <div
                                className={`left-[0vh] top-[0vh] absolute justify-start items-center gap-[0.6vh] inline-flex overflow-hidden transition-transform duration-300 ease-in-out ${
                                  index === 0
                                    ? ""
                                    : index === 1
                                    ? "translate-x-[1.2vh] group-hover:translate-x-[3vh]"
                                    : index === 2
                                    ? "translate-x-[2.4vh] group-hover:translate-x-[6vh]"
                                    : index === 3
                                    ? "translate-x-[3.6vh] group-hover:translate-x-[9vh]"
                                    : index === 4
                                    ? "translate-x-[4.8vh] group-hover:translate-x-[12vh]"
                                    : "translate-x-[6vh] group-hover:translate-x-[15vh]"
                                }`}
                              >
                                <div
                                  key={item?.chainName}
                                  className={`w-[2.4vh] h-[2.4vh]`}
                                >
                                  <img
                                    key={item?.chainName}
                                    className='w-[2.4vh] h-[2.4vh] rounded-[0.5vh] overflow-hidden '
                                    // style={{
                                    //   transform: `translateX(${calculateTranslation(index)})`,
                                    // }}
                                    src={item?.chainLogo}
                                    alt={item?.chainName}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
                          <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
                            <div className='ml-[0.5vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90 text-[2vh] font-semibold  leading-tight group-hover:translate-y-[-1vh] transform duration-300'>
                              {groupedAssets[
                                groupName
                              ][0]?.symbol?.toUpperCase()}
                            </div>
                          </div>
                          <div className='self-stretch justify-start items-center gap-[0.5vh] inline-flex ml-[1vh]  relative'>
                            <div className='text-center hidden text-fontLightColor dark:text-fontLightColorDark text-[1.4vh] font-semibold  leading-tight overflow-hidden transform translate-y-[3vh] opacity-0 group-hover:opacity-100 duration-0 ease-in-out '>
                              {calculateTotalBalanceInUSD(
                                groupedAssets[groupName]
                              )}
                            </div>
                            <div
                              className={`absolute hidden bottom-0 left-10 ml-2 h-0  px-[1vh]  bg-opacity-10 rounded-[3.5vh] overflow-hidden transition-all duration-300 ease-in-out group-hover:h-[2vh]">
                       ${
                         calculateTotalPercentChange(
                           groupedAssets[groupName]
                         ) >= 0
                           ? "bg-pink-500"
                           : "bg-pink-500"
                       }`}
                            >
                              <div
                                className={`text-center text-[1.2vh] font-semibold  leading-tight transform translate-y-[3.8vh]  ${
                                  calculateTotalPercentChange(
                                    groupedAssets[groupName]
                                  ) > 0
                                    ? "text-greenWarning"
                                    : "text-redWarning"
                                } `}
                              >
                                {calculateTotalPercentChange(
                                  groupedAssets[groupName]
                                )?.toFixed(2)}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='grow shrink basis-0 h-[4.7vh] justify-start items-center gap-[1vh] flex'>
                        <div className='w-[4.7vh] h-[4.7vh] relative rounded-[4.7vh]'>
                          <div className='w-[4.7vh] h-[4.7vh] left-0 top-0 absolute rounded-[4.7vh] overflow-hidden'>
                            {groupedAssets[groupName][0]?.logoUrl && (
                              <img
                                src={groupedAssets[groupName][0]?.logoUrl}
                                alt={`image`}
                                className={`${
                                  groupedAssets[
                                    groupName
                                  ][0]?.name?.toUpperCase() === "FUSE"
                                    ? "scale-[120%]"
                                    : ""
                                }`}
                              />
                            )}
                            {!groupedAssets[groupName][0]?.logoUrl && (
                              <div className='w-[4.7vh] h-[4.7vh] left-0 top-0 absolute rounded-[4.7vh] bg-[#a855f7] text-primaryColor text-[3.6vh] flex items-center justify-center font-[600]'>
                                {groupedAssets[groupName][0]?.symbol?.charAt(0)}
                              </div>
                            )}
                          </div>
                          <div className='left-[2.86vh] top-[2.86vh] absolute justify-start items-center gap-[0.6vh] inline-flex rounded-[0.5vh] overflow-hidden'>
                            <div className='w-[2.4vh] h-[2.4vh]  flex-col justify-center items-center overflow-hidden rounded-[0.5vh] '>
                              {groupedAssets[groupName][0]?.chainLogo && (
                                <img
                                  className='w-[2.4vh] h-[2.4vh] '
                                  src={groupedAssets[groupName][0]?.chainLogo}
                                  alt={groupedAssets[groupName][0]?.chainName}
                                />
                              )}
                              {!groupedAssets[groupName][0]?.chainLogo && (
                                <div className='w-[2.4vh] h-[2.4vh] flex justify-center items-center text-[1vh] bg-[#a855f7] text-primaryColor'>
                                  logo
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='grow shrink basis-0 flex-col justify-start items-start  inline-flex'>
                          <div className=' justify-centeritems-center flex-col'>
                            <div className='ml-[0.5vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90 text-[2vh] font-semibold mt-[2vh] group-hover:translate-y-[-1vh] transform duration-300'>
                              {groupedAssets[
                                groupName
                              ][0]?.symbol?.toUpperCase()}
                            </div>
                            <div className='self-stretch justify-start items-center gap-[0.2vh] mt-[-1vh] flex ml-[1vh] relative transform translate-y-[3vh] opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transition-opacity duration-300 ease-in-out w-full'>
                              <div className='text-center text-fontExtraLightColor dark:text-fontExtraLightColorDark text-[1.42vh] font-semibold opacity-0 group-hover:opacity-100  leading-tight overflow-hidden '>
                                {groupedAssets[
                                  groupName
                                ][0]?.quoteRate?.toFixed(2)}
                              </div>
                              {
                                <div
                                  className={` ml-[1vh] px-[1vh] h-0 bg-opacity-10 rounded-[3.57vh] overflow-hidden opacity-0 group-hover:opacity-100 group-hover:h-[2vh] flex items-center justify-center ${
                                    handlePercentChange(
                                      groupedAssets[groupName][0]
                                        ?.percentChangein24hr
                                    )
                                      ? handlePercentChange(
                                          groupedAssets[groupName][0]
                                            ?.percentChangein24hr
                                        ) > 0
                                        ? "bg-greenWarning"
                                        : handlePercentChange(
                                            groupedAssets[groupName][0]
                                              ?.percentChangein24hr
                                          ) < 0
                                        ? "bg-redWarning"
                                        : " dark:bg-primaryDarkColor bg-primaryColor"
                                      : "invisible"
                                  } `}
                                >
                                  <div
                                    className={`text-center text-[1.2vh] font-semibold  leading-tight  ${
                                      handlePercentChange(
                                        groupedAssets[groupName][0]
                                          ?.percentChangein24hr
                                      )
                                        ? handlePercentChange(
                                            groupedAssets[groupName][0]
                                              ?.percentChangein24hr
                                          ) > 0
                                          ? "text-greenWarning"
                                          : handlePercentChange(
                                              groupedAssets[groupName][0]
                                                ?.percentChangein24hr
                                            ) < 0
                                          ? "text-redWarning"
                                          : " dark:text-primaryDarkColor text-primaryColor"
                                        : "invisible"
                                    } `}
                                  >
                                    {groupedAssets[
                                      groupName
                                    ][0]?.percentChangein24hr?.toFixed(2)}
                                    %
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='grow shrink basis-0 flex-col justify-center items-end gap-[0.5vh] inline-flex'>
                      <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90 text-[2vh] font-semibold  leading-tight'>
                        {formatCurrency(
                          calculateTotalBalanceInUSD(groupedAssets[groupName]),
                          false
                        )}
                      </div>
                      <div className='text-center text-fontExtraLightColor dark:text-fontExtraLightColorDark text-[1.67vh] font-semibold  leading-tight'>
                        {calculateTotalBalanceInToken(
                          groupedAssets[groupName]
                        )?.toFixed(4)}{" "}
                        {groupedAssets[groupName][0]?.symbol}
                      </div>
                    </div>
                  </div>
                </div>
                {expandedGroups[groupName] && (
                  <div className={`transform duration-150`}>
                    {groupedAssets[groupName].map((item, index) => (
                      <div
                        key={index}
                        className='h-[8.6vh] w-[100%] flex flex-col justify-center items-center gap-[8px] self-stretch pl-[3.8vh] pr-[2.4vh] py-[1.4vh] hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[2.5vh] cursor-pointer'
                        onClick={() => handleAssetClickForExpanded(item)}
                      >
                        <div className='flex items-center gap-[2vh] self-stretch'>
                          <div className='flex items-center gap-[1vh] flex-1'>
                            <p className='justify-start text-[2vh] font-[600] items-center mx-[1vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90'>
                              {index + 1}
                            </p>
                            <div className='w-[4.76vh] h-[4.76vh] rounded-[4.76vh] shadow-sm relative'>
                              <div className='flex w-[4.76vh] h-[4.76vh] flex-col justify-center items-center flex-shrink-0 rounded-[4.76vh]  '>
                                <img
                                  src={item.logoUrl}
                                  alt={item.name}
                                  className='w-[4.76vh] h-[4.76vh] flex-shrink-0 rounded-[3.57vh]'
                                />
                              </div>
                              <div className='flex w-[2.4vh] h-[2.4vh] flex-col justify-center items-center flex-shrink-0 left-[2.8vh] top-[2.9vh] absolute overflow-hidden rounded-[0.5vh]'>
                                <img
                                  src={item.chainLogo}
                                  alt={item.chainName}
                                  className='w-[2.4vh] h-[2.4vh] flex-shrink-0'
                                />
                              </div>
                            </div>
                            <div className='flex flex-col items-center gap-[0.5vh] flex-1'>
                              <div className='flex items-center gap-[8px] self-stretch text-center'>
                                <p className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[600] leading-tight'>
                                  {item.symbol}
                                </p>
                              </div>
                              <div className='flex items-center gap-[1vh] self-stretch text-fontLightColor dark:text-fontLightColorDark text-center text-[1.67vh] font-[600] leading-tight'>
                                <p className='text-fontExtraLightColor dark:text-fontExtraLightColorDark text-center text-[1.67vh] font-[600] leading-tight'>
                                  {item.chainName}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='flex flex-col justify-center items-end gap-[0.5vh] flex-1'>
                            <p className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[600] leading-[120%]'>
                              ${item.balanceInUSD?.toFixed(6)}
                            </p>
                            <p className='text-fontExtraLightColor dark:text-fontExtraLightColorDark text-center text-[1.67vh] font-[600] leading-[120%]'>
                              {typeof item?.balanceInTokens === "string"
                                ? parseFloat(item.balanceInTokens)?.toFixed(6)
                                : item.balanceInTokens?.toFixed(6)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
        )}
    </div>
  )
}
