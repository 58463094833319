import { TriaRouterInternalService } from './router.model'

export class RouterService {
  private static _instance: RouterService
  routeData: unknown[]

  private constructor(
    private readonly internalService: TriaRouterInternalService
  ) {
    this.routeData = []
  }

  public static getInstance() {
    if (!this._instance) {
      throw new Error('RouterService not initalized')
    }
    return this._instance
  }

  public static initialize(service: TriaRouterInternalService) {
    if (this._instance) {
      throw new Error('RouterService already initalized')
    }
    this._instance = new RouterService(service)
  }

  public navigate(path: string) {
    if (typeof path !== 'string') {
      throw new Error(`'path' must be typeof 'string', received ${typeof path}`)
    }
    this.internalService.navigate(path)
  }

  public navigateExternal(path: string) {
    if (typeof path !== 'string') {
      throw new Error(`'path' must be typeof 'string', received ${typeof path}`)
    }
    this.internalService.navigate(path)
  }

  public setRouteData(...args: unknown[]) {
    this.routeData = args
  }

  public getRouteData(): unknown[] {
    return this.routeData
  }
}
