import { useNavigate } from "react-router-dom"
import { EyeSlash, HideBalance, Refresh, useTriaUser } from "../../../.."
import "../index.css"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { formatCurrency } from "../../../../../rx.core"
import React from "react"
import { useFetchTotalBalance } from "../../../../../../hooks"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../rx.core"
import { SendBuyButtons } from "../../Buttons/SendBuyButtons"
import triaOrange from "./tria-orange.svg"
import triagreen from "./tria-green.svg"
import triaPurple from "./tria-purple.svg"
import triaRose from "./tria-rose.svg"
import eos from "./eos-dcc.svg"
import stackos from "./stackos-dcc.svg"
import storage from "redux-persist/lib/storage"

export const DisplayCost: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading } = useFetchTotalBalance()
  const [gradientBackground, setGradientBackground] = useState<string>("")

  useEffect(() => {
    const theme = localStorage.getItem("tria.wallet.store.app")
    if (theme) {
      const themeObj = JSON.parse(theme)
      const colorFromStorage = themeObj?.primaryColor
      {
        colorFromStorage === "#FFA800"
          ? setGradientBackground(eos)
          : colorFromStorage === "#AAFF00"
          ? setGradientBackground(stackos)
          : colorFromStorage === "#7D40FF"
          ? setGradientBackground(triaPurple)
          : colorFromStorage === "#D7FF01"
          ? setGradientBackground(triagreen)
          : colorFromStorage === "#FF249D"
          ? setGradientBackground(triaRose)
          : setGradientBackground(triaOrange)
      }
    }
  }, [])
  const totalBalance = useSelector(
    (state: RootState) => state.User.totalBalance
  )
  const percentageChange = useSelector(
    (state: RootState) => state.User.percentChange
  )
  const isTextVisible = useSelector(
    (store: RootState) => store?.balance?.isBalanceVisible
  )

  return (
    <div
      className={`w-full self-stretch h-[24vh] rounded-[2vh] flex-col justify-center items-center gap-[2vh] flex text-white  overflow-hidden relative bg-black`}
    >
      <div
        className={`w-[100%] self-stretch h-[24vh] flex-col justify-center items-center gap-[2vh] flex text-white  overflow-hidden absolute z-30`}
        style={{
          backgroundImage: `url(${gradientBackground})`,
          opacity: 1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "200% 200%", // or '100% 100%' if you want to stretch the image
          backgroundPosition: "-18vh 2vh", // shorthand for X and Y
        }}
      />

      <div
        className={`w-full display-cost-card self-stretch h-[24vh] rounded-[2vh] flex-col justify-center items-center gap-[2vh] flex text-white  absolute overflow-hidden z-50`}
      />
      <div className='self-stretch h-full px-[2.4vh] py-[2vh] rounded-[2vh] flex-col z-[100] justify-center items-start gap-[1vh] flex '>
        <div className='flex items-center pt-[1.4vh] pb-0'>
          <div className='mr-[1vh] text-[2vh] font-[600] '>Assets</div>
          <div className='w-[6vh] h-[2vh] px-[1.3vh] py-[0.3vh] bg-white bg-opacity-20 rounded-[3.4vh] justify-center items-center gap-[0.7vh] inline-flex font-[600]'>
            <div className='text-center text-white text-[1.4vh] font-semibold'>
              {percentageChange.toFixed(2)}%
            </div>
          </div>
          <div className='  justify-end  flex ml-[21vh] '>
            <SendBuyButtons
              onClick={() => navigate("/home/buycrypto")}
              content='Buy'
            />
          </div>
        </div>
        <div className='self-stretch flex-col justify-start items-start gap-[1.4vh] flex'>
          <div className='self-stretch h-[5.2vh] flex-col justify-center items-start gap-[0.7vh] flex'>
            <div className='self-stretch justify-between items-center inline-flex'>
              {isTextVisible ? (
                <div className='justify-center items-center gap-[1.4vh] flex group'>
                  <div className='text-center text-[4.3vh] leading-normal font-semibold text-white group-hover:invisible group-hover:w-0 '>
                    {formatCurrency(totalBalance, false)}
                  </div>
                  <div className='invisible w-0 group-hover:visible group-hover:w-full ml-[-1.5vh] opacity-0 group-hover:opacity-100'>
                    <HideBalance />
                  </div>
                </div>
              ) : (
                <div className=' ml-[-0.1vh]'>
                  <HideBalance />
                </div>
              )}
              {/* <div
                className='w-[3vh] h-[3vh] relative cursor-pointer'
                onClick={() => {
                  setIsTextVisible(!isTextVisible)
                }}
              >
                <div className='w-[12.6px] h-[12.6] flex items-center left-0 top-0 absolute'>
                  <EyeSlash />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className='self-stretch pt-[2vh] justify-center items-center gap-[2.7vh] inline-flex'>
          {/* <SendBuyButtons
            onClick={() => navigate('/home/buycrypto')}
            content='Buy'
          /> */}
          <SendBuyButtons
            onClick={() => navigate("/home/sendcrypto")}
            content='Send'
          />
          <SendBuyButtons
            onClick={() => {}}
            disabled={true}
            // onClick={() => navigate("/swap")}
            content='Swap'
          />
        </div>
      </div>
    </div>
  )
}
