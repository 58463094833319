import { useEffect } from 'react'
import { PopularTokenItem, TokenData } from '../../../../../rx.core'

interface Props {
  token?: PopularTokenItem
  searchToken?: TokenData
  onClick?: () => void
}

export const SearchedTokenContainer: React.FC<Props> = ({
  token,
  searchToken,
  onClick: handleClick,
}) => {
  return (
    <div
      className='w-full h-[8.57vh] px-[2.4vh] py-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[2.4vh] flex-col justify-center items-center gap-[2vh] inline-flex cursor-pointer'
      onClick={handleClick}
    >
      {token && (
        <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
          <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1.4vh] flex'>
            <div className='w-[4.76vh] h-[4.76vh] relative rounded-[1.67vh] md:rounded-[40px] '>
              <div className='w-[4.76vh] h-[4.76vh] left-0 top-0 absolute bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[4.76vh] backdrop-blur-[3.5px] flex-col justify-center items-center inline-flex'>
                <img
                  className='w-[5.71vh] h-[5.71vh]'
                  src={token?.tokenLogo}
                  alt={`Logo of: ${token?.tokenName}`}
                />
              </div>
              <div className='w-[2.6vh] h-[2.6vh] left-[2.8vh] top-[2.8vh] absolute flex-col justify-center items-center inline-flex'>
                {token?.chainLogo && (
                  <img
                    className='w-[2.4vh] h-[2.4vh]'
                    src={token?.chainLogo}
                    alt={`Logo`}
                  />
                )}
                {!token?.chainLogo && (
                  <div className='w-[2.4vh] h-[2.4vh] border-[0.01vh] border-white rounded-[0.5vh] bg-displayCardBgColor '>
                    {token?.chainLogo}
                  </div>
                )}
              </div>
            </div>
            <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold font-'Montserrat' leading-tight">
                  {token?.tokenName}
                </div>
              </div>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-[1.67vh] font-semibold font-'Montserrat' leading-[normal]">
                  {token?.coinSymbol}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {searchToken && (
        <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
          <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1.4vh] flex'>
            <div className='w-[4.76vh] h-[4.76vh] relative rounded-[4.76vh] shadow-2'>
              <div className='w-[4.76vh] h-[4.76vh] left-0 top-0 absolute bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor rounded-[4.76vh] backdrop-blur-[3.5px] flex-col justify-center items-center inline-flex'>
                <img
                  className='w-[5.71vh] h-[5.71vh]'
                  src={searchToken?.logo}
                  alt={`Logo of: ${searchToken?.coin_name}`}
                />
              </div>
            </div>
            <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2vh] font-semibold leading-tight'>
                  {searchToken?.coin_name}
                </div>
              </div>
              <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-[1.67vh] font-semibold font-'Montserrat' leading-normal">
                  {searchToken?.coin_symbol}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
