export const copyToClipboard = (text: string | undefined) => {
  let copied = false
  if (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        copied = true
      })
      .catch((error) => {
        console.error('Failed to copy: ', error)
      })
  }
  return { copied: true, text }
}
