import './index.css'

export const HomeAssets: React.FC = () => {
  return (
    <div className='w-[50vh] h-[8.57vh] px-[2.4vh] py-[1.4vh] flex-col justify-center items-center gap-[1vh] inline-flex'>
      <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
        <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1vh] flex'>
          <div className='w-[4.76vh] h-[4.76vh] relative'>
            <div className='w-[4.76vh] dark:w-0 custom-pulse-light dark:h-0 h-[4.76vh] left-0 top-0 absolute bg-neutral-700 rounded-[3.33vh]' />
            <div className='w-0 dark:w-[4.76vh] custom-pulse-dark h-0 dark:h-[4.76vh] left-0 top-0 absolute bg-neutral-700 rounded-[3.33vh]' />
          </div>
          <div className='flex-col justify-start items-start gap-[1vh] inline-flex'>
            <div className='w-[15.2vh] h-[1.67vh] relative'>
              <div className='w-[15.2vh] dark:w-0 custom-pulse-light dark:h-0 h-[1.67vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
              <div className='dark:w-[15.2vh] w-0 custom-pulse-dark h-0 dark:h-[1.67vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
            </div>
            <div className='w-[6.8vh] h-[1.2vh] relative'>
              <div className='w-[6.8vh] dark:w-0 dark:h-0 custom-pulse-light  h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
              <div className='w-0 h-0 dark:w-[6.8vh] custom-pulse-dark dark:h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
            </div>
          </div>
        </div>
        <div className='flex-col justify-center items-end gap-[1vh] inline-flex'>
          <div className='w-[15.2vh] h-[1.2vh] relative'>
            <div className='dark:w-0 dark:h-0 w-[15.2vh] custom-pulse-light  h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
            <div className='w-0 h-0 dark:w-[15.2vh] custom-pulse-dark dark:h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh]' />
          </div>
          <div className='w-[6.8vh] h-[1.2vh] justify-center items-center inline-flex'>
            <div className='w-[6.8vh] custom-pulse-light dark:custom-pulse h-[1.2vh] bg-neutral-700 rounded-[2vh]' />
            <div className='h-0 w-0 dark:w-[6.8vh] custom-pulse-dark dark:h-[1.2vh] bg-neutral-700 rounded-[2vh]' />
          </div>
        </div>
      </div>
    </div>
  )
}
