import './index.css'

export const NftAsset: React.FC = () => {
  return (
    <div className='w-[21.7vh] h-[29vh] p-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[1.2vh] border-[0.2vh] border-outlining dark:border-outliningDark border-opacity-10 flex-col justify-center items-center gap-[1vh] inline-flex'>
      <div className='w-[19.9vh] h-[22.3vh] relative'>
        <div className='w-[19.9vh] dark:w-0 custom-pulse-light h-[22.3vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[0.5vh]' />
        <div className='w-0 dark:w-[19.9vh] custom-pulse-dark h-[22.3vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[0.5vh]' />
      </div>
      <div className='self-stretch h-[4vh] p-[1.4vh] flex-col justify-center items-start gap-[1vh] flex '>
        <div className='self-stretch grow shrink basis-0 justify-start items-center gap-[2vh] inline-flex '>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-start gap-[1vh] inline-flex '>
            <div className='w-[17vh] h-[1vh] relative '>
              <div className='w-[17vh] dark:w-0  custom-pulse-light h-[1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh]' />
              <div className='dark:w-[17vh] w-0  custom-pulse-dark h-[1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh]' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
