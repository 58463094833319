import React from 'react'
import { HomeAssets } from '../Loaders/HomeAssets'

export default function HomeCrypto() {
  return (
    <div>
        <div className="flex grid columns-1  ">
        <HomeAssets/>
        <HomeAssets/>
        <HomeAssets/>
        </div>
    </div>
  )
}
