import React from 'react'
import TransactionListHistory from './TransactionListHistory'
import TransactionListUsers from './TransactionListUsers'
import TransactionListRecent from './TransactionListRecent'

interface TransactionTabsProps {
  handleTabClick: (tab: string) => void
  activeTab: string
}

const TransactionTabs: React.FC<TransactionTabsProps> = ({
  handleTabClick,
  activeTab,
}) => {
  return (
    <div>
      <div className='w-full h-[4vh] relative px-[2.4vh] py-[1.2vh] justify-start items-start gap-[2.4vh] inline-flex'>
        <div
          className={`justify-start items-start gap-[2vh] flex cursor-pointer ${
            activeTab === 'history'
              ? 'text-fontPrimaryColor dark:text-fontPrimaryDarkColor active'
              : 'text-fontLightColor dark:text-fontLightColorDark'
          }`}
          onClick={() => handleTabClick('history')}
        >
          <div
            className={`text-start text-opacity-80 text-[1.4vh] font-bold  uppercase leading-tight tracking-tight cursor-pointer ${
              activeTab === 'history'
                ? 'text-fontPrimaryColor dark:text-fontPrimaryDarkColor active'
                : 'text-fontLightColor dark:text-fontLightColorDark'
            }`}
          >
            history
          </div>
        </div>
        <div
          className={`justify-start items-center cursor-pointer gap-[2vh] flex ${
            activeTab === 'users'
              ? 'text-fontPrimaryColor dark:text-fontPrimaryDarkColor active'
              : 'text-fontLightColor dark:text-fontLightColorDark'
          }`}
          onClick={() => handleTabClick('users')}
        >
          <div
            className={`text-start text-opacity-20 text-[1.4vh] font-bold  uppercase leading-tight tracking-tight ${
              activeTab === 'users'
                ? 'text-fontPrimaryColor dark:text-fontPrimaryDarkColor active'
                : 'text-fontLightColor dark:text-fontLightColorDark'
            }`}
          >
            users
          </div>
        </div>
        <div
          className={`justify-start items-center gap-[2vh] flex cursor-pointer ${
            activeTab === 'recent' ? 'active' : ''
          }`}
          onClick={() => handleTabClick('recent')}
        ></div>
      </div>
      <div></div>
    </div>
  )
}

export default TransactionTabs
