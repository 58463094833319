import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Names, RootState, updateCurrentRecipient } from '../../../../rx.core'
import { useFetchRecentUsers } from '../../../../../hooks/useFetchRecentUsers'
import { useTriaUser } from '../../contexts'
import { useNavigate } from 'react-router-dom'
import { UserContainer } from '../../components'
import { SearchLayout } from '../Search'

// type TransactionData = {
//     Image: string;
//     User: string;
//   };

//   const jsonData: TransactionData[] = [
//     {
//       Image: "/icons/user2.svg",
//       User: "gusOsus",
//     },
//     {
//         Image: "/icons/user2.svg",
//         User: "gusOsus",
//       },
//       {
//         Image: "/icons/user2.svg",
//         User: "gusOsus",
//       },
//       {
//         Image: "/icons/user2.svg",
//         User: "gusOsus",
//       },
//       {
//         Image: "/icons/user2.svg",
//         User: "gusOsus",
//       },
//       {
//         Image: "/icons/user2.svg",
//         User: "gusOsus",
//       },

// ]

const TransactionListUsers = () => {
  const [userResultsFromChild, setUserResultsFromChild] = useState<Names[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const HandleClick = () => {
    navigate('/home')
  }
  const loading = useFetchRecentUsers()

  const handleClick = (index: number) => {
    const selected = recentUsers[index]
    dispatch(updateCurrentRecipient(selected?.triaName))
    navigate(`/home/sendCrypto/conversations`)
  }
  const [showRecentUsers, setShowRecentUsers] = useState<boolean>(true)
  const handleUserResultsUpdate = (results: string) => {
    if (results.length > 0) {
      setShowRecentUsers(false)
    } else {
      setShowRecentUsers(true)
    }
  }

  const recentUsers = useSelector(
    (store: RootState) => store.User.userRecentUsers
  )

  return (
    <>
      {showRecentUsers && (
        <div>
          {/* <div className='w-[100%] h-[6vh] px-[2.4vh] py-[1vh] justify-start items-center gap-[2vh] inline-flex'>
            <div className='justify-start items-center gap-[2vh] flex'>
              <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-60 text-[1.67vh] font-bold font-'Montserrat' uppercase leading-tight tracking-tight">
                recent USERS
              </div>
            </div>
          </div> */}
          {recentUsers.length > 0 ? (
            <div className='grid grid-cols-2 gap-[1vh] px-[1vh]'>
              {recentUsers.slice(0, 6).map((user, index) => (
                <div>
                  <UserContainer
                    onClick={() => handleClick(index)}
                    recentUser={user}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className='text-center w-[50vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-60 text-[2vh] font-bold flex justify-center items-center uppercase leading-tight tracking-tight mt-[9.5vh]'>
              {' '}
              No Recent Users
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default TransactionListUsers
