import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTriaUser } from '../package/ui.common'
import { HistoryFilterOption } from '../package/rx.core'
import { updateUserHistory } from '../package/rx.core/src/redux/features' // Import the action creator
import {
  ReloadProvider,
  useReload,
} from '../package/ui.common/src/contexts/reload/ReloadContext'

export const useFetchHistory = () => {
  const { reload } = useReload()

  const [loading, setLoading] = useState<boolean>(false)
  const { getAllHistory } = useTriaUser()
  const dispatch = useDispatch()
  const [triaName, setTriaName] = useState<string>()
  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const fetchHistory = async (
    txFilter: HistoryFilterOption,
    selectedNetworks: string[]
  ) => {
    if (triaName) {
      try {
        setLoading(true)
        const items = await getAllHistory(txFilter, selectedNetworks, triaName)
        dispatch(updateUserHistory(items)) // Dispatch action to update userHistory in Redux store
      } catch (error: any) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchHistory('all', [])
  }, [triaName])
  useEffect(() => {
    if (reload) fetchHistory('all', [])
  }, [reload])
  return { loading }
}
