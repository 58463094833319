interface Props {
  onClick?: () => void
}
export const BackButton: React.FC<Props> = ({ onClick: handleBack }) => {
  return (
    <div
      className='w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer '
      onClick={handleBack}
    >
      <div className='w-[2.1vh] h-[2.1vh] flex'>
        <button type='button'>
          <img
            src='/icons/arrow-left.svg'
            className='w-[2.1vh] visible dark:invisible dark:w-0'
          ></img>
          <img
            src='/icons/arrow-left-dark.svg'
            className='w-0 invisible dark:visible dark:w-[2.1vh]'
          ></img>
        </button>
      </div>
    </div>
  )
}
