import { BackButton } from '../Buttons/BackButton'
import './index.css'

export const NftDetailsLoader: React.FC = () => {
  return (
    <div className='w-[53vh] h-[100vh]'>
      <div className='w-full h-[8.57vh] py-[1.4vh] rounded-[2.4vh] justify-center items-center gap-[1vh] inline-flex'>
        <BackButton onClick={() => {}} />
        <div className='grow shrink basis-0 h-[4.3vh] justify-center items-center gap-[2vh] flex'>
          <div className='px-[1.4vh] py-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[5.7vh] border-[0.2vh] border-fontLightColor dark:border-fontLightColorDark justify-center items-center gap-[1vh] flex'>
            <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight'>
              NFT on
            </div>
            <div className='w-5 h-5 pt-[2.70px] pb-[2.30px]  rounded border border-white backdrop-blur-[2.50px] justify-center items-center flex'>
              <div className='w-[15px] h-[15px] relative flex-col justify-start items-start flex' />
            </div>
          </div>
        </div>
        <div
          className='rounded-[27.58px]  border-stone-300 justify-center items-center gap-[5.66px] flex cursor-pointer'
          onClick={() => {}}
        >
          <img src='/icons/arrow-up.svg'></img>
        </div>
      </div>
      <div className='w-full h-[45.47vh] py-4 rounded-2xl  flex-col justify-center items-center gap-8 inline-flex'>
        <div className='w-[35vh] p-[11.48px] bg-primaryColor dark:bg-primaryDarkColor rounded-[14.34px] border-2 border-zinc-500 border-opacity-10 flex-col justify-center items-center gap-[11.48px] flex'>
          <div className='w-[28.45vh] h-[32vh] flex-col justify-center items-center flex'>
            <div className='dark:w-0 dark:h-0 w-[28.45vh] h-[32vh]  rounded-[0.6vh] custom-pulse-light' />
            <div className='w-0 h-0 dark:w-[28.45vh] dark:h-[32vh]  rounded-[0.6vh] custom-pulse-dark' />
          </div>
          <div className='self-stretch h-[47.34px] p-[17.21px] flex-col justify-center items-start gap-[11.48px] flex'>
            <div className='self-stretch grow shrink basis-0 justify-start items-center gap-[22.95px] inline-flex'>
              <div className='grow shrink basis-0 self-stretch flex-col justify-center items-start gap-[11.48px] inline-flex'>
                <div className='w-[24.4vh] h-[1.53vh] relative'>
                  <div className='dark:w-0 dark:h-0 w-[24.4vh] h-[1.53vh] left-0 top-0 absolute bg-neutral-700 rounded-3xl custom-pulse-light' />
                  <div className='w-0 h-0 dark:w-[24.4vh] dark:h-[1.53vh] left-0 top-0 absolute bg-neutral-700 rounded-3xl custom-pulse-dark' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-[416px] h-[200px] px-5 py-4 rounded-2xl flex-col justify-center items-center gap-2 inline-flex'>
        <div className='self-stretch h-[68px] py-2 flex-col justify-start items-start gap-4 flex'>
          <div className='w-[15.24vh] flex-col justify-center items-start gap-2 flex'>
            <div className='dark:w-0 dark:h-0 w-[6.78vh] h-[1.2vh] relative'>
              <div className='dark:w-0 dark:h-0 w-[6.78vh] h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh] custom-pulse-light' />
              <div className='w-0 h-0 dark:w-[6.78vh] dark:h-[1.2vh] left-0 top-0 absolute bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
            </div>
            <div className='w-[15.24vh] h-[1.67vh] justify-center items-center inline-flex'>
              <div className='w-0 h-0 dark:w-[15.24vh] dark:h-[1.67vh] bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
              <div className='dark:w-0 dark:h-0 w-[15.24vh] h-[1.67vh] bg-neutral-700 rounded-[2vh] custom-pulse-light' />
            </div>
          </div>
        </div>
        <div className='self-stretch h-[92px] py-3 flex-col justify-start items-start gap-4 flex'>
          <div className='self-stretch h-[68px] flex-col justify-center items-start gap-2 flex'>
            <div className='w-[44.76vh] h-[1.2vh] justify-center items-center inline-flex'>
              <div className='w-[44.76vh] h-[1.2vh] dark:w-0 dark:h-0 bg-neutral-700 rounded-[2vh] custom-pulse-light' />
              <div className='w-0 h-0  dark:w-[44.76vh] dark:h-[1.2vh] bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
            </div>
            <div className='w-[44.76vh] h-[1.2vh] justify-center items-center inline-flex'>
              <div className='w-[44.76vh] h-[1.2vh] dark:w-0 dark:h-0 bg-neutral-700 rounded-[2vh] custom-pulse-light' />
              <div className='w-0 h-0  dark:w-[44.76vh] dark:h-[1.2vh] bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
            </div>
            <div className='w-[44.76vh] h-[1.2vh] justify-center items-center inline-flex'>
              <div className='w-[44.76vh] h-[1.2vh] dark:w-0 dark:h-0 bg-neutral-700 rounded-[2vh] custom-pulse-light' />
              <div className='w-0 h-0  dark:w-[44.76vh] dark:h-[1.2vh] bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
            </div>
            <div className='w-[44.76vh] h-[1.2vh] justify-center items-center inline-flex'>
              <div className='w-[44.76vh] h-[1.2vh] dark:w-0 dark:h-0 bg-neutral-700 rounded-[2vh] custom-pulse-light' />
              <div className='w-0 h-0  dark:w-[44.76vh] dark:h-[1.2vh] bg-neutral-700 rounded-[2vh] custom-pulse-dark' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
