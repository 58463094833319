import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTriaUser } from '../package/ui.common'
import {
  updateUserAssets,
  updateUserAssetsLastFetch,
} from '../package/rx.core/src/redux/features'
import { RootState } from '../package/rx.core'
import { useReload } from '../package/ui.common/src/contexts/reload/ReloadContext'

export const useFetchAssets = () => {
  const dispatch = useDispatch()
  const { getAssetsForATriaName } = useTriaUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triaName, setTriaName] = useState<string>()
  const lastFetchTime = useSelector(
    (store: RootState) => store?.User?.userAssetsLastFetch
  )
  const assetsDataExists = useSelector(
    (store: RootState) => store.User?.userAssets
  )
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  )
  const { reload } = useReload()

  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])
  const fetchAssetsForATriaName = async () => {
    const now = new Date()
    if (
      !lastFetchTime ||
      (now.getTime() - new Date(lastFetchTime).getTime() >= 10000 &&
        !userInactive) ||
      reload
    ) {
      try {
        if (!assetsDataExists) {
          setIsLoading(true)
        }
        const assets = await getAssetsForATriaName(triaName || '')
        dispatch(updateUserAssets(assets))
        dispatch(updateUserAssetsLastFetch(new Date().toISOString()))
      } catch (error: any) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }
  useEffect(() => {
    if (triaName) {
      fetchAssetsForATriaName()

      const intervalId = setInterval(fetchAssetsForATriaName, 10000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [triaName, dispatch, assetsDataExists, userInactive])
  useEffect(() => {
    if (reload) fetchAssetsForATriaName()
  }, [reload])

  return isLoading
}
