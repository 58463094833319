interface Props {
  width?: number
  height?: number
}

export const Refresh: React.FC<Props> = ({ width = 12.6, height = 12.6 }) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='vuesax/linear/refresh-2'>
        <g id='refresh-2'>
          <path
            id='Vector'
            d='M16.5 9.5C16.5 13.64 13.14 17 9 17C4.86 17 2.3325 12.83 2.3325 12.83M2.3325 12.83H5.7225M2.3325 12.83V16.58M1.5 9.5C1.5 5.36 4.83 2 9 2C14.0025 2 16.5 6.17 16.5 6.17M16.5 6.17V2.42M16.5 6.17H13.17'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  )
}
