import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NftTxnState, SendNftState } from './nft.model'

const initialState: SendNftState = {
  recipientTriaName: '',
  nftDetails: {
    chainName: '',
    nftImage: '',
    nftName: '',
    type: '',
    tokenAddress: '',
    tokenId: '',
    amount: '',
  },
}

export const sendNftSlice = createSlice({
  name: 'SendNftState',
  initialState,
  reducers: {
    updateNftRecipientAddress: (state, action: PayloadAction<string>) => {
      state.recipientTriaName = action.payload
    },
    updateNftDetails: (state, action: PayloadAction<NftTxnState>) => {
      state.nftDetails = action.payload
    },
  },
})

export const { updateNftRecipientAddress, updateNftDetails } =
  sendNftSlice.actions
