import { TriaStorageService } from './storage.model'

export class StorageService {
  private static _instance: StorageService

  private constructor(private readonly internalService: TriaStorageService) {}

  public static initalize(service: TriaStorageService) {
    if (this._instance) {
      throw new Error('StorageService already initalized')
    }
    this._instance = new StorageService(service)
  }

  private static get getInstance() {
    if (!this._instance || !this._instance.internalService) {
      throw new Error('StorageService is not initalized')
    }

    return this._instance.internalService
  }

  public static set<T>(key: string, value: T) {
    if (typeof value === 'string') {
      this.getInstance.store(key, value)
      return
    }
    this.getInstance.store(key, JSON.stringify(value))
  }

  public static tryGet(key: string): string | undefined {
    return this.getInstance.read(key)
  }
}
