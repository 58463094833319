import { useNavigate } from 'react-router-dom'
import { ConversationNav, Opentria } from '../../package/ui.common'
import { NftDetailImageCard } from '../../package/ui.common/src/components/Cards'
import { PrimaryCustomButton } from '../../package/ui.common/src/components/Buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  updateCurrentRoute,
  updatePreviousRoute,
  useToast,
} from '../../package/rx.core'
import { useEffect, useState } from 'react'

interface Props {}

const AUTH_URL = process.env.REACT_APP_AUTH_URL

export const SendNft: React.FC<Props> = () => {
  const [nftAmount, setNftAmount] = useState<number>(1)
  const [sendParams, setSendParams] = useState<string>()
  const [triaName, setTriaName] = useState<string>()
  const [error, setError] = useState<string>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openToastMessage, closeToastMessage, setToastData } = useToast()
  const prevPath = useSelector((store: RootState) => store.Route?.previousRoute)

  dispatch(updateCurrentRoute(window.location.pathname))

  const nftDetail = useSelector(
    (store: RootState) => store?.SendNftState?.nftDetails
  )

  const recipient = useSelector(
    (store: RootState) =>
      store.SendToken.tokenState?.recipientTriaName ||
      store.SendToken.tokenState?.recipientAddress
  )

  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData)
      setTriaName(parsedData?.triaName)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const HandleClick = () => {
    if (prevPath === '/nfts/oneNft') {
      dispatch(updatePreviousRoute('/'))
      navigate('/nfts/oneNft')
    } else {
      navigate('/home/sendCrypto/conversations')
    }
  }

  useEffect(() => {
    setError('')
    if (
      triaName &&
      nftDetail?.chainName &&
      recipient &&
      nftDetail &&
      nftAmount <= parseFloat(nftDetail?.amount || '0') &&
      nftAmount !== 0
    ) {
      const params: any = {
        chainName: nftDetail?.chainName,
        fromTriaName: triaName,
        recipientTriaName: recipient,
        nftDetails: {
          type: nftDetail?.type,
          tokenAddress: nftDetail?.tokenAddress,
          tokenId: nftDetail?.tokenId,
          amount: nftAmount,
        },
        fromWallet: true,
      }

      const encodedParams = btoa(JSON.stringify(params))
      setSendParams(encodedParams)
    } else if (nftAmount > parseFloat(nftDetail?.amount || '0')) {
      setError('Not Enough Nfts!')
    } else if (nftAmount === 0) {
      setError('Please Select Atleast One Nft!')
    }
  }, [nftAmount, triaName, recipient, nftDetail])

  const handleSendClick = () => {
    if (sendParams && !error) {
      window.open(`${AUTH_URL}/sendNft/${sendParams}`, '_self')
      dispatch(updatePreviousRoute('/'))
    }
  }

  const nftAmountDeduct = () => {
    if (nftAmount >= 1) {
      setNftAmount(nftAmount - 1)
    }
  }

  const nftAmountIncrease = () => {
    if (nftAmount < parseFloat(nftDetail?.amount || '0')) {
      setNftAmount(nftAmount + 1)
    } else {
      setToastData({ status: 'error', title: 'Not enought NFTs!' })
      openToastMessage()
      setTimeout(closeToastMessage, 4000)
    }
  }
  useEffect(() => {
    if (error) {
      setToastData({ status: 'error', title: error })
      openToastMessage()
      setTimeout(closeToastMessage, 4000)
    }
  }, [error])

  return (
    <div className='w-[53vh] h-[100vh] p-[2vh] flex-col justify-between items-center inline-flex rounded-[1.4vh] relative z-50'>
      <div className=' pb-[15vh]'>
        <ConversationNav onClick={HandleClick} />
        <NftDetailImageCard
          nftName={nftDetail?.nftName}
          nftImage={nftDetail?.nftImage}
          amount={parseFloat(nftDetail?.amount || '')}
        />
        <div className='w-full  h-[8.57vh] px-[2.8vh] py-[1.67vh] left-0 top-[27.4vh] bg-[#F9F9F9] dark:bg-hoverDarkColor rounded-[8.57vh] flex-col justify-center items-center gap-[1vh] inline-flex'>
          <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
            <div className='grow shrink basis-0 h-[4.76vh] justify-start items-center gap-[1.4vh] flex'>
              <div className='w-[4.76vh] h-[4.76vh] relative rounded-[1.4vh] shadow'>
                <img
                  src={nftDetail?.chainLogo}
                  alt='logo'
                  className='rounded-[2.2vh]'
                />
              </div>
              <div className='flex self-stretch w-full justify-between items-center '>
                <div className='self-stretch justify-start items-center gap-[0.5vh] flex flex-col'>
                  <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor  text-[2vh] font-semibold  leading-tight'>
                    QUANTITY
                  </div>
                  <div className='text-center text-fontLightColor dark:text-fontLightColorDark  text-[2vh] font-semibold  leading-tight'>
                    {nftDetail?.chainName}
                  </div>
                </div>
                <div className='flex gap-2'>
                  <div
                    className='flex justify-center items-center w-[4.2vh] h-[4.2vh] rounded-[2.1vh] bg-[#D9D9D9] font-[500] text-[2.8vh] text-[#101010]'
                    onClick={nftAmountDeduct}
                  >
                    -
                  </div>
                  <div className='w-[6.2vh] h-[4.3vh] flex items-center justify-center bg-[#404040] rounded-[2.1vh] text-[2.4vh] text-fontPrimaryDarkColor font-[600] opacity-80'>
                    {nftAmount}
                  </div>
                  <div
                    className='flex justify-center items-center w-[4.3vh] h-[4.3vh] rounded-[2.1vh] bg-[#D9D9D9] font-[500] text-[2.8vh] text-[#101010]'
                    onClick={nftAmountIncrease}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FeeContainer />
          <div className="self-stretch pb-3 justify-start items-start gap-4 inline-flex">
          <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2 flex">
            <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-lg font-semibold  leading-snug">Total Cost</div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-center items-end gap-1 inline-flex">
            <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor  text-lg font-semibold leading-snug">
             {/* {(amountInUsd + (parseFloat(fees?.fee?.usd || ".0000001"))).toFixed(5) } 
             </div>
             {inputInUsd &&<div className="text-center text-fontLightColor dark:text-fontLightColorDark text-sm font-semibold leading-[16.80px]">{((amountInToken) + (parseFloat(fees?.fee?.eth || ".0000001"))).toFixed(5) } {transactionPayload?.tokenName}</div>} 

            {/* {!inputInUsd && <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-sm font-semibold leading-[16.80px]">{(parseFloat(enteredAmountInTokenValue) + (parseFloat(fees?.fee?.eth || ".0000001"))).toFixed(5) } {transactionPayload?.tokenName}</div>} 
          </div>
        </div> */}
      <div className='self-stretch flex-col justify-center items-center gap-[1vh] flex'>
        <div className='self-stretch h-[6.43vh] flex-col justify-center items-center gap-[2vh] flex '>
          <div className='self-stretch justify-center items-center gap-[2.8vh] inline-flex'>
            <div className='w-[24vh] cursor-pointer' onClick={handleSendClick}>
              {!error && <PrimaryCustomButton content='send' />}
              {error && <PrimaryCustomButton content='send' disable={true} />}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-[2vh]'>
        <Opentria />
      </div>
    </div>
  )
}
