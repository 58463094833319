import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NetworkSelectState } from './network-select.model'

const INITIAL_STATE: NetworkSelectState = {
  filterChainNames: [],
  filter: 'all',
  sort: 'amountDesc',
}

export const networkSelectSlice = createSlice({
  name: 'networkSelect',
  initialState: INITIAL_STATE,
  reducers: {
    updateFilters: (
      state,
      payload: PayloadAction<Partial<NetworkSelectState>>
    ) => {
      const { filterChainNames, filter, sort } = payload.payload
      if (filterChainNames) {
        state.filterChainNames = filterChainNames
      }
      if (filter) {
        state.filter = filter
      }
      if (sort) {
        state.sort = sort
      }
    },
    // The extension build process detects "state" as unused variable eventhough it is used

    resetFilters: (state) => {
      state = INITIAL_STATE
    },
  },
})

export const { updateFilters, resetFilters } = networkSelectSlice.actions
