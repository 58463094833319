import React, { useState } from 'react'
import {
  Nav,
  Footer,
  UserContainer,
  SearchLayout,
} from '../../package/ui.common'
import { useNavigate } from 'react-router-dom'
import { RootState, updateCurrentRoute } from '../../package/rx.core'
import { useSelector } from 'react-redux'
import { useFetchRecentUsers } from '../../hooks/useFetchRecentUsers'
import { useDispatch } from 'react-redux'
import { BackButton } from '../../package/ui.common/src/components/Buttons/BackButton'
export const SendCrypto: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useFetchRecentUsers()

  dispatch(updateCurrentRoute(window.location.pathname?.toString()))

  const HandleClick = () => {
    navigate('/home')
  }

  const [showRecentUsers, setShowRecentUsers] = useState<boolean>(true)
  const handleUserResultsUpdate = (results: string) => {
    if (results?.length > 0) {
      setShowRecentUsers(false)
    } else {
      setShowRecentUsers(true)
    }
  }

  const recentUsers = useSelector(
    (store: RootState) => store.User?.userRecentUsers
  )

  return (
    <div>
      <div className='flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor '>
        <div className='w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat'>
          <div className='w-full grow shrink basis-0 flex-col justify-start items-center flex'>
            <Nav />
            <div className='w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex'>
              <div className='self-stretch justify-start items-center gap-[2vh] inline-flex'>
                <div className='h-[2.8vh] justify-start items-center gap-[1vh] flex'>
                  <div className='w-[2vh] h-[2vh] justify-center items-center flex'>
                    <div className='w-[1.67vh] h-[1.67vh] relative'>
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal'>
                    Send
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch pb-[1.4vh] px-[2vh]'>
              <SearchLayout onUpdate={handleUserResultsUpdate} />
            </div>
            {showRecentUsers && (
              <div>
                <div className='w-[50vh] h-[3.6vh] px-[1.4vh] justify-start items-center gap-[2vh] inline-flex'>
                  <div className='justify-start items-center gap-[2vh] flex'>
                    <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh] font-bold uppercase leading-tight tracking-tight'>
                      recent USERS
                    </div>
                  </div>
                </div>
                {recentUsers?.length > 0 ? (
                  <div className='grid grid-cols-2 gap-[1vh]'>
                    {recentUsers?.slice(0, 6)?.map((user, index) => (
                      <div>
                        <UserContainer recentUser={user} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-60 text-[2vh] font-bold font-'Montserrat' uppercase leading-tight tracking-tight mt-[9.5vh]">
                    {' '}
                    No Recent Users
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]'>
        <Footer currentActive='/home' />
      </div>
      <div className='absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0'>
        <Footer currentActive='/home' />
      </div>
    </div>
  )
}
