import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  HistoryItem,
  Names,
  RootState,
  updateCurrentRecipient,
} from '../../../../rx.core'
import TransactionHistoryListItem from './TransactionHistoryListItem'
import { useFetchRecentUsers } from '../../../../../hooks/useFetchRecentUsers'
import { useTriaUser } from '../../contexts'
import { useDispatch, useSelector } from 'react-redux'
import { UserContainer } from '../../components'

type Props = {
  loading: boolean
  items: HistoryItem[]
  showTitle?: boolean
  onClick: (action: string) => void
}
interface GroupedItems {
  [date: string]: HistoryItem[]
}

const TransactionListHistory: React.FC<Props> = ({
  loading,
  items,
  showTitle = true,
  onClick: handleClick,
}) => {
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({})

  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp)
    const today = new Date()
    const yesterday = new Date(today.setDate(today.getDate() - 1))

    if (date.toDateString() === new Date().toDateString()) {
      return 'Today'
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday'
    } else {
      return date.toDateString()
    }
  }

  useEffect(() => {
    const groups: GroupedItems = {}

    items.forEach((item) => {
      const date = formatDay(item?.timestamp) // Convert UNIX timestamp to a date string

      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(item)
    })

    setGroupedItems(groups)
  }, [items])

  return (
    <>
      <div className='w-full flex flex-col py-[1.2vh] justify-start items-center scrollbar-thin scrollbar-thumb-gray-300 h-[45vh] scrollbar-track-stone-100 overflow-scroll '>
        {Object.entries(groupedItems)?.map(([dateLabel, itemsForDay]) => (
          <>
            <div className='w-[100%] h-[2vh] px-[1.4vh] justify-start items-center gap-[2vh] py-[1.4vh] inline-flex'>
              <div className='grow shrink basis-0 h-[1.4vh] justify-start items-center gap-[1vh] flex'>
                <div className='text-center text-fontLightColor dark:text-fontLightColorDark  text-opacity-40 text-[1.2vh] font-bold uppercase  leading-tight tracking-tight'>
                  {dateLabel === 'Today' || dateLabel === 'Yesterday'
                    ? dateLabel
                    : `${dateLabel.split(' ')[0]}, ${dateLabel.split(' ')[2]} ${
                        dateLabel.split(' ')[1]
                      } ${dateLabel.split(' ')[3]}`}
                </div>
              </div>
              <div className='grow shrink basis-0 flex-col justify-center items-end gap-[0.5vh] inline-flex' />
            </div>
            {itemsForDay?.map((item) => (
              <TransactionHistoryListItem
                key={item.txnHash}
                item={item}
                onClick={handleClick}
              />
            ))}
          </>
        ))}
      </div>
    </>
  )
}

export default TransactionListHistory
