import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteState } from "./route.model";

const initialState: RouteState = {
 currentRoute: "/home",
 previousRoute: "",
  };

export const routeSlice = createSlice({
 name:"Route",
 initialState,
 reducers:{
  updateCurrentRoute:( state, action:PayloadAction<string>) =>{
   state.currentRoute = action.payload;
  },
  updatePreviousRoute:( state, action:PayloadAction<string>) =>{
   state.previousRoute = action.payload;
  },
  
 }
})

export const {updateCurrentRoute, updatePreviousRoute} = routeSlice.actions;

