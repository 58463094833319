import './polyfills'
import React from 'react'
import App from './App'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

// import '@rainbow-me/rainbowkit/styles.css';
// import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  goerli,
} from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { TriaProvider } from './package/ui.common'
import { webappLocalizationResources } from './package/rx.i18n'
import { persistor } from './package/rx.core'
import { ReloadProvider } from './package/ui.common/src/contexts/reload/ReloadContext'

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [
//     mainnet,
//     polygon,
//     optimism,
//     arbitrum,
//     base,
//     zora,
//     ...(process.env.REACT_APP_ENABLE_TESTNETS === "true" ? [goerli] : []),
//   ],
//   [publicProvider()]
// );

// const { connectors } = getDefaultWallets({
//   appName: 'RainbowKit demo',
//   projectId: 'YOUR_PROJECT_ID',
//   chains,
// });

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   webSocketPublicClient,
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  // dev-lit
  <BrowserRouter>
    <TriaProvider appLocalizationResource={webappLocalizationResources}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <WagmiConfig config={wagmiConfig}> */}
        {/* <RainbowKitProvider chains={chains}> */}
        <ReloadProvider>
          <App />
        </ReloadProvider>
        {/* </RainbowKitProvider> */}
        {/* </WagmiConfig> */}
      </PersistGate>
    </TriaProvider>
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
