import { KeyringControllerInterface } from '@tria-sdk/core';

import { KEYRING_SYMBOL } from "./keyring.const";
import { KeyringContainer } from "./keyring.model";

export class StaticGlobal implements KeyringContainer {

    private static _instance = new StaticGlobal();

    public static getInstance(): KeyringContainer {
        return this._instance;
    }

    [KEYRING_SYMBOL]: KeyringControllerInterface | undefined;
}
