import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CacheState } from './caches.model'
import {
  Conversation,
  GetAssetDetailsResponse,
  HistoryItem,
  NftsItemDetails,
} from '../../../types'
import {
  assetDetail_initialstate,
  nftDetail_initialstate,
} from './caches.initialstate'

const INITIAL_STATE: CacheState = {
  assetDetail: assetDetail_initialstate,
  assetHistory: [],
  nftDetail: nftDetail_initialstate,
  onChainConversation: [],
}

export const cacheSlice = createSlice({
  name: 'Caches',
  initialState: INITIAL_STATE,
  reducers: {
    updateAssetDetailCache: (
      state,
      action: PayloadAction<GetAssetDetailsResponse['data']>
    ) => {
      state.assetDetail = action.payload
    },
    updateAssetHistoryCache: (state, action: PayloadAction<HistoryItem[]>) => {
      state.assetHistory = action.payload
    },
    updateNftDetailCache: (state, action: PayloadAction<NftsItemDetails>) => {
      state.nftDetail = action.payload
    },
    updateConversationCache: (state, action: PayloadAction<Conversation[]>) => {
      state.onChainConversation = action.payload
    },
  },
})

export const {
  updateAssetDetailCache,
  updateAssetHistoryCache,
  updateConversationCache,
  updateNftDetailCache,
} = cacheSlice.actions
