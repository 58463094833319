import React from 'react'
import './index.css'
import { ChatCardLoader } from '../Loaders/ChatCardLoader'
export default function CryptoSend() {
  return (
    <>
      <div className='grid'>
        <div className='w-[50vh] h-[17.8vh] flex-col justify-center items-end gap-1 inline-flex'>
          <ChatCardLoader />
        </div>
        <div className='w-[50vh] h-[17.8vh] flex-col justify-center items-start gap-1 inline-flex'>
          <ChatCardLoader />
        </div>
        <div className='w-[50vh] h-[17.8vh] flex-col justify-center items-end gap-1 inline-flex'>
          <ChatCardLoader />
        </div>
      </div>
    </>
  )
}
