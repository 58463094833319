import { formatAddress } from '../../../../rx.core'
import './index.css'

interface Props {
  data: any
}
export const PendingItem: React.FC<Props> = ({ data }) => {
  return (
    <div className='w-[50vh] h-auto px-[1.4vh]'>
      {data?.assetType == 'TOKEN' && (
        <div className='flex justify-between w-[50vh]'>
          <div className='flex gap-[1vh]'>
            <div className='relative w-[4.76vh] h-[4.76vh] bg-yellow-100 rounded-[3.57vh]'>
              <div className='pendingloader'></div>
              <img
                src={data?.tokenDetails?.chainLogo}
                alt=''
                className='w-[2.1vh] h-[2.1vh] z-50 absolute top-[2.8vh] left-[2.8vh]'
              />
            </div>
            <div className='flex flex-col '>
              <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor uppercase font-[600] text-[2vh]'>
                {data?.tokenDetails?.name}
              </p>
              <p className='text-[1.2vh] font-[600] '>
                <span className='text-[#FFCC00]'>Pending </span>
                <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                  {' '}
                  To:{' '}
                </span>
                {data?.params?.recipientTriaName?.charAt[0] === '0' && (
                  <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                    {formatAddress(data?.params?.recipientAddress)}{' '}
                  </span>
                )}
                {data?.params?.recipientTriaName?.charAt[0] !== '0' && (
                  <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                    {data?.params?.recipientAddress}{' '}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className='flex flex-col px-[2.4vh] w-auto text-end text-fontLightColor dark:text-fontLightColorDark  font-[600]'>
            <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh]'>
              $
              {(data?.params?.amount * data?.tokenDetails?.quoteRate)?.toFixed(
                4
              )}
            </p>
            <p className='text-[1.67vh]'>
              {data?.params?.amount?.toFixed(4)}{' '}
              {data?.tokenDetails?.symbol?.toUpperCase()}
            </p>
          </div>
        </div>
      )}

      {data?.assetType == 'NFT' && (
        <div className='flex justify-between w-[50vh] ml-[-1.4vh]'>
          <div className='flex gap-[1vh]'>
            <div className='relative w-[4.76vh] h-[4.76vh] bg-yellow-100 rounded-[3.57vh]'>
              <div className='pendingloader'></div>

              <img
                src={data?.tokenDetails?.chainLogo}
                alt=''
                className='w-[2.1vh] h-[2.1vh] z-50 absolute top-[2.8vh] left-[2.8vh]'
              />
            </div>
            <div className='flex flex-col'>
              <p className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor font-[600] text-[2vh]'>
                NFT on {data?.params?.chainName?.toUpperCase()}
              </p>
              <p className='text-[1.2vh] font-[600] '>
                <span className='text-[#FFCC00]'>Pending </span>

                <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                  {' '}
                  To:{' '}
                </span>
                {data?.params?.recipientTriaName?.charAt[0] === '0' && (
                  <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                    {formatAddress(data?.params?.recipientTriaName)}{' '}
                  </span>
                )}
                {data?.params?.recipientTriaName?.charAt[0] !== '0' && (
                  <span className='text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]'>
                    {data?.params?.recipientTriaName}{' '}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className='flex flex-col w-[12vh] text-end text-fontLightColor dark:text-fontLightColorDark font-[600] text-[2vh]'>
            <p>x{data?.params?.nftDetails?.amount}</p>
            {/* <p>{data?.params?.amount} {data?.tokenDetails?.name?.toUpperCase()}</p> */}
          </div>
        </div>
      )}
    </div>
  )
}
