import { useDispatch, useSelector } from 'react-redux'
import {
  AvatarItem,
  RootState,
  store,
  updateUserAvatar,
} from '../../../../../rx.core'
import { useTriaUser } from '../../../contexts'
import { useEffect, useState } from 'react'
import './index.css'
import { useFetchUserAvatar } from '../../../../../../hooks'

interface Props {
  loading?: boolean
  width?: string
  height?: string
  rounded?: string
}

export const UserAvatar: React.FC<Props> = ({
  loading = false,
  width = '4.3vh',
  height = '4.3vh',
  rounded = '2.1vh',
}) => {
  const userAvatar = useSelector((store: RootState) => store.User.avatar)
  const isLoading = useFetchUserAvatar()

  return (
    <div
      className='min-w-[2.1vh] md:min-w-[4.3vh]'
      style={{ width: width, height: height }}
    >
      {userAvatar && !isLoading && !loading && (
        <div
          className=' flex justify-center items-center'
          style={{
            backgroundImage: userAvatar.background,
            width: width,
            height: height,
            borderRadius: rounded,
          }}
        >
          <img
            src={userAvatar.avatar}
            className=''
            style={{ width: width, height: height, borderRadius: rounded }}
          />
        </div>
      )}
      {isLoading && <div className='loading'></div>}
      {loading && <div className='loading'></div>}
      {/* {!avatar && <div className="h-[36px] w-[36px] rounded-[18px] flex justify-center items-center" style={{backgroundImage:triggerAvatar.background}}>
    <img src = {triggerAvatar.avatar} className="w-8 h-8 rounded-[22.50px]"/>
    </div>

    } */}
    </div>
  )
}
