import { CallbackType } from "./sign.model"

export class SignService {
  private static _instance: SignService

  private constructor(private readonly callback: CallbackType) {}

  public static getInstance() {
    if (!this._instance) {
      throw new Error("SignService not initalized")
    }
    return this._instance
  }

  public static initalize(callBack: CallbackType) {
    if (this._instance) {
      throw new Error("SignService already initalized")
    }
    this._instance = new SignService(callBack)
  }

  public async sign(): Promise<Boolean> {
    const res = await this.callback()
    if (res) {
      return true
    }
    return this.signPin()
  }

  private async signPin(): Promise<Boolean> {
    return true
  }
}
