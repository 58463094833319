export interface Props {
  height?: number
  width?: number
}

export const TickIcon: React.FC<Props> = ({ height = 10, width = 14 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${14}`}
      height={`${10}`}
      viewBox='0 0 14 10'
      fill='none'
    >
      <path
        d='M1.33569 4.99874L5.10903 8.77207L12.669 1.2254'
        stroke='#66D554'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
