export const ChatCardLoader: React.FC = () => {
  return (
    <div className='w-[26.2vh] h-[17.8vh] p-[2.4vh] bg-[#EAEAEA] dark:bg-[#202020] rounded-2xl justify-between items-start inline-flex'>
      <div className='h-[13.1vh] flex-col justify-between items-start inline-flex'>
        <div className='w-[4.76vh] h-[4.76vh] rounded-[2.4vh]  justify-center items-center inline-flex '>
          <div className='grow shrink basis-0 self-stretch  rounded-[2.4vh]   backdrop-blur-[5px] flex-col justify-center items-center inline-flex'>
            <div className='w-[4.76vh] h-[4.76vh] relative flex-col justify-start items-start flex'>
              <div className='w-[4.76vh] h-[4.76vh] dark:w-0 dark:h-0   rounded-[2.4vh] custom-users-light ' />
              <div className='w-0 h-0 dark:w-[4.76vh] dark:h-[4.76vh]  rounded-[2.4vh] custom-users-dark' />
            </div>
          </div>
        </div>
        <div className='flex-col justify-start items-start gap-2 flex'>
          <div className='w-[15.24vh] h-[1.67vh] relative'>
            <div className='dark:w-0 dark:h-0 w-[15.24vh] h-[1.67vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-light ' />
            <div className='w-0 h-0 dark:w-[15.24vh] dark:h-[1.67vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-dark ' />
          </div>
          <div className='w-[6.78vh] h-[2.1vh] relative'>
            <div className='dark:w-0 dark:h-0 w-[6.78vh] h-[2.1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-light ' />
            <div className='w-0 h-0 dark:w-[6.78vh] dark:h-[2.1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-dark ' />
          </div>
        </div>
      </div>
      <div className='w-[2.1vh] self-stretch flex-col justify-between items-end inline-flex'>
        <div className='justify-center items-end gap-1 inline-flex '>
          <div className='w-[6.78vh] h-[2.1vh] relative'>
            <div className='dark:w-0 dark:h-0 w-[6.78vh] h-[2.1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-light' />
            <div className='w-0 h-0 dark:w-[6.78vh] dark:h-[2.1vh] left-0 top-0 absolute bg-hoverColor dark:bg-hoverDarkColor rounded-[2.1vh] custom-pulse-dark' />
          </div>
        </div>
        <div className='w-[2.1vh] h-[2.1vh] opacity-20 justify-center items-center inline-flex'>
          <div className='w-[2.1vh] h-[2.1vh] relative'></div>
        </div>
      </div>
    </div>
  )
}
