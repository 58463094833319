import { useNavigate } from 'react-router-dom'
import { BackButton } from '../../package/ui.common/src/components/Buttons/BackButton'
import { useFetchAssetHistory } from '../../hooks/useFetchAssetHistory'
import { useDispatch, useSelector } from 'react-redux'
import {
  store,
  RootState,
  updateCurrentRoute,
  formatNumberDisplay,
  updateTokenInfo,
  updatePreviousRoute,
  HistoryItem,
} from '../../package/rx.core'
import { WalletIcon } from '../../package/ui.common/src/components/Buttons/WalletIcon'
import { SendIcon } from '../../package/ui.common/src/components/Buttons/SendIcon'

import { AssetHistoryContainer } from '../../package/ui.common/src/components/Containers/Asset/AssetHistoryContainer'

import { useEffect, useState } from 'react'
import { useFetchAssetDetails } from '../../hooks/useFetchAssetDetails'
import { AssetHistoryLoader } from '../../package/ui.common/src/components/LoadersPages'
import { Opentria } from '../../package/ui.common'
import { QrButton } from '../../package/ui.common/src/components/Buttons/QrButton'

interface GroupedItems {
  [date: string]: HistoryItem[]
}

export const AssetDetailHistory: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  dispatch(updateCurrentRoute(window.location.pathname))

  const isLoading = useFetchAssetHistory()
  const isLoading2 = useFetchAssetDetails()

  const [currentAddress, setCurrentAddress] = useState<string>('')
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({})

  const HandleClick = () => {
    navigate('/home')
  }
  const assetHistory = useSelector(
    (store: RootState) => store.Caches?.assetHistory
  )
  const valueInUsd = useSelector(
    (store: RootState) => store.Asset?.assetValue?.valueInUsd
  )
  const assetBalance = useSelector(
    (store: RootState) => store.Asset?.assetValue?.assetBalance
  )
  const assetDetail = useSelector(
    (store: RootState) => store.Caches?.assetDetail
  )

  const totalValue = (Array.isArray(assetHistory) ? assetHistory : [])?.reduce(
    (accumulator, asset) => {
      if (asset && asset?.desc && asset?.desc?.value) {
        return accumulator + parseFloat(asset?.desc?.value)
      }
      return accumulator
    },
    0
  )
  useEffect(() => {
    const storedData = localStorage.getItem('tria.wallet.store.app')
    if (storedData !== null) {
      const parsedData = JSON?.parse(storedData)
      setCurrentAddress(parsedData?.userAddress)
    } else {
      console.log("Can't find triaName;")
    }
  }, [])

  const handleBuyClick = () => {
    if (typeof window === 'undefined') {
      return
    }
    if (currentAddress) {
      try {
        const url = `https://global.transak.com/?apiKey=83565d1e-05b6-415a-940c-b02baedd55c8&walletAddress=${currentAddress}&cryptoCurrencyCode=${assetHistory[0]?.desc?.assetSymbol}&network=${assetHistory[0]?.chainName}`
        window.open(url, '_blank')?.focus()
      } catch (error: any) {
        console.error(error)
      }
    }
  }

  const formatDay = (timestamp: number) => {
    const date = new Date(timestamp)
    const today = new Date()
    const yesterday = new Date(today?.setDate(today?.getDate() - 1))

    if (date?.toDateString() === new Date()?.toDateString()) {
      return 'Today'
    } else if (date?.toDateString() === yesterday?.toDateString()) {
      return 'Yesterday'
    } else {
      return date?.toDateString()
    }
  }

  useEffect(() => {
    const groups: GroupedItems = {}

    Array.isArray(assetHistory) &&
      assetHistory?.forEach((item) => {
        const date = formatDay(item?.timestamp) // Convert UNIX timestamp to a date string

        if (!groups[date]) {
          groups[date] = []
        }
        groups[date]?.push(item)
      })

    setGroupedItems(groups)
  }, [assetHistory])

  const handleSendClick = () => {
    dispatch(
      updateTokenInfo({
        qouteRate: assetDetail?.quoteRate,
        senderBalance: assetBalance,
        tokenAddress: assetDetail?.tokenAddress,
        tokenLogo: assetDetail?.logoUrl,
        chainLogo: assetDetail?.chainLogo,
        chainName: assetDetail?.chainName,
        tokenName: assetDetail?.symbol,
      })
    )
    dispatch(updatePreviousRoute('/assetHistory'))
    navigate('/home/sendCrypto')
  }

  return (
    <>
      {isLoading2 && <AssetHistoryLoader />}
      {!isLoading2 && (
        <div className='w-[53vh] overflow-hidden h-[100vh] p-[2vh] flex-col justify-center bg-primaryColor dark:bg-primaryDarkColor items-center gap-[1.4vh] inline-flex rounded-[1.4vh] font-montserrat'>
          <div className='w-full h-[100%] flex-col justify-start items-center flex'>
            <div className='self-stretch h-[8.57vh] px-[2.4vh] py-[1.4vh] rounded-[2.5vh] justify-between items-center inline-flex'>
              <div className='w-[2.4vh] h-[2.4vh] relative'>
                <div className='w-[2.4vh] h-[2.4vh] left-0 top-0 absolute'>
                  <BackButton onClick={HandleClick} />
                </div>
              </div>
              <div className='px-[1.4vh] py-[1vh] rounded-[5.7vh] border-[0.2vh] border-outlining dark:border-outliningDark border-opacity-10 justify-center items-center gap-[1vh] flex'>
                <div className='text-center text-fontLightColor text-fontLightColorDark text-[1.67vh] font-semibold leading-tight'>
                  {assetDetail?.symbol} on
                </div>
                <div className='w-[2.4vh] h-[2.4vh] relative  backdrop-blur-[2.6vh] rounded-[0.5vh] overflow-hidden'>
                  <img src={assetDetail?.chainLogo} />
                </div>
              </div>
              <div>
                <QrButton />
              </div>
              {/* <div className='w-[2.1vh] h-[2.1vh] relative'>
                <div className='w-[2.1vh] h-[2.1vh] left-0 top-0 absolute'></div>
              </div> */}
            </div>
            <div className='self-stretch h-[72vh] py-[1vh] flex-col justify-start items-center flex'>
              <div className='self-stretch h-[37vh] px-[2.4vh] py-[1.4vh] rounded-[1.07vh] flex-col justify-center items-start gap-[2.85vh] flex'>
                <div className='self-stretch h-[17.6vh] flex-col justify-start items-center gap-[1.4vh] flex'>
                  <div className='rounded-[4.76vh] shadow flex-col justify-center items-center gap-2 flex'>
                    <div className='bg-primaryColor dark:bg-primaryDarkColor rounded-[7.1vh] backdrop-blur-[7.6vh] justify-start items-start gap-[1.4vh] inline-flex'>
                      <div className='w-[7.1vh] h-[7.1vh] rounded-[3vh] overflow-hidden relative'>
                        <img
                          className={`w-[7.1vh] h-[7.1vh] left-0 top-0 absolute overflow-hidden  ${
                            assetDetail?.symbol?.toUpperCase() === 'FUSE'
                              ? 'scale-[120%]'
                              : ''
                          }`}
                          src={assetDetail?.logoUrl || ''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='self-stretch h-[76px] flex-col justify-center items-center gap-[0.5vh] flex'>
                    <div className='self-stretch justify-center items-center gap-[1vh] inline-flex'>
                      <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-80 text-[2.4vh] font-semibold leading-tight'>
                        ${valueInUsd?.toFixed(3)}
                      </div>
                    </div>
                    <div className='self-stretch justify-center items-center gap-[1vh] inline-flex'>
                      <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[4.76vh] font-semibold leading-tight'>
                        {typeof assetBalance === 'string'
                          ? parseFloat(assetBalance)?.toFixed(5)
                          : assetBalance?.toFixed(5)}{' '}
                        {assetDetail?.symbol}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='self-stretch py-[1.4vh] justify-center items-center gap-[16vh] inline-flex z-50'>
                  <div className='flex-col justify-center items-center gap-[1vh] flex cursor-pointer'>
                    <div
                      className='bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[4.76vh] w-[7.1vh] h-[7.1vh] dark:border-outliningDark flex-col justify-center items-center gap-[1.3vh] flex '
                      onClick={handleBuyClick}
                    >
                      <div className='w-[6vh] h-[6vh] flex items-center justify-center'>
                        <WalletIcon
                          color='white'
                          width='3.33vh'
                          height='3.33vh'
                        />
                      </div>
                    </div>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                      Buy
                    </div>
                  </div>
                  <div
                    className='flex-col justify-center items-center gap-[1vh] inline-flex cursor-pointer'
                    onClick={handleSendClick}
                  >
                    <div className=' rounded-[4.76vh] w-[7.1vh] h-[7.1vh] border-[0.36vh] bg-outlining dark:bg-outliningDark border-outlining dark:border-outliningDark flex-col justify-center items-center gap-[1.3vh] flex'>
                      <div className='w-0 h-0 dark:w-[6vh] dark:h-[6vh]  items-center hidden dark:flex justify-center'>
                        <SendIcon
                          color='#FAFAFACC'
                          width='3.33vh'
                          height='3.33vh'
                        />
                      </div>
                      <div className='dark:w-0 dark:h-0 w-[6vh] h-[6vh] flex items-center dark:hidden justify-center'>
                        <SendIcon
                          color='#7C7C7C'
                          width='3.33vh'
                          height='3.33vh'
                        />
                      </div>
                    </div>
                    <div className='text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.1vh] font-semibold leading-snug'>
                      Send
                    </div>
                  </div>
                </div>
              </div>
              <div className='self-stretch min-h-[45.2vh] py-[2vh] rounded-[2vh] flex-col justify-start items-center gap-[1vh] flex overflow-y-auto overflow-x-hidden'>
                {Object?.entries(groupedItems)?.map(([dateLabel, assets]) => (
                  <>
                    <div className='self-stretch text-fontLightColor dark:text-fontLightColorDark font-[600] text-[1.2vh]  leading-tight px-[1.4vh]'>
                      {dateLabel === 'Yesterday' || dateLabel === 'Today'
                        ? `${dateLabel}`
                        : `${dateLabel?.split(' ')[0]}, ${
                            dateLabel?.split(' ')[2]
                          } ${dateLabel?.split(' ')[1]} ${
                            dateLabel?.split(' ')[3]
                          }`}
                    </div>

                    {assets?.map((asset) => (
                      <AssetHistoryContainer asset={asset} />
                    ))}
                  </>
                ))}
                {/* {(Array.isArray(assetHistory) ? assetHistory : [])?.map(
                  (asset, index) => (
                    <AssetHistoryContainer asset={asset} />
                  )
                )} */}
              </div>
            </div>
          </div>
          <Opentria />
        </div>
      )}
    </>
  )
}
