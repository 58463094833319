import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { NftsItem } from '../../../../rx.core'
import './index.css'

type Props = {
  key: number
  id: number
  loading?: boolean
  nftItem?: NftsItem
  onClick: (action: number) => void
}

export const NftCard: React.FC<Props> = ({
  key,
  id,
  loading,
  nftItem,
  onClick: handleNftClick,
}) => {
  const navigate = useNavigate()

  const handleItemClick = () => {
    handleNftClick(id)
  }

  function HandleClick() {
    navigate('/nfts/oneNft')
  }

  function handleClick() {
    navigate('/nfts/multipleNft')
  }

  return (
    <div
      className='w-[21.8vh] h-[29vh] p-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[1.2vh] border-[0.2vh] border-outlining dark:border-outliningDark border-opacity-[10%] flex-col justify-center items-center gap-[1vh] inline-flex'
      onClick={handleItemClick}
    >
      <div className='w-[20vh] h-[22vh] relative rounded-[0.5vh]'>
        <button>
          {' '}
          <img
            className='w-[20vh] h-[22vh] left-0 top-0 absolute rounded-[0.5vh]'
            src={
              nftItem?.nftInfo?.content?.preview?.url ||
              nftItem?.collectionInfo?.content?.icon?.url
            }
          />
        </button>
        <div className='w-[4.76vh] h-[4.76vh] absolute top-0 left-0 flex items-center justify-center '>
          <img
            className='w-[2.6vh] h-[2.6vh] bg-transparent rounded-[1.3vh]'
            src={nftItem?.chainLogo}
          />
        </div>
      </div>
      <div className='self-stretch px-[0.7vh] py-[1vh] rounded-[2vh] justify-between items-center gap-[1vh] inline-flex '>
        <div className='h-[2vh] justify-center items-center gap-[1vh] flex'>
          <div className='text-center dark:text-fontColorNftCardDark text-fontColorNftCard text-[1.67vh] font-semibold leading-tight'>
            {nftItem?.nftInfo?.name}
          </div>
        </div>
        <p className='text-fontLightColor dark:text-fontLightColorDark font-[600] text-[1.67vh]'>
          x{nftItem?.amount}
        </p>
      </div>
    </div>
  )
}
