import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Opentria, useTriaUser } from '../../../../ui.common'
import {
  coerceStringValue,
  NftsItem,
  useToast,
  NftsItemDetails,
  updateCurrentRoute,
  updatePreviousRoute,
  updateNftDetails,
} from '../../../../rx.core'
import { useTranslation } from 'react-i18next'
import './Nft.css'
import { BackButton } from '../../components/Buttons/BackButton'
import { PrimaryCustomButton } from '../../components/Buttons/PrimaryButton'
import { NftExplore } from '../../components/Popups/NftExplore'
import { useDispatch, useSelector } from 'react-redux'
import { store, RootState } from '../../../../rx.core'
import { NftDetailsLoader } from '../../components/LoadersPages/NftDetailsLoader'
import { NftDetailImageCard } from '../../components/Cards'

export default function OneNft() {
  const { getNFTDetails } = useTriaUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [nftsItemDetails, setNftsItemDetails] = useState<NftsItemDetails>()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const nftItem = location.state && location.state?.selected
  const [showFullDescription, setShowFullDescription] = useState(false)
  const description =
    nftsItemDetails?.collectionInfo?.description ||
    nftsItemDetails?.nftInfo?.description
  const words = description?.split(/\s+/)
  const previewDescription = words?.slice(0, 20).join(' ')
  const nftToFetch = useSelector(
    (store: RootState) => store.NftDetail.currentNft
  )
  const userNfts = useSelector((store: RootState) => store?.User?.userNfts)

  const dispatch = useDispatch()

  dispatch(updateCurrentRoute(window.location.pathname))

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription)
  }

  const fetchNftDetails = async () => {
    try {
      setLoading(true)

      const itemDetails = await getNFTDetails(
        nftToFetch?.chainName,
        nftToFetch?.tokenAddress,
        nftToFetch?.tokenId,
        nftToFetch?.triaName
      )
      setNftsItemDetails(itemDetails)
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // Side effect
  useEffect(() => {
    fetchNftDetails()
  }, [nftItem])

  function HandleClick() {
    navigate('/nfts')
  }

  const handlePopup = () => {
    setShowPopup(!showPopup)
  }
  useEffect(() => {
    if (nftsItemDetails?.nftInfo) {
      const nft = userNfts.find((nft) => {
        if (
          nft?.nftInfo?.contract_address ===
            nftsItemDetails?.nftInfo?.contract_address &&
          nft?.nftInfo?.token_id === nftsItemDetails?.nftInfo?.token_id &&
          nft?.nftInfo?.name === nftsItemDetails?.nftInfo?.name
        ) {
          return nft
        }
      })
      setAmount(nft?.amount)
    }
  }, [nftsItemDetails])

  const handleSendClick = () => {
    dispatch(
      updateNftDetails({
        chainName: nftsItemDetails?.chainName,
        chainLogo: nftsItemDetails?.chainLogo,
        nftImage: nftsItemDetails?.nftInfo?.content?.preview?.url,
        nftName: nftsItemDetails?.nftInfo?.name,
        type: nftsItemDetails?.nftInfo?.interface,
        tokenAddress: nftsItemDetails?.nftInfo?.contract_address,
        tokenId: nftsItemDetails?.nftInfo?.token_id,
        amount: `${amount}`,
      })
    )
    dispatch(updatePreviousRoute('/nfts/oneNft'))
    navigate('/home/sendcrypto')
  }
  return (
    <>
      {loading && <NftDetailsLoader />}
      {!loading && (
        <div className='flex items-start relative w-[53vh] h-[100vh] overflow-hidden'>
          {showPopup && (
            <div className='absolute z-50 top-[6vh] right-[1.2vh]'>
              <NftExplore explorerUrl={nftsItemDetails?.contractExplorerUrl} />
            </div>
          )}
          <div className='w-[52vh] flex-col rounded-xl font-montserrat justify-between items-center inline-flex '>
            <div
              className={`w-[52vh] 
                h-[75vh]
               flex-col justify-start items-center flex px-[2vh] z-20 `}
            >
              <div className='self-stretch h-[8.57vh] py-[1.4vh] rounded-[2.5vh] justify-center items-center gap-[1vh] inline-flex'>
                <BackButton onClick={HandleClick} />
                <div className='grow shrink basis-0 h-[4.3vh] justify-center items-center gap-[2vh] flex'>
                  <div className='px-[1.4vh] py-[1vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[5.7vh] border-[0.2vh] border-fontLightColor dark:border-fontLightColorDark justify-center items-center gap-[1vh] flex'>
                    <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight'>
                      NFT on
                    </div>
                    <div className='w-[2.4vh] h-[2.4vh] pt-[0.32vh] pb-[0.27vh]  rounded border-[0.1] border-white backdrop-blur-[2.50px] justify-center items-center flex'>
                      <div className='w-[1.7vh] h-[1.7vh] relative flex-col justify-start items-start flex  overflow-hidden' />
                      <img
                        src={nftsItemDetails?.chainLogo}
                        alt=''
                        className='rounded-[1.2vh]'
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='rounded-[1.2vh] w-[2.4vh] h-[2.4vh] border border-fontLightColor dark:border-fontLightColorDark justify-center items-center gap-[0.7vh] flex cursor-pointer'
                  onClick={handlePopup}
                >
                  <img src='/icons/arrow-up.svg'></img>
                </div>
              </div>
              <div
                className={`self-stretch ${
                  showFullDescription ? 'h-[88.7vh]' : 'h-[72.6vh]'
                } py-[1vh] flex-col justify-start items-center gap-[1vh] flex`}
              >
                <div className=''>
                  <NftDetailImageCard
                    nftImage={
                      nftsItemDetails?.nftInfo?.content?.preview?.url ||
                      nftsItemDetails?.collectionInfo?.content?.icon?.url
                    }
                    nftName={nftsItemDetails?.nftInfo?.name}
                    amount={parseFloat(amount || '0')}
                  />
                </div>
                <div className='self-stretch h-[51.3vh] px-[2.4vh] py-[2vh] rounded-[2vh] flex-col justify-center items-center gap-[1vh] flex'>
                  {nftsItemDetails?.nftInfo?.market_data?.prices?.floor && (
                    <div className='self-stretch h-[8.1vh] py-[1vh] flex-col justify-start items-start gap-[2vh] flex'>
                      <div className='self-stretch h-[6.2vh] flex-col justify-start items-start gap-[0.5vh] flex'>
                        <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                          <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[2vh] font-semibold  leading-tight'>
                            Floor price
                          </div>
                        </div>
                        <div className='self-stretch justify-start items-center gap-[1vh] inline-flex'>
                          <div className='grow shrink basis-0 text-fontLightColor dark:text-fontLightColorDark text-opacity-80 text-[2.85vh] font-medium  leading-tight'>
                            {
                              nftsItemDetails?.nftInfo?.market_data?.prices
                                ?.floor
                            }{' '}
                            ETH
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor w-full text-start text-[2.4vh] font-[600]'>
                    About
                  </div>
                  <div
                    className={`self-stretch 
                      h-[10vh]
                     flex-col justify-start items-start flex overflow-auto mb-[4vh]`}
                  >
                    <div
                      className={`self-stretch  flex-col justify-start items-start gap-[1vh] flex `}
                    >
                      <div
                        className={`w-full text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[2vh] font-medium text-start leading-tight ${
                          showFullDescription ? 'h-[16.7vh]' : 'h-[8.3vh]'
                        } text-center`}
                      >
                        {showFullDescription ? description : previewDescription}
                      </div>
                    </div>
                    {/* <button
                      className='self-stretch h-[3vh] px-[2.4vh] py-[0.5vh] rounded-[2.5vh] flex-col justify-center items-center gap-[1vh] flex'
                      onClick={toggleDescription}
                    >
                       <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-40 text-sm font-semibold leading-[16.80px]">
            {showFullDescription ? 'View less' : 'View more'}
          </div> 
                    </button> */}
                  </div>
                </div>
              </div>
            </div>

            {nftsItemDetails?.nftInfo?.attributes &&
              nftsItemDetails?.nftInfo?.attributes?.length > 0 && (
                <div
                  className={`px-[2.4vh] self-stretch h-[3vh] rounded-[2.4vh] flex-col justify-center items-start gap-[1vh] mb-[7vh] flex ${
                    showFullDescription
                      ? 'translate-y-[10.7vh] transform duration-200'
                      : ' translate-y-[0px] transform duration-200'
                  }`}
                >
                  <div className='flex flex-wrap gap-[1vh]'>
                    {nftsItemDetails?.nftInfo?.attributes?.map((attribute) => (
                      <div className='flex py-[2vh] px-[1vh] items-center justify-between rounded-[4.76vh] h-[3.8vh] border-[0.17vh] border-outlining dark:border-outliningDark'>
                        <div className='h-[2vh] flex items-center justify-center'>
                          <p>
                            <span className='text-fontSemiLightColor dark:text-fontSemiLightColorDark text-[1.67vh] font-[600] leading-[120%]'>
                              {attribute?.key}:
                            </span>
                            <span> </span>
                            <span className='text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[1.6vh] font-[600] leading-[120%]'>
                              {attribute?.value}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            <div
              className={`self-stretch justify-center items-center gap-[2.85vh] inline-flex mb-[2.4vh] ${
                showFullDescription
                  ? 'translate-y-[10.7vh] transform duration-200'
                  : ' translate-y-[0px] transform duration-200'
              }`}
            >
              <PrimaryCustomButton
                content='Send'
                height='8vh'
                width='30vh'
                onClick={handleSendClick}
              />
            </div>
          </div>
          <div className='bottom-[2.3vh] absolute flex items-center justify-center w-full'>
            <Opentria />
          </div>
        </div>
      )}
    </>
  )
}
