import React, { useEffect, useRef, useState } from 'react'
import QRCodeStyling from 'qr-code-styling'
interface Props {
  content?: string
  logo?: string
}
export const QrGenerator: React.FC<Props> = ({ content, logo }) => {
  const qrCodeRef = useRef(
    new QRCodeStyling({
      width: 200,
      height: 200,
      image: logo || '/ethereum-eth.svg',
      dotsOptions: {
        color: 'black',
        type: 'rounded',
      },
      imageOptions: {
        margin: 2,
      },
    })
  )
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current) {
      qrCodeRef.current.append(divRef.current)
    }
  }, [])

  useEffect(() => {
    qrCodeRef.current.update({
      data: content || '',
      image: logo || '/ethereum-eth.svg',
    })
  }, [content, logo])

  return (
    <div className='App'>
      <div ref={divRef} />
    </div>
  )
}
