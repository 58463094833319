import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { NavigatorButton, Opentria } from '../../components'

interface IconData {
  name: string
  iconSrc: string
  iconSrcDark: string
  iconSrcActive: string
  text: string
  route: string
}

const iconData: IconData[] = [
  {
    name: 'home',
    iconSrc: '/icons/home.svg',
    iconSrcDark: '/icons/homedark.svg',
    iconSrcActive: '/icons/homeactive.svg',
    text: 'home',
    route: '/home',
  },
  {
    name: 'colorfilter',
    iconSrc: '/icons/colorfilter.png',
    iconSrcDark: '/icons/colorfilterdark.svg',
    iconSrcActive: '/icons/colorfilteractive.svg',
    text: 'nfts',
    route: '/nfts',
  },
  {
    name: 'arrangesquare',
    iconSrc: '/icons/clock.svg',
    iconSrcDark: '/icons/clockdark.svg',
    iconSrcActive: '/icons/clockactive.svg',
    text: 'activity',
    route: '/activity',
  },
]
interface Props {
  currentActive?: string
}

export const Footer: React.FC<Props> = ({ currentActive }) => {
  const [selectedIcon, setSelectedIcon] = useState<string | null>(
    iconData[0].name
  )
  const [currentRoute, setCurrentRoute] = useState<string>('/home')
  const navigate = useNavigate()

  const location = useLocation()
  useEffect(() => {
    if (location.pathname == '/') {
      setCurrentRoute('/home')
    }

    setCurrentRoute(location.pathname)
    if (currentActive) {
      setCurrentRoute(currentActive)
    }
  }, [location.pathname])

  const handleIconClick = (icon: IconData) => {
    setSelectedIcon(icon.name)
    isTextVisible(icon.name)
    navigate(icon.route)
  }

  const isTextVisible = (route: string) => {
    return route === currentRoute ? 'block' : 'none'
  }

  return (
    <div className='w-full min-w-[288px] h-[64px] md:h-[91px] flex-col justify-start items-center gap-[3px] md:gap-2 flex'>
      <div className='w-full h-[64px] md:h-[91px] flex-col justify-center items-center gap-[3px] md:gap-2 flex'>
        <div className='justify-center items-center gap-[3px] md:gap-2 inline-flex'>
          {iconData.map((item) => (
            <div key={item.name}>
              <NavigatorButton
                item={item}
                currentRoute={currentRoute}
                isTextVisible={isTextVisible}
                onClick={() => {
                  handleIconClick(item)
                }}
              />
            </div>
          ))}
        </div>
        <Opentria />
      </div>
    </div>
  )
}
