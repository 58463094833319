import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssetState, AssetValue, FetchParams } from './asset.model'
import {
  AssetForTriaName,
  NftsItem,
  HistoryItem,
  RecentUser,
  UserOnChainStatus,
  GetAssetDetailsResponse,
} from '../../../types'

const initialState: AssetState = {
  fetchParam: {
    triaName: '',
    chainName: '',
    tokenAddress: '',
  },
  assetValue: {
    assetBalance: 0,
    valueInUsd: 0,
  },
}

export const assetSlice = createSlice({
  name: 'Asset',
  initialState,
  reducers: {
    updateFetchParams: (state, action: PayloadAction<FetchParams>) => {
      state.fetchParam.chainName = action.payload.chainName
      state.fetchParam.tokenAddress = action.payload.tokenAddress
      state.fetchParam.triaName = action.payload.triaName
    },

    updateAssetBalance: (state, action: PayloadAction<AssetValue>) => {
      state.assetValue.assetBalance = action.payload.assetBalance
      state.assetValue.valueInUsd = action.payload.valueInUsd
    },
  },
})

export const { updateFetchParams, updateAssetBalance } = assetSlice.actions
