import { UserProvider } from '../../contexts';
import { Provider } from 'react-redux';
import { store } from '../../../../rx.core/src';
// import { RouterProvider } from '../router-provider'
import {
  LocalizationResources,
  initalize as initLocalization,
} from '../../../../rx.i18n'
import { I18nextProvider } from 'react-i18next';
import { useMemo } from 'react';
import React from 'react';

type Props = {
  //   triaRouter: Parameters<typeof RouterProvider>[0]['triaRouter']
  children: React.ReactNode;
    appLocalizationResource?: LocalizationResources
  //   persistenceService: Parameters<typeof ThemeProvider>[0]['persistenceService']
};

export const TriaProvider: React.FC<Props> = ({
  //   triaRouter,
  children,
    appLocalizationResource = undefined,
  //   persistenceService,
}) => {
    const i18n = useMemo(
      () => initLocalization(appLocalizationResource),
      [appLocalizationResource]
    )

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <UserProvider>{children}</UserProvider>
      </I18nextProvider>
    </Provider>

    // <Provider store={store}>
    //   <RouterProvider triaRouter={triaRouter}>
    //     <I18nextProvider i18n={i18n}>
    //       <ThemeProvider persistenceService={persistenceService}>
    //         <AuthProvider>
    //           <UserProvider>{children}</UserProvider>
    //         </AuthProvider>
    //       </ThemeProvider>
    //     </I18nextProvider>
    //   </RouterProvider>
    // </Provider>
  );
};
