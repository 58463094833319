import { useTriaUser } from '../package/ui.common'

export const useResolveTriaName = () => {
  const { getUserByAddress } = useTriaUser()
  const getTriaName = async (chainName: string, address: string) => {
    try {
      const triaName = await getUserByAddress(address, chainName)
      return triaName
    } catch (err) {
      console.log(err)
    }
  }
  return getTriaName
}
