export const ArrowButton: React.FC = () => {
  return (
    <div className='w-[2.1vh] h-[2.1vh] flex items-center justify-center'>
      <div className='w-[2.1vh] h-[2.1vh]'>
        <img
          src='/icons/arrow-down-dark.svg'
          className='dark:visible invisible w-0 dark:w-[2.1vh]'
        />
        <img
          src='/icons/arrow-down.png'
          className='dark:invisible visible w-[2.1vh] dark:w-0'
        />
      </div>
    </div>
  )
}
