import { useEffect, useState } from 'react'
import { HistoryItem, formatAddress } from '../../../../../rx.core'
import {
  formatNumberDisplay,
  formatDisplayDateAndTime,
} from '../../../../../rx.core'

import { useResolveTriaName } from '../../../../../../hooks'

interface Props {
  asset: HistoryItem
}

export const AssetHistoryContainer: React.FC<Props> = ({ asset }) => {
  const [dateTime, setDateTime] = useState(['', ''])
  const [triaName, setTriaName] = useState<string>()
  const getTriaName = useResolveTriaName()
  useEffect(() => {
    const date = formatDisplayDateAndTime(new Date(asset?.timestamp))
    setDateTime(date)
  }, [asset])

  useEffect(() => {
    const resolveName = async () => {
      if (asset?.desc?.action === 'Received') {
        try {
          const response = await getTriaName(
            asset?.chainName,
            asset?.from?.address
          )
          setTriaName(response)
        } catch (err) {
          console.log(err)
        }
      } else if (asset?.desc?.action === 'Sent') {
        try {
          const response = await getTriaName(
            asset?.chainName,
            asset?.to?.address
          )
          setTriaName(response)
        } catch (err) {
          console.log(err)
        }
      }
    }
    resolveName()
  }, [asset])

  return (
    <div className='w-[53vh] self-stretch flex-col justify-center items-center gap-[1vh] flex'>
      <div className='self-stretch px-[2.4vh] py-[1.4vh] flex-col justify-center items-center gap-[2vh] flex hover:bg-hoverColor dark:hover:bg-hoverDarkColor'>
        <div className='w-full justify-between items-center flex h-[4.76vh] px-[1.4vh]'>
          <div className='self-stretch h-[4.76vh] justify-center items-start gap-[1vh] flex'>
            <div className='w-[4.76vh] h-[4.76vh] rounded-[2vh] overflow-hidden'>
              <img
                className={`w-[4.76vh] h-[4.76vh] rounded-[3vh] ${
                  asset?.desc?.assetSymbol === 'FUSE' ? 'scale-[120%]' : ''
                }`}
                src={asset?.desc?.assetLogo || ''}
              />
            </div>
            <div className='grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex'>
              {asset?.desc?.action === 'Received' ? (
                <div className='self-stretch w-[24vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor font-[600] leading-[120%] text-[1.67vh] max-w-[24vh] overflow-hidden'>
                  {' '}
                  {!triaName ? (
                    <span>{formatAddress(asset?.from?.address)}</span>
                  ) : (
                    <span>{triaName}</span>
                  )}
                </div>
              ) : (
                <div className='self-stretch text-fontPrimaryColor dark:text-fontPrimaryDarkColor font-[600] leading-[120%] text-[1.67vh] w-[24vh] max-w-[24vh] overflow-hidden'>
                  {' '}
                  {!triaName ? (
                    <span>{formatAddress(asset?.to?.address)}</span>
                  ) : (
                    <span>{triaName}</span>
                  )}
                </div>
              )}

              <div className='self-stretch text-fontLightColor dark:text-fontLightColorDark font-[500] text-[1.67vh]  leading-tight'>
                {dateTime[1]}
              </div>
            </div>
          </div>
          <div className='w-[40vh] px-[2.4vh] flex-col justify-center items-end gap-[0.5vh] inline-flex'>
            <div
              className={`text-center ${
                asset?.desc?.action == 'Sent'
                  ? 'text-redWarning'
                  : asset?.desc?.action == 'Received'
                  ? 'text-greenWarning'
                  : 'text-fontLightColor dark:text-fontLightColorDark'
              }  font-[600] text-[2vh] w-auto leading-[120%]`}
            >
              {formatNumberDisplay(
                asset?.desc.value
                  ? parseFloat(asset?.desc.value) / 10 ** asset?.desc.decimals
                  : 0,
                4
              )}{' '}
              {asset?.desc?.assetSymbol}
            </div>
            <div className='text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-[500] leading-[120%]'>
              {asset?.desc?.valueInUsd?.toFixed(5)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
